export const interestList = [
    // Interest
    { id: "01", listName: "Gaming" },
    { id: "02", listName: "Playing Cricket" },
    { id: "03", listName: "Reading Book" },
    { id: "04", listName: "Blogging" },
    { id: "05", listName: "Reading tech books" },
    { id: "06", listName: "Traveling" },
    { id: "07", listName: "Photography" },
    { id: "08", listName: "Listening to podcasts" },
    { id: "09", listName: "Exploring new cultures" },
    { id: "10", listName: "Web development" },
    { id: "11", listName: "Machine learning" },
    { id: "12", listName: "UI/UX design" },
    { id: "13", listName: "Robotics" },
    { id: "14", listName: "Swimming" },
    { id: "15", listName: "Watching Movie" },
    { id: "16", listName: "Network Security" },
    { id: "17", listName: "Public Speaking" },
    { id: "18", listName: "Leadership Development" },
    { id: "19", listName: "Networking" },
    { id: "20", listName: "Painting" },
    { id: "21", listName: "Fitness and Wellness" },
    { id: "22", listName: "Swimming" },
]

export const skillsList = [
    // Software Skills
    { id: "01", listName: "Java" },
    { id: "02", listName: "Python" },
    { id: "03", listName: "JavaScript" },
    { id: "04", listName: "C#" },
    { id: "05", listName: "Ruby" },
    { id: "06", listName: "PHP" },
    { id: "07", listName: "C++" },
    { id: "08", listName: "Swift" },
    { id: "09", listName: "Go" },
    { id: "10", listName: "SQL" },
    { id: "11", listName: "R" },
    { id: "12", listName: "TypeScript" },
    { id: "13", listName: "Kotlin" },
    { id: "14", listName: "Rust" },
    { id: "15", listName: "Dart" },
    { id: "16", listName: "MATLAB" },
    { id: "17", listName: "Scala" },
    { id: "18", listName: "Shell Scripting" },
    { id: "19", listName: "Visual Basic" },

    // Graphics Skills
    { id: "20", listName: "Adobe Photoshop" },
    { id: "21", listName: "Adobe Illustrator" },
    { id: "22", listName: "Adobe InDesign" },
    { id: "23", listName: "CorelDRAW" },
    { id: "24", listName: "Adobe After Effects" },
    { id: "25", listName: "Blender" },
    { id: "26", listName: "Autodesk Maya" },
    { id: "27", listName: "Cinema 4D" },
    { id: "28", listName: "Sketch" },
    { id: "29", listName: "Figma" },
    { id: "30", listName: "InVision" },
    { id: "31", listName: "GIMP" },
    { id: "32", listName: "Unity" },
    { id: "33", listName: "Adobe XD" },
    { id: "34", listName: "Procreate" },
    { id: "35", listName: "ZBrush" },

    // Other Skills
    { id: "36", listName: "Project Management" },
    { id: "37", listName: "Data Analysis" },
    { id: "38", listName: "SEO" },
    { id: "39", listName: "Digital Marketing" },
    { id: "40", listName: "Content Writing" },
    { id: "41", listName: "Public Speaking" },
    { id: "42", listName: "Social Media Management" },
    { id: "43", listName: "Customer Service" },
    { id: "44", listName: "Sales" },
    { id: "45", listName: "Negotiation" },
    { id: "46", listName: "Networking" },
    { id: "47", listName: "Research" },
    { id: "48", listName: "Time Management" },
    { id: "49", listName: "Team Leadership" },
    { id: "50", listName: "Critical Thinking" },
    { id: "51", listName: "Nginx" },
    { id: "52", listName: "Problem Solving" },
    { id: "53", listName: "Docker" },
    { id: "54", listName: "Kubernetes" },
    { id: "55", listName: "Data Structures  " },
    { id: "56", listName: "Algorithms" },
    { id: "57", listName: "React.js" },
    { id: "58", listName: "Angular" },
    { id: "59", listName: "Vue.js" },
    { id: "60", listName: "Node.js" },
    { id: "61", listName: "Express.js" },
    { id: "62", listName: "jQuery" },
    { id: "63", listName: "Bootstrap" },
    { id: "64", listName: "SASS/SCSS" },
    { id: "65", listName: "Tailwind CSS " },
    { id: "66", listName: "TypeScript" },
    { id: "67", listName: "AJAX" },
    { id: "68", listName: "JSON" },
    { id: "69", listName: "RESTful APIs" },
    { id: "70", listName: "GraphQL" },
    { id: "71", listName: "Webpack" },
    { id: "72", listName: "Gulp" },
    { id: "73", listName: "Git" },
    { id: "74", listName: "GitHub" },
    { id: "75", listName: "NPM/Yarn" },
    { id: "76", listName: "PostgreSQL" },
    { id: "77", listName: "Firebase" },
    { id: "78", listName: "Babel" },
    { id: "79", listName: "MongoDB" },
    { id: "80", listName: "Babel" },
    { id: "81", listName: "MySQL" },
    { id: "82", listName: "SQLite" },
    { id: "83", listName: "NoSQL" },
    { id: "84", listName: "SQL" },
    { id: "85", listName: "Python (Django/Flask)" },
    { id: "86", listName: "PHP (Laravel)" },
    { id: "87", listName: "Ruby on Rails" },
    { id: "88", listName: "Java (Spring Boot)" },
    { id: "89", listName: "ASP.NET" },
    { id: "90", listName: "OAuth" },
    { id: "91", listName: "Version Control" },
    { id: "92", listName: "Agile Methodologies" },
    { id: "93", listName: "Jest" },
    { id: "94", listName: "Mocha" },
    { id: "95", listName: "Cypress" },
    { id: "96", listName: "CloudFormation" },
    { id: "97", listName: "AWS" },
    { id: "98", listName: "Google Cloud Platform" },
    { id: "99", listName: "Grafana" },
    { id: "100", listName: "ELK" },
    { id: "101", listName: "Stack" },
    { id: "102", listName: "firewalls" },
    { id: "103", listName: "Azure " },
    { id: "104", listName: "Typography" },
    { id: "105", listName: "Color Theory" },
    { id: "106", listName: "Social Media Design" },
    { id: "107", listName: "Data Visualization" },
    { id: "108", listName: "Client Management" },
]