import React, { useContext } from "react";
import "./Elegant_Resume_Template.css";
import { QRCodeCanvas } from "qrcode.react";
import GlobalContext from "../../context/GlobalContext";

export default function Elegant_Resume_Template({ resumeData }) {
  const { AllSections, isQRCode, qrPosition } = resumeData;
  const { vertical, horizontal, X, Y } = qrPosition;

  const { liveTemplateURL } = useContext(GlobalContext);

  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    color: resumeData.headingTextColor,
  };
  const { backgroundPattern } = resumeData.resumeStyle;

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`,
    lineHeight: `${resumeData.bodyTextSize + 8}px`
  };

  const combineHeadingStyle = {
    ...headingStyleCSS,
    fontSize: `${resumeData.headingTextSize}px`,
  };

  return (
    <div
      className="elegant-resume-template"
      style={{
        backgroundImage: `url("/background-pattern/${backgroundPattern}")`,
      }}>

      {isQRCode &&
        <div className="resume-qr-code" style={{ [vertical]: `${Y}px`, [horizontal]: `${X}px` }}>
          <QRCodeCanvas value={liveTemplateURL} size={"50"} />
        </div>
      }

      {/* *** HEADER SECTION *** */}
      <div className="personal-edit">
        <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
        <h4 style={headingStyleCSS} className="job-role">{resumeData.userJobRole}</h4>
      </div>

      {/* *** LEFT-RIGHT SECTIONS *** */}

      <div className="left-right-body">

        {/* ** LEFT SECTION ** */}

        <div className="left-section">
          {/* * SUMMARY * */}

          {resumeData.AllSections[0].isSection &&
            <div className="summary-edit">
              <h2 style={combineHeadingStyle} className="section-name"><i className="fa-regular fa-user" />{AllSections[0].sectionName}</h2>
              <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />
            </div>}

          {/* * CONTACT SECTION * */}
          {resumeData.AllSections[1].isSection &&
            <div className="contact-edit">
              <h2 style={combineHeadingStyle} className="section-name"><i className="fa-regular fa-address-book" />{AllSections[1].sectionName}</h2>
              <ul className="contact">
                {AllSections[1].list.map(element => {
                  const { listId, iconName, contactName, additionalLink } = element;
                  const handleItemClick = (e) => {
                    if (!additionalLink) {
                      e.preventDefault();
                    }
                  };
                  return (
                    <li
                      key={listId}
                      style={{ cursor: 'pointer' }}
                      onClick={handleItemClick}
                    >
                      {additionalLink ? (
                        <a
                          href={additionalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'flex' }}
                        >
                          <i style={{ color: resumeData.bodyTextColor, fontSize: resumeData.bodyTextSize + 2 }} className={iconName} />
                          <p style={{ ...paraStyleCSS, marginLeft: '10px' }}>{contactName}</p>
                        </a>
                      ) : (
                        <>
                          <li style={{ ...paraStyleCSS, cursor: 'pointer' }}>
                            <i style={{ color: resumeData.bodyTextColor, fontSize: resumeData.bodyTextSize + 2 }} className={iconName}></i>
                            <p>{contactName}</p>
                          </li>
                        </>
                      )
                      }
                    </li>
                  );
                })}
              </ul>
            </div>}

          {/* * SKILLS SECTION * */}
          {resumeData.AllSections[3].isSection &&
            <div className="skill-edit">
              <h2 style={combineHeadingStyle} className="section-name"><i className="fa-solid fa-list-check" />{AllSections[3].sectionName}</h2>
              <ul className="common-list-styling">
                {AllSections[3].list.map((element) => {
                  const { listId, skillName } = element;
                  return (
                    <li style={paraStyleCSS} key={listId}>
                      {skillName}
                    </li>
                  );
                })}
              </ul>
            </div>}

          {resumeData.AllSections[7].isSection &&
            <div className='interest-edit'>
              <h2 style={combineHeadingStyle} className="section-name"> <i className="fa-regular fa-puzzle-piece"></i>{AllSections[7].sectionName}</h2>
              <ul className="common-list-styling">
                {AllSections[7].list.map(hobbies => {
                  const { listId, interest } = hobbies;
                  return (
                    <li style={paraStyleCSS} key={listId}>
                      {interest}
                    </li>
                  )
                })}
              </ul>
            </div>}

          {/* * PROJECT SECTION * */}
          {resumeData.AllSections[2].isSection &&
            <div className="project-edit">
              <h2 style={combineHeadingStyle} className="section-name"><i className="fa-regular fa-folder" />{AllSections[2].sectionName}</h2>
              <div className="project-section">
                {AllSections[2].list.map((element) => {
                  const { listId, projectName, startDate, endDate, aboutProject, } = element;
                  return (
                    <div className="common-sec" key={listId}>
                      <div style={{ width: "40%" }}>
                        <p style={paraStyleCSS}> {startDate} - {endDate}</p>
                        <p style={paraStyleCSS} className="uppercase"><b>{projectName}</b></p>
                      </div>
                      <div style={{ width: "55%" }}>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>}
        </div>
        {/* ** RIGHT SECTION ** */}
        <div className="right-section">
          {/* * WORK EXP SECTION * */}
          {resumeData.AllSections[4].isSection &&
            <div className="job-exp-edit">
              <h2 style={combineHeadingStyle} className="section-name"><i className="fa-regular fa-compass" />{AllSections[4].sectionName}</h2>
              <div className="exp-section">
                {AllSections[4].list.map((element) => {
                  const { listId, companyName, jobRole, startDate, endDate, aboutJob, } = element;
                  return (
                    <div className="common-sec" key={listId}>
                      <div style={{ width: "40%" }}>
                        <p style={paraStyleCSS}> {startDate} - {endDate}</p>
                        <p style={paraStyleCSS} className="uppercase"> <b>{companyName}</b></p>
                      </div>
                      <div style={{ width: "55%" }}>
                        <p style={paraStyleCSS} className="uppercase"> <b>{jobRole}</b></p>
                        <p
                          style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>}

          {/* * EDUCATION SECTION * */}
          {resumeData.AllSections[5].isSection &&
            <div className="education-edit">
              <h2 style={combineHeadingStyle} className="section-name"><i className="fa-regular fa-pen-to-square" />{AllSections[5].sectionName}</h2>
              <div className="edu-section">
                {AllSections[5].list.map((element) => {
                  const { listId, collegeName, course, startDate, endDate, aboutEducation, } = element;
                  return (
                    <div className="common-sec" key={listId}>
                      <div style={{ width: "40%" }}>
                        <p style={paraStyleCSS}>{startDate} - {endDate}</p>
                        <p style={paraStyleCSS} className="uppercase"> <b>{course}</b></p>
                      </div>
                      <div style={{ width: "55%" }}>
                        <p style={paraStyleCSS} className="uppercase"> <b>{collegeName}</b></p>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>}

          {AllSections[8].isSection &&
            <div className='certification-edit common-section'>
              <h2 style={combineHeadingStyle} className="section-name"><i className="fa-regular fa-file-certificate"></i>{AllSections[8].sectionName}</h2>
              <div className="certificatiion-section">
                {AllSections[8].list.map(Project => {
                  const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
                  return (
                    <div className="common-sec" key={listId}>
                      <div style={{ width: "40%" }}>
                        <p style={paraStyleCSS}>{startDate} - {endDate}</p>
                        <p style={paraStyleCSS} className="uppercase"><b>{certificateName}</b></p>
                      </div>
                      <div style={{ width: "55%" }}>
                        <p style={paraStyleCSS} className="uppercase"><b>{instituteName}</b></p>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>}
          {AllSections[6].isSection &&
            <div className='language-edit'>
              <h2 style={combineHeadingStyle} className="section-name"> <i className="fa-light fa-language"></i>{AllSections[6].sectionName}</h2>
              <ul className="common-list-styling">
                {AllSections[6].list.map(languages => {
                  const { listId, languageName, languageProfiency } = languages;
                  return (
                    <li style={paraStyleCSS} key={listId}>
                      {languageName} {languageProfiency !== "None" && ` - ${languageProfiency}`}
                    </li>
                  )
                })}
              </ul>
            </div>}
        </div>
      </div>
    </div >
  );
}
