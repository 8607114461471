import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import "./ShowBlogData.css";
import EditBlogPopup from "./EditBlogPopup";

function ShowBlogData() {
  const { backServer } = useContext(GlobalContext);
  const [blogPosts, setBlogPosts] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [filter, setFilter] = useState("all"); // Filter state

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(`${backServer}/cg/all-posts`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch posts. Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data)
        if (data.success) {
          setBlogPosts(data.posts);
        } else {
          console.error("Error fetching posts:", data.message);
        }
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };
    fetchBlogPosts();
  }, [backServer]);

  // Handle delete functionality
  const handleDelete = async (slug) => {
    try {
      const response = await fetch(`${backServer}/cg/delete-post`, {
        method: "DELETE",
        body: JSON.stringify({ slug }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setBlogPosts(blogPosts.filter((post) => post.slug !== slug));
      } else {
        const errorData = await response.json();
        console.error("Error deleting post:", errorData.message);
        alert("An error occurred while deleting the post.");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      alert("An error occurred while deleting the post.");
    }
  };

  // Open the edit popup
  const handleEdit = (post) => {
    setCurrentPost(post);
    setIsPopupOpen(true);
  };


  const handleUpdate = async (updatedPost) => {
    try {
      const response = await fetch(`${backServer}/cg/edit-post`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedPost),
      });

      if (response.ok) {
        const data = await response.json();
        setBlogPosts((prevPosts) =>
          prevPosts.map((post) =>
            post._id === data.post._id ? data.post : post
          )
        );
        setIsPopupOpen(false); // Close the popup
      } else {
        const errorData = await response.json();
        console.error("Error updating post:", errorData.message);
        alert("An error occurred while updating the post.");
      }
    } catch (error) {
      console.error("Error updating post:", error);
      alert("An error occurred while updating the post.");
    }
  };

  const filteredPosts = blogPosts.filter((post) => {
    if (filter === "all") return true;
    if (filter === "visible" && post.visibility.status === "visible") return true;
    if (filter === "hidden" && post.visibility.status === "hidden") return true;
    if (filter === "draft" && post.visibility.status === "draft") return true;
    if (filter === "scheduled" && post.visibility.status === "scheduled") return true;
    return false;
  });

  return (
    <div className="Show-blog-data">
      <h2>All Blog</h2>
      <div className="filter-section">
        <select onChange={(e) => setFilter(e.target.value)} value={filter}>
          <option value="all">All</option>
          <option value="visible">Visible</option>
          <option value="hidden">Hidden</option>
          <option value="draft">Draft</option>
          <option value="scheduled">Scheduled</option>
        </select>
      </div>

      {filteredPosts.length > 0 ? (
        filteredPosts.map((post) => {
          const { slug, featuredImage, title, author, createdAt, excerpt, visibility } = post;
          return (
            <div key={post._id} className="blog-lists">
              <div className="bl-img">
                <img src={featuredImage || "/images/default.jpg"} alt={title} />
              </div>
              <div className="details">
                <h3>{title}</h3>
                <span>
                  <p>Published by {author}</p> | <p>Date: {createdAt}</p>
                </span>
                <p>
                  <p>Visibility: {
                    visibility.status === "scheduled"
                      ? `Scheduled for ${visibility.scheduleTime.date} at ${visibility.scheduleTime.time}`
                      : visibility.status
                  }</p>
                </p>
                <p>{excerpt}</p>
                <div className="btn-groups">
                  <button onClick={() => handleEdit(post)}>Edit</button>
                  <button onClick={() => handleDelete(slug)}>Delete</button>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className="no-blog-p">No blog posts available.</p>
      )}

      {isPopupOpen && (
        <EditBlogPopup
          post={currentPost}
          cancel={() => setIsPopupOpen(false)}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
}

export default ShowBlogData;
