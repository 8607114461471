import React, { useContext } from "react";
import "./BlueModernTemplate.css";
import { QRCodeCanvas } from "qrcode.react";
import GlobalContext from "../../context/GlobalContext";

const BlueModernTemplate = ({ resumeData }) => {
  const { AllSections, isQRCode, resumeStyle ,qrPosition} = resumeData;
  const { vertical, horizontal, X, Y } = qrPosition;
  const { liveTemplateURL } = useContext(GlobalContext);

  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    color: resumeData.headingTextColor,
  };

  const { backgroundPattern } = resumeStyle;
  const { themeColor } = resumeData;
  
  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`,
    lineHeight: `${resumeData.bodyTextSize + 8}px`
  };

  const boldParaStyleCss = {
    ...paraStyleCSS, fontSize: `${resumeData.bodyTextSize + 2}px`
  }

  const combineHeadingStyle = {
    ...headingStyleCSS,
    fontSize: `${resumeData.headingTextSize}px`,
  };
  return (
    <div
      className="MRT-Template"
      style={{
        backgroundImage: `url("/background-pattern/${backgroundPattern}")`,
      }}
    >
     {
                isQRCode && <div className="resume-qr-code" style={{ [vertical]: `${Y}px`, [horizontal]: `${X}px`}}>
                    <QRCodeCanvas value={liveTemplateURL} size={"50"} />
                </div>
            }
      <div className="MRT-Upper-section">
        <div className="MRT-Heading-Style MRT-Lower-section">
          <div className="MRT-Left">
            <div
              className="MRT-Sec-img"
              style={{
                backgroundImage: `url(${resumeData.profileImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                border:`2px solid ${themeColor}`
              }}
            ></div>

            {/* EDUCATION */}

            {resumeData.AllSections[5].isSection &&
              <div className="education-edit">
                <h2 style={combineHeadingStyle} className="hhh">
                  {resumeData.AllSections[5].sectionName}
                </h2>
                {resumeData.AllSections[5].list.map((element, index) => {
                  const { collegeName, course, startDate, endDate, aboutEducation, } = element;
                  return (
                    <div className="common-section" key={index}>
                      <p style={paraStyleCSS}> {startDate} - {endDate}<br />
                        <b>{course}</b>
                      </p>
                      <div className="MRT-mydiv MRT-Para-Size-Option" style={{borderLeft:`2px solid ${themeColor}`}}>
                        <p style={paraStyleCSS}> <b>{collegeName}</b> </p>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} className="para-font-size" />
                      </div>
                    </div>
                  );
                })}
              </div>}

            {/* SKILLS */}
            {resumeData.AllSections[3].isSection &&
              <div className="MRT-Heading-style MRT-skils skill-edit">
                <h2 style={combineHeadingStyle} className="hhh">
                  {resumeData.AllSections[3].sectionName}
                </h2>
                <ul className="MRT-common-list">
                  {resumeData.AllSections[3].list.map((element, index) => (
                    <li style={paraStyleCSS} key={index}>
                      <span className='list-dots' style={{ color: themeColor }}></span>
                      {element.skillName}
                    </li>
                  ))}
                </ul>
              </div>}

            {AllSections[6].isSection && <div className='MRT-Heading-style MRT-language language-edit'>
              <h2 style={combineHeadingStyle} className="hhh">
                {resumeData.AllSections[6].sectionName}
              </h2>
              <ul className="MRT-common-list">
                {AllSections[6].list.map(languages => {
                  const { listId, languageName, languageProfiency } = languages;
                  return (
                    <li style={paraStyleCSS} key={listId}>
                      <span className='list-dots' style={{ color: themeColor }}></span>
                      {languageName} - {languageProfiency}
                    </li>
                  )
                })}
              </ul>
            </div>}

            {AllSections[7].isSection && <div className='MRT-Heading-style hobbies interest-edit'>
              <h2 style={combineHeadingStyle} className="hhh">
                {AllSections[7].sectionName}
              </h2>
              <ul className="MRT-common-list">
                {AllSections[7].list.map(hobbies => {
                  const { listId, interest } = hobbies;
                  return (
                    <li style={paraStyleCSS} key={listId}>
                    <span className='list-dots' style={{ color: themeColor }}></span>
                    {interest}
                  </li>
                  )
                })}
              </ul>
            </div>}

          </div>
          <div className="MRT-Right">
            <div className="first-sec personal-edit">
              <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
              <h4 style={{ ...headingStyleCSS, fontSize: `${resumeData.bodyTextSize + 6}px` }}>{resumeData.userJobRole}</h4>
            </div>

            {/* CONTACT */}
            {resumeData.AllSections[1].isSection && (
  <div className="MRT-contact-details contact-edit" style={{borderLeft:`2px solid ${themeColor}`}}>
   {resumeData.AllSections[1].list.map((element, index) => {
  const { listId, iconName, contactName, additionalLink } = element;

  
  const handleItemClick = (e) => {
    if (!additionalLink) {
      e.preventDefault(); 
    }
  };

  return (
    <div
      key={listId}
      style={{ ...paraStyleCSS, cursor: 'pointer' }}
      className="MRT-sec-details MRT-Para-Size-Option"
      onClick={handleItemClick}
    >
      {additionalLink ? (
        <a
          href={additionalLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'flex',gap:'5px',color: 'inherit', }} 
        >
          <i className={iconName} style={{color:themeColor}}></i>
          <p className="para-font-size">{contactName}</p>
        </a>
      ) : (
        <>
          <i className={iconName} style={{color:themeColor}}></i>
          <p className="para-font-size">{contactName}</p>
        </>
      )}
    </div>
  );
})}

  </div>
)}            


            {/* SUMMARY */}
            <div className="summary-edit">
              {resumeData.AllSections[0].isSection &&
                <div lang="MRT-sec-profile" >
                  <h2 style={combineHeadingStyle} className="hhh custom-hhh">{resumeData.AllSections[0].sectionName}</h2>
                  <p style={{...paraStyleCSS,borderLeft:`2px solid${themeColor}`}} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} className="ppp para-font-size" />
                </div>}
            </div>

            {/* JOB EXPERIENCE */}
            {resumeData.AllSections[4].isSection &&
              <div className="job-exp-edit">
                <h2 style={combineHeadingStyle} className="hhh">
                  {resumeData.AllSections[4].sectionName}
                </h2>
                {resumeData.AllSections[4].list.map((element, index) => {
                  const { companyName, jobRole, startDate, endDate, aboutJob, } = element;
                  return (
                    <div className="common-section" key={index}>
                      <p style={paraStyleCSS}> {startDate} - {endDate}</p>
                      <p style={paraStyleCSS}><b>{jobRole}</b></p>
                      <div className="MRT-mydiv MRT-Para-Size-Option" style={{borderLeft:`2px solid ${themeColor}`}}>
                        <p style={paraStyleCSS}><b>{companyName}</b></p>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} className="para-font-size" />
                      </div>
                    </div>
                  );
                })}
              </div>}

            {/* PROJECTS */}
            {resumeData.AllSections[2].isSection &&
              <div className="project-edit">
                <h2 style={combineHeadingStyle} className="hhh">
                  {resumeData.AllSections[2].sectionName}
                </h2>
                {resumeData.AllSections[2].list.map((element, index) => {
                  const { projectName, startDate, endDate, aboutProject } =
                    element;
                  return (
                    <div className="common-section" key={index}>
                      <p style={paraStyleCSS}>{startDate} - {endDate}</p>
                      <div className="MRT-mydiv MRT-Para-Size-Option" style={{borderLeft:`2px solid ${themeColor}`}}>
                        <p style={paraStyleCSS}><b>{projectName}</b></p>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} className="para-font-size" />
                      </div>
                    </div>
                  );
                })}
              </div>}

            {AllSections[8].isSection &&
              <div className='certification-edit common-section'>
                <h2 style={combineHeadingStyle} className="hhh">
                  {AllSections[8].sectionName}
                </h2>
                {AllSections[8].list.map(Project => {
                  const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
                  return (
                    <div className="common-section" key={listId}>
                      <p style={paraStyleCSS}> {startDate} - {endDate}</p>
                      <p style={paraStyleCSS}><b>{certificateName}</b></p>
                      <div className="MRT-mydiv MRT-Para-Size-Option" style={{borderLeft:`2px solid ${themeColor}`}}>
                        <p style={paraStyleCSS}><b>{instituteName}</b></p>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} className="para-font-size" />
                      </div>
                    </div>
                  )
                })}
              </div>}

          </div>
        </div>
      </div>
    </div>
  );
};

export default BlueModernTemplate;
