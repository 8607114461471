import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './BlogPageVertical.css';
import GlobalContext from '../context/GlobalContext';

const BlogPageVertical = () => {
  const navigate = useNavigate();
  const { blogPosts } = useContext(GlobalContext);

  // Get only the latest three blog posts
  const latestPosts = blogPosts.slice(0, 2);

  return (
    <div className="blog-page-vertical">
      <main className="blog-main">
        {latestPosts.map((post) => {
          const { slug, featuredImage, title, author, createdAt, excerpt, id } = post;

          return (
            <Link to={`/blog/${slug}`} className="blog-post-link" key={id}>
              <article className="blog-post">
                <img
                  src={featuredImage || "/images/Blog-Banner-Placeholder.png"}
                  alt={title}
                  className="post-image"
                  onError={(e) => e.target.src = "/images/Blog-Banner-Placeholder.png"}
                />
                <div className="content">
                  <h4 className="post-title">{title}</h4>
                  <button className="read-more">Read More</button>
                </div>
              </article>
            </Link>
          );
        })}
      </main>
    </div>
  );
};

export default BlogPageVertical;
