import React, { useContext, useEffect, useState } from "react";
import './MediaEdit.css';
import GlobalContext from "../context/GlobalContext";

function MediaEdit({ cancel, media, onSave, change }) {
    const { backServer } = useContext(GlobalContext);
    const [mediaData, setMediaData] = useState(media);
    const { title, alt, url, description } = mediaData;
    const [newImage, setNewImage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMediaData({ ...mediaData, [name]: value })
    }

    const handleSave = async () => {
        try {
            const response = await fetch(`${backServer}/cg/update-media`, {
                method: "PUT",
                body: JSON.stringify(mediaData),
                headers: {
                    "Content-Type": "application/json"
                }
            });

            change();
            cancel();
        } catch (error) {
            console.log("Error updating media:", error);
        }
    };

    const handleDelete = async () => {
        try {
            console.log(`Requesting DELETE: ${backServer}/delete-media`); // Debugging API URL

            const response = await fetch(`${backServer}/cg/delete-media`, {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ url: media.url })
            });

            const data = await response.json();
            console.log("Media deleted:", data);
            change();
            cancel();
        } catch (error) {
            console.error("Error deleting media:", error.message);
            alert(error.message);
        }
    };


    return (
        <div className='cg-media-edit-popup'>
            <div className='popup-container'>
                <div className="media-popup-header">
                    <h2>Media Edit</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="cancel-btn" onClick={cancel}>
                        <path d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </div>
                <div
                    className="cg-media-edit-preview"
                    style={{
                        backgroundImage: `url(${newImage ? `${backServer}/uploads/${newImage}` : `${backServer}/uploads/${media.url}`})`,
                        cursor: "pointer",
                        border: "1px solid #e5e5e5",
                    }}
                >
                </div>
                <div className='cg-tad-edit'>
                    <h4>Title</h4>
                    <input
                        type="text"
                        name="title"
                        placeholder="Media Title"
                        value={title}
                        onChange={handleChange}
                    />
                </div>
                <div className='cg-tad-edit'>
                    <h4>Alt</h4>
                    <input
                        type="text"
                        name="alt"
                        placeholder="Alt Title"
                        value={alt}
                        onChange={handleChange}
                    />
                </div>
                <div className='cg-tad-edit'>
                    <h4>URL</h4>
                    <input
                        id="banned"
                        type="text"
                        name="url"
                        placeholder="URL"
                        value={url}
                        onChange={handleChange}
                        readOnly
                    />
                </div>
                <div className='cg-tad-edit'>
                    <h4>Description</h4>
                    <textarea
                        type="text"
                        name="description"
                        placeholder="Media Description"
                        value={description}
                        onChange={handleChange}>
                    </textarea>
                </div>
                <div className='cg-media-del-save'>
                    <button className='media-del' onClick={handleDelete}>Delete</button>
                    <button className='media-save' onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>


    );
}

export default MediaEdit;
