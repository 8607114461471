import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './BlogPage.css';
import GlobalContext from '../context/GlobalContext';


const BlogPage = () => {
  const navigate = useNavigate();
  const { blogPosts } = useContext(GlobalContext);

  return (
    <div className="blog-page">
      <header className='blog-page-header'>
        <div className='blog-page-row-section'>
          <div className='blog-text-heading'>
            <h1 className="career-gennie-web-h1">Expert Tips and Insights for Crafting the Perfect CV</h1>
            <p  className="career-gennie-web-para">Welcome to Career Gennie Blog! The platform where we share valuable insights
              and expert advice on creating standout professional CVs and Resumes. These
              insights and advice keep you ahead in the industry with the latest highlighting
              your right expertise to the employer.</p>
            <button onClick={() => navigate("/templates")}>Build Your Resume</button>
          </div>
        </div>
      </header>

      <main className="blog-main">
  {blogPosts.map((post) => {
    const { slug, featuredImage, title, author, createdAt, excerpt, visibility, id } = post;

    
  // Word Limit of description
  const getExcerptWords = (text) => {
    const words = text.split(/\s+/);  
    let excerpt = words.slice(0, 20).join(" ");
    if (words.length > 20) {
      excerpt += "...";
    }
    return excerpt;
  };  
  const truncatedExcerpt = getExcerptWords(excerpt);
    return (
      <Link to={`/blog/${slug}`} className="blog-post-link" key={id}>
      <article className="blog-post">
        {/* Use featuredImage if available, otherwise fallback to GetJob */}
        <img
          src={featuredImage || "/images/Blog-Banner-Placeholder.png"}
          alt={title}
          className="post-image"
          onError={(e) => e.target.src = "/images/Blog-Banner-Placeholder.png"}  
        />
        <div className="content">
          <h4 className="post-title">{title}</h4>
          <p className="post-author">Published By: {author}</p>  
          <p className="post-date">Date: {createdAt}</p> 
          <p className="career-gennie-web-para" style={{ color: "#495057",marginBottom:'10px' }}>
           {truncatedExcerpt}
          </p>
          <button className="read-more">Read More</button>
        </div>
      </article>
    </Link>
    
    );
  })}
</main>
    </div>
  );
};

export default BlogPage;
