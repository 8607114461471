import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "./AdminDashboard.css";
import AdminDashboardLeftbar from "./AdminDashboardLeftbar";
import PostPage from "./PostPage";
import ChangeAdminPassword from "./ChangeAdminPassword";
import CGDashboard from "./CGDashboard";
import PostMedia from "./PostMedia";
import { useEffect } from "react";

export default function AdminDashboard() {
    const location = useLocation().pathname;
    const navigate = useNavigate();
    const [content, setContent] = useState(<CGDashboard />)

    useEffect(() => {

        if (location === '/cg-admin') {
            setContent(<CGDashboard />)
        } else if (location === '/cg-admin/post') {
            setContent(<PostPage />)
        } else if (location === '/cg-admin/media') {
            setContent(<PostMedia />)

        } else if (location === '/cg-admin/change-admin-password') {
            setContent(<ChangeAdminPassword />)
        } else {
            setContent(<CGDashboard />)
        }
    }, [location])
    const handleLogout = () => {
        localStorage.removeItem("auth-admin");
        window.location.href = "/cg-admin";
    };
    return (
        <>
            <div className="cg-admin-dashboard-header">
                <img
                    src="/branding/Career Gennie White Logo.png"
                    alt="A Online CV Maker"
                    className="cg-career-logo-icon"
                    draggable="false"
                />
                <div className="details" onClick={handleLogout}>
                    Logout
                    <i className="fa-regular fa-arrow-right-from-bracket"></i>
                </div>
            </div>
            <div className="cg-bg-dashboard">
                <div className="admin-features-options">
                    <AdminDashboardLeftbar currPage={location} navigate={navigate} />
                </div>
                <div className="admin-features-page">
                    {content}
                </div>
            </div>
        </>
    );
}
