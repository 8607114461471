import ReactQuill from 'react-quill'; // Import Quill component
import 'react-quill/dist/quill.snow.css'; // Import Quill's Snow theme
import "./TextEditorAd.css"


const TextEditorAd = ({ value, onChange }) => {

    const headerOptions = [
        { label: 'Heading 1', value: '1' },
        { label: 'Heading 2', value: '2' },
        { label: 'Heading 3', value: '3' },
        { label: 'Heading 4', value: '4' },
        { label: 'Heading 5', value: '5' },
        { label: 'Heading 6', value: '6' },
      ];
  
      const modules = {
        toolbar: [
          [{ 'header': headerOptions.map(option => option.value) }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline'],
          [{ 'color': [] }],
          ['link'], ['image'],['code-block'],
        ]
      };

    return (
        <div className='cg-admin-text-editor'>
            <ReactQuill
                value={value}
                onChange={onChange}
                modules={modules}
                theme="snow"
                placeholder="Write your content here..."
            />
        </div>
    );
};

export default TextEditorAd;
