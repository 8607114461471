import React, { useContext } from 'react'
import "./Yellow_Theme_Template.css";
import { QRCodeCanvas } from 'qrcode.react';
import GlobalContext from '../../context/GlobalContext';

export default function Yellow_Theme__Template({ resumeData }) {

  const { AllSections, isQRCode } = resumeData;
  const { liveTemplateURL } = useContext(GlobalContext);
  const { themeColor } = resumeData;
  const { backgroundPattern } = resumeData.resumeStyle;

  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    color: resumeData.headingTextColor,
  }

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`,
    lineHeight: `${resumeData.bodyTextSize + 8}px`
  }

  const boldParaStyleCss = {
    ...paraStyleCSS, fontSize: `${resumeData.bodyTextSize + 2}px`
  }

  const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }
  return (
    <div className="Yellow-Theme-Template" style={{ backgroundImage: `url("/background-pattern/${backgroundPattern}")` }}>
      {
        isQRCode ? <div className="resume-qr-code">
          <QRCodeCanvas value={liveTemplateURL} size={"50"} />
        </div> : <></>
      }
      <div className="Header personal-edit">
        <div className="Personal-Details" style={{ backgroundColor: themeColor }}>
          <h1 style={headingStyleCSS}>
            {resumeData.userName}<span style={{ color: "black", paddingLeft: "15%" }}></span>
          </h1>
        </div>
        <h4 style={headingStyleCSS}>
          {resumeData.userJobRole}
          <br />
        </h4>
      </div>
      <div className="Main">
        <div className="Left-Column">

          {/* SUMMARY */}
          {
            resumeData.AllSections[0].isSection &&
            <div className="Summary-Information summary-edit">
              <h2 style={combineHeadingStyle}>{AllSections[0].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
              <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />
            </div>}

          {/* SKILLS */}
          {
            resumeData.AllSections[3].isSection &&
            <div className="skill-edit common-list-styling">
              <h2 style={combineHeadingStyle}>{AllSections[3].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
              <ul style={{ borderColor: themeColor }}>
                {AllSections[3].list.map(skill => {
                  const { listId, skillName } = skill;
                  return (
                    <li style={{ backgroundColor: themeColor }} key={listId}>
                      <p style={paraStyleCSS}>{skillName}</p>
                    </li>
                  )
                })}
              </ul>
            </div>}

          {/* PROJECTS */}
          {
            resumeData.AllSections[2].isSection &&
            <div className="project-edit common-section">
              <h2 style={combineHeadingStyle}>{AllSections[2].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
              {AllSections[2].list.map(Project => {
                const { listId, projectName, startDate, endDate, aboutProject } = Project;
                return (
                  <div className="common-Details" key={listId}>
                    <p className="cmn-date" style={paraStyleCSS}><i>{startDate} - <br /> {endDate}</i></p>
                    <div>
                      <p style={paraStyleCSS}><b>{projectName}</b> </p>
                      <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
                    </div>
                  </div>
                )
              })}
            </div>}

          {AllSections[7].isSection && <div className='interest-edit common-list-styling'>
            <h2 style={combineHeadingStyle}>{AllSections[7].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
            <ul>
              {AllSections[7].list.map(hobbies => {
                const { listId, interest } = hobbies;
                return (
                  <li style={{ backgroundColor: themeColor }} key={listId}>
                    <p style={paraStyleCSS}> {interest}</p>
                  </li>
                )
              })}
            </ul>
          </div>}
          {AllSections[6].isSection && <div className='language-edit common-list-styling'>
            <h2 style={combineHeadingStyle}>{AllSections[6].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
            <ul>
              {AllSections[6].list.map(languages => {
                const { listId, languageName, languageProfiency } = languages;
                return (
                  <li style={{ backgroundColor: themeColor }} key={listId}>
                    <p style={paraStyleCSS}>{languageName} {languageProfiency !== "None" && ` - ${languageProfiency}`}</p>
                  </li>
                )
              })}
            </ul>
          </div>}
        </div>
        <div className="Right-Column">

          {/* CONTACT */}
          {
            resumeData.AllSections[1].isSection &&
            <div className="Contact-Information contact-edit">
              <ul>
                {AllSections[1].list.map(element => {
                  const { listId, iconName, contactName, additionalLink } = element;
                  const handleItemClick = (e) => {
                    if (!additionalLink) {
                      e.preventDefault();
                    }
                  };
                  return (
                    <li
                      key={listId}
                      style={{ cursor: 'pointer' }}
                      onClick={handleItemClick}
                    >
                      {additionalLink ? (
                        <a
                          href={additionalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
                        >
                          <i style={{ backgroundColor: themeColor }} className={iconName} />
                          <p style={paraStyleCSS}>{contactName}</p>
                        </a>
                      ) : (
                        <>
                          <i style={{ backgroundColor: themeColor }} className={iconName} />
                          <p style={paraStyleCSS}>{contactName}</p>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>}

          {/* EDUCATION */}
          {
            resumeData.AllSections[5].isSection &&
            <div className="education-edit common-section">
              <h2 style={combineHeadingStyle}>{AllSections[5].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
              {AllSections[5].list.map(Education => {
                const { listId, collegeName, course, startDate, endDate, aboutEducation } = Education;
                return (
                  <div className="common-Details" key={listId}>
                    <p className="cmn-date" style={paraStyleCSS}> <i>{startDate} - <br /> {endDate}</i> </p>
                    <div>
                      <p style={paraStyleCSS}> <b>{course}</b></p>
                      <p style={paraStyleCSS}><b>{collegeName}</b></p>
                      <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} />
                    </div>
                  </div>
                )
              })}
            </div>}

          {/* JOB EXP */}
          {
            resumeData.AllSections[4].isSection &&
            <div className="job-exp-edit common-section">
              <h2 style={combineHeadingStyle}>{AllSections[4].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
              {AllSections[4].list.map(Experience => {
                const { listId, companyName, jobRole, startDate, endDate, aboutJob } = Experience;
                return (
                  <div className="common-Details" key={listId}>
                    <p className="cmn-date" style={paraStyleCSS}><i>{startDate} - <br /> {endDate}</i></p>
                    <div>
                      <p style={paraStyleCSS}><b>{companyName}</b></p>
                      <p style={paraStyleCSS}><b>{jobRole}</b> </p>
                      <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
                    </div>
                  </div>
                )
              })}
            </div>}
          {AllSections[8].isSection && <div className='certification-edit common-section'>
            <h2 style={combineHeadingStyle}>{AllSections[8].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
            {AllSections[8].list.map(Project => {
              const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
              return (
                <div className="common-Details" key={listId}>
                  <p className="cmn-date" style={paraStyleCSS}><i>{startDate} - <br /> {endDate}</i></p>
                  <div>
                    <p style={paraStyleCSS}><b>{instituteName}</b></p>
                    <p style={paraStyleCSS}><b>{certificateName}</b> </p>
                    <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} />
                  </div>
                </div>
              )
            })}
          </div>}
        </div>
      </div>
    </div>
  )
}
