import React, { useContext, useState } from 'react';
import GlobalContext from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import '../Resume-process-css/skill.css';
import SaveAlert from '../alerts/SaveAlert';
import { skillsList } from '../popup/ArraySuggestions';
import SuggestionPopup from '../popup/SuggestionPopup';
import ResumePreviewPopup from '../popup/ResumePreviewPopup';
import { TemplateData } from '../templates/TemplateData';


export default function Skills({ isLast }) {
    const { currentTemplateData, ChangeListValue, DeleteItem, AddItem, ChangeSectionValue, authtoken, setIsSaveData, backServer } = useContext(GlobalContext);
    const { sectionName, list } = currentTemplateData.AllSections[3];
    const [isSkillSuggestion, setIsSkillSuggestion] = useState("");
    const [isPreview, setIsPreview] = useState(false);

    const navigate = useNavigate();
    const objectData = {
        listId: new Date().getTime().toString(),
        skillName: ""
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!authtoken) {
                localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
                isLast && navigate(isLast);
                //RESUME PREVIEW
                !isLast && setIsPreview(
                    <ResumePreviewPopup
                        element={TemplateData.map((element) => {
                            if (element.id === currentTemplateData.id)
                                return <element.element resumeData={currentTemplateData} />;
                        })}
                        cancel={() => setIsPreview(false)}
                    />
                )
                return;
            }

            await fetch(`${backServer}/dashboard/update-templates`, {
                method: "POST",
                body: JSON.stringify(currentTemplateData),
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': authtoken
                }
            })
            setIsSaveData(<SaveAlert status={"show"} alertMsg={"All changes saved"} />);
            setTimeout(() => {
                setIsSaveData(<SaveAlert status={"hide"} alertMsg={"All changes saved"} />);
            }, 800);
            //ALSO SAVE IN LOCAL STORAGE
            localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
            isLast && navigate(isLast);
            //RESUME PREVIEW
            !isLast && setIsPreview(
                <ResumePreviewPopup
                    element={TemplateData.map((element) => {
                        if (element.id === currentTemplateData.id)
                            return <element.element resumeData={currentTemplateData} />;
                    })}
                    cancel={() => setIsPreview(false)}
                />
            )
        } catch (error) {
            console.log("Server error.", error);
        }
    };

    return (
        <>
            {isPreview}
            <div className="edit-skills-form">
                <h2 className="cg-form-h2">Skills</h2>
                <form onSubmit={handleSubmit}>
                    <div className="cg-wrap-inputs">
                        <input type="text" className="cg-form-input" value={sectionName} name='sectionName' onChange={(e) => ChangeSectionValue(e, 3)} placeholder='Section name' />
                        {list.map((element) => {
                            const { skillName, listId } = element;
                            return (
                                <>
                                    <div className="skills-list">
                                        <input onClick={() => setIsSkillSuggestion(listId)} type="text" name='skillName' value={skillName} onChange={(e) => ChangeListValue(e, 3, listId)} placeholder='Add skill' />
                                        <i className='fa-solid fa-trash' onClick={() => DeleteItem(3, listId)}></i>
                                    </div>
                                    {isSkillSuggestion === listId ? <SuggestionPopup listArray={skillsList} cancel={() => setIsSkillSuggestion("")} PopupId={listId} PopSet={skillName} /> : <></>}
                                </>
                            );
                        })}
                    </div>
                    <a className="cg-web-form-fill-btn add-section" onClick={() => AddItem(3, objectData)}><i className='fa-regular fa-plus'></i> Add Section </a>
                    <button className="cg-web-form-fill-btn" type="submit">
                        Save and {isLast ? "Next" : "Preview"}
                        <i className="fa-regular fa-arrow-right" />
                    </button>
                </form>
            </div>
        </>
    );
}
