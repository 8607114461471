import React, { useContext, useState } from 'react';
import GlobalContext from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import SaveAlert from '../alerts/SaveAlert';
import { AiTextPopup } from '../popup/AiTextPopup';
import ProjectJobEduTextEditor from '../popup/ProjectJobEduTextEditor';
import ResumePreviewPopup from '../popup/ResumePreviewPopup';
import { TemplateData } from '../templates/TemplateData';

export default function Summary({ isLast }) {
    const [aiTextSuggestion, setAiTextSuggestion] = useState(false);
    const { currentTemplateData, ChangeSectionValue, authtoken, setIsSaveData, backServer } = useContext(GlobalContext);
    const [isPreview, setIsPreview] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!authtoken) {
                localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
                isLast && navigate(isLast);
                //RESUME PREVIEW
                !isLast && setIsPreview(
                    <ResumePreviewPopup
                        element={TemplateData.map((element) => {
                            if (element.id === currentTemplateData.id)
                                return <element.element resumeData={currentTemplateData} />;
                        })}
                        cancel={() => setIsPreview(false)}
                    />
                )
                return;
            }

            await fetch(`${backServer}/dashboard/update-templates`, {
                method: "POST",
                body: JSON.stringify(currentTemplateData),
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': authtoken
                }
            });
            setIsSaveData(<SaveAlert status={"show"} alertMsg={"All changes saved"} />);
            setTimeout(() => {
                setIsSaveData(<SaveAlert status={"hide"} alertMsg={"All changes saved"} />);
            }, 800);
            // Also save in local storage
            localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
            isLast && navigate(isLast);
            //RESUME PREVIEW
            !isLast && setIsPreview(
                <ResumePreviewPopup
                    element={TemplateData.map((element) => {
                        if (element.id === currentTemplateData.id)
                            return <element.element resumeData={currentTemplateData} />;
                    })}
                    cancel={() => setIsPreview(false)}
                />
            )
        } catch (error) {
            console.log("Server error.", error);
        }
    };

    const handleTextChange = (newText) => {
        // Update the summary in currentTemplateData
        ChangeSectionValue({ target: { name: 'summary', value: newText } }, 0);
        setAiTextSuggestion(false); // Close the popup after selecting the text
    };

    return (
        <>
            {isPreview}
            <div className='summary-form'>
                <h2 className="cg-form-h2">Summary</h2>
                <form onSubmit={handleSubmit}>
                    <div className="cg-wrap-inputs">
                        <input
                            type="text"
                            className="cg-form-input"
                            name="sectionName"
                            value={currentTemplateData.AllSections[0].sectionName}
                            onChange={(e) => ChangeSectionValue(e, 0)}
                            required
                            placeholder='About me'
                        />
                        <div className="cg-text-editor">
                            <ProjectJobEduTextEditor value={currentTemplateData.AllSections[0].summary} onChange={(e) => {
                                ChangeSectionValue({ target: { name: "summary", value: e } }, 0);
                                console.log(e, "by summary");
                            }} />
                            <div className="cg-ai-text-suggestion" title='Use AI to enhance your content.' onClick={() => setAiTextSuggestion(true)}>
                                {/* <img
                                    src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png"
                                    alt="AI Suggestion" draggable="false" title='Generate text'
                                    onClick={() => setAiTextSuggestion(true)}
                                /> */}
                                 <div className="ai-text-animated-line">
                                 <i class="fa-light fa-sparkles"></i>
                                <p>Generate text</p>
                            </div>
                            </div>
                            {aiTextSuggestion && (
                                <AiTextPopup
                                    prompt={currentTemplateData.AllSections[0].summary}
                                    cancel={() => setAiTextSuggestion(false)}
                                    onTextChange={handleTextChange}
                                />
                            )}
                        </div>
                    </div>
                    <button className="cg-web-form-fill-btn" type='submit'>
                        Save and {isLast ? "Next" : "Preview"}
                        <i className="fa-regular fa-arrow-right" />
                    </button>
                </form>
            </div>
        </>
    );
}
