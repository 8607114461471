import React, { useState, useEffect, useContext } from 'react';
import './EditBlogPopup.css';
import TextEditorAd from '../cg-admin-popup/TextEditorAd'; // Assuming this is the text editor component you're using
import GlobalContext from '../context/GlobalContext';

function EditBlogPopup({ cancel, post, onUpdate }) {
  const { title, excerpt, author, featuredImage, description, slug, visibility, scheduledDate, _id } = post;
  const [titleState, setTitleState] = useState(title);
  const [excerptState, setExcerptState] = useState(excerpt);
  const [authorState, setAuthorState] = useState(author);
  const [image, setImage] = useState(featuredImage);
  const [editorContent, setEditorContent] = useState(description);
  const [slugState, setSlugState] = useState(slug);
  const [visibilityState, setVisibilityState] = useState(visibility);
  const [scheduledDateState, setScheduledDate] = useState(scheduledDate || { date: '', time: '' });
  const [showScheduleInput, setShowScheduleInput] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [isEditingSchedule, setIsEditingSchedule] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);


  useEffect(() => {
    // Reset the state if the post changes
    setTitleState(title);
    setExcerptState(excerpt);
    setAuthorState(author);
    setImage(featuredImage);
    setEditorContent(description);
    setSlugState(slug);
    setVisibilityState(visibility);
    setScheduledDate(scheduledDate || { date: '', time: '' });
  }, [post]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        // Update state with base64 image data
        setImage(base64String);
      };
      // Read the selected file as data URL (base64)
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage('/images/Blog-Banner-Placeholder.png');
    setIsDataChanged(true);
  };

  const handleSave = (e) => {
    e.preventDefault();

    const updatedPost = {
      _id,
      title: titleState,
      excerpt: excerptState,
      author: authorState,
      image,
      description: editorContent,
      visibility: visibilityState,
      scheduledDate: scheduledDateState,
      slug: slugState,
    };


    onUpdate(updatedPost);
    setIsDataChanged(false);
  };

  const handleCancel = () => {
    if (isDataChanged) {
      const confirmClose = window.confirm('You have unsaved changes. Do you want to save the changes?');
      if (confirmClose) {
        handleSave(new Event('submit'));
      } else {
        cancel();
        setIsDataChanged(false);
      }
    } else {
      cancel();
    }
  };

  // Handle radio button change for visibility
  const handleRadioChange = (e) => {
    const selectedVisibility = e.target.id;
    setVisibilityState(selectedVisibility);

    if (selectedVisibility) {
      setVisibilityState(selectedVisibility);
    } else {
      setVisibilityState('uncategorized');
    }

    if (selectedVisibility === 'scheduled') {
      setShowScheduleInput(true);
    } else {
      setShowScheduleInput(false);
      setScheduledDate({ date: '', time: '' });
      setIsScheduled(false);
    }
  };

  // Handle saving the schedule
  const handleSaveSchedule = () => {
    if (scheduledDateState.date && scheduledDateState.time) {
      setIsScheduled(true);
      setShowScheduleInput(false);
      setIsEditingSchedule(false);
    }
  };

  // Handle rescheduling
  const handleReschedule = () => {
    setIsEditingSchedule(true);
    setShowScheduleInput(true);
    setIsScheduled(false);
  };

  // Handle reset schedule
  const handleResetSchedule = () => {
    setScheduledDate({ date: '', time: '' });
    setIsScheduled(false);
  };

  return (
    <div className="blog-post-popup-overlay">
      <div className="blog-post-popup-container">
        <h2>Edit Post</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          className="cancel-btn"
          onClick={handleCancel}
        >
          <path
            d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6"
            stroke="#252046"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <form onSubmit={handleSave}>
          {/* Image upload section */}
          <div className="bp-text">
            <h4>Featured Image</h4>
            <div className="blog-image-grp">
              <div
                className="blog-image-preview"
                style={{ backgroundImage: `url(${image})`, cursor: 'pointer', border: '1px solid #e5e5e5' }}
                onClick={() => document.getElementById('blog-upload-img').click()}
              ></div>
              <input
                id="blog-upload-img"
                className="blog-upload-img"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <label htmlFor="blog-upload-img">Upload Image</label>
              {image !== '/images/Blog-Banner-Placeholder.png' && (
                <button type="button" className="remove-btn" onClick={handleRemoveImage}>
                  Remove Image
                </button>
              )}
            </div>
          </div>

          {/* Title */}
          <div className="bp-text">
            <h4>Title</h4>
            <input
              type="text"
              placeholder="Post title"
              value={titleState}
              onChange={(e) => {
                setTitleState(e.target.value);
                setIsDataChanged(true);
              }}
            />
          </div>

          {/* Excerpt */}
          <div className="bp-text">
            <h4>Excerpt</h4>
            <input
              type="text"
              placeholder="Post excerpt"
              value={excerptState}
              onChange={(e) => {
                setExcerptState(e.target.value);
                setIsDataChanged(true);
              }}
            />
          </div>

          {/* Author */}
          <div className="bp-text">
            <h4>Author</h4>
            <input
              type="text"
              placeholder="Post author"
              value={authorState}
              onChange={(e) => {
                setAuthorState(e.target.value);
                setIsDataChanged(true);
              }}
            />
          </div>

          {/* Slug */}
          <div className="bp-text">
            <h4>Slug</h4>
            <input
              type="text"
              placeholder="Post URL slug"
              value={slugState}
              onChange={(e) => {
                setSlugState(e.target.value);
                setIsDataChanged(true);
              }}
            />
          </div>

          {/* Content */}
          <div className="cg-create-bp">
            <h4>Content</h4>
            <TextEditorAd value={editorContent} onChange={(value) => { setEditorContent(value); setIsDataChanged(true); }} />
          </div>

          {/* Visibility */}
          <div className="bp-text">
            <h4>Visibility</h4>
            <div className="bp-radio-input-grp">
              <div className="radio-inp-sep">
                <input
                  type="radio"
                  id="visible"
                  name="visibility"
                  onChange={handleRadioChange}
                  checked={visibilityState === 'visible'}
                />
                <label htmlFor="visible">Visible</label>
              </div>
              <div className="radio-inp-sep">
                <input
                  type="radio"
                  id="hidden"
                  name="visibility"
                  onChange={handleRadioChange}
                  checked={visibilityState === 'hidden'}
                />
                <label htmlFor="hidden">Hidden</label>
              </div>
              <div className="radio-inp-sep">
                <input
                  type="radio"
                  id="draft"
                  name="visibility"
                  onChange={handleRadioChange}
                  checked={visibilityState === 'draft'}
                />
                <label htmlFor="draft">Draft</label>
              </div>
              <div className="radio-inp-sep">
                <input
                  type="radio"
                  id="scheduled"
                  name="visibility"
                  onChange={handleRadioChange}
                  checked={visibilityState === 'scheduled'}
                />
                <label htmlFor="scheduled">Scheduled</label>
              </div>
            </div>
          </div>

          {/* Scheduled input form */}
          {showScheduleInput && !isScheduled && (
            <div id="schedule-input" className="schedule-grp">
              <div className="sch-grp-a">
                <label htmlFor="schedule-date">Select Date:</label>
                <input
                  type="date"
                  id="schedule-date"
                  onChange={(e) =>
                    setScheduledDate({ ...scheduledDateState, date: e.target.value })
                  }
                  value={scheduledDateState.date || ''}
                />
              </div>
              <div className="sch-grp-b">
                <label htmlFor="schedule-time">Select Time:</label>
                <input
                  type="time"
                  id="schedule-time"
                  onChange={(e) =>
                    setScheduledDate({ ...scheduledDateState, time: e.target.value })
                  }
                  value={scheduledDateState.time || ''} />
              </div>

              {scheduledDateState.date && scheduledDateState.time && (
                <>
                  <button type="button" onClick={handleSaveSchedule}>
                    Save Schedule
                  </button>
                  <button type="button" onClick={handleResetSchedule}>
                    Reset
                  </button>
                </>
              )}
            </div>
          )}
          {isScheduled && (
            <div className="scheduled-label">
              <p>
                Post Scheduled for {scheduledDateState.date} at {scheduledDateState.time}
              </p>
              <button type="button" onClick={handleReschedule}>
                Reschedule
              </button>
            </div>
          )}
          <div className="save-blog-btn-container">
            <button type="submit">Save Blog</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditBlogPopup;


