import React, { useContext, useState } from 'react';
import GlobalContext from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import '../Resume-process-css/project.css';
import SaveAlert from '../alerts/SaveAlert';
import { AiTextPopup } from '../popup/AiTextPopup';
import ProjectJobEduTextEditor from '../popup/ProjectJobEduTextEditor';
import MonthYearPicker from '../popup/MonthYearPicker';
import ResumePreviewPopup from '../popup/ResumePreviewPopup';
import { TemplateData } from '../templates/TemplateData';

export default function Project({ isLast }) {
  const [aiTextSuggestion, setAiTextSuggestion] = useState(false);
  const { currentTemplateData, DeleteItem, AddItem, ChangeListValue, ChangeSectionValue, authtoken, setIsSaveData, backServer } = useContext(GlobalContext);
  const index = 2;
  const { sectionName, list } = currentTemplateData.AllSections[index];
  const [isMonthYearPopup, setIsMonthYearPopup] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const navigate = useNavigate();

  const objectData = {
    listId: new Date().getTime().toString(),
    projectName: "Project name",
    startDate: "May, 2000",
    endDate: "May, 2025",
    aboutProject: "Description"
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!authtoken) {
        localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
        isLast && navigate(isLast);
        //RESUME PREVIEW
        !isLast && setIsPreview(
          <ResumePreviewPopup
            element={TemplateData.map((element) => {
              if (element.id === currentTemplateData.id)
                return <element.element resumeData={currentTemplateData} />;
            })}
            cancel={() => setIsPreview(false)}
          />
        )
        return;
      }

      await fetch(`${backServer}/dashboard/update-templates`, {
        method: "POST",
        body: JSON.stringify(currentTemplateData),
        headers: {
          'Content-Type': 'application/json',
          'auth-token': authtoken
        }
      });
      setIsSaveData(<SaveAlert status={"show"} alertMsg={"All changes saved"} />);
      setTimeout(() => {
        setIsSaveData(<SaveAlert status={"hide"} alertMsg={"All changes saved"} />);
      }, 800);
      localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
      isLast && navigate(isLast);
      //RESUME PREVIEW
      !isLast && setIsPreview(
        <ResumePreviewPopup
          element={TemplateData.map((element) => {
            if (element.id === currentTemplateData.id)
              return <element.element resumeData={currentTemplateData} />;
          })}
          cancel={() => setIsPreview(false)}
        />
      )
    } catch (error) {
      console.log("Server error.", error);
    }
  };

  const handleTextChange = (newText, listId) => {
    ChangeListValue({ target: { name: 'aboutProject', value: newText } }, index, listId);
    setAiTextSuggestion(false);
  }

  return (
    <>
      {isPreview}
      <div className="project-form">
        <h2 className="cg-form-h2">Projects</h2>
        <form onSubmit={handleSubmit}>
          <div className='cg-wrap-inputs'>
            <input
              type="text"
              className="cg-form-input"
              name="sectionName"
              value={sectionName}
              placeholder='Section Name'
              onChange={(e) => ChangeSectionValue(e, 2)}
            />
            {list.map((element) => {
              const { listId, projectName, startDate, endDate, aboutProject } = element;
              return (
                <div className='project-number-box' key={listId}>
                  <input
                    type="text"
                    className="cg-form-input"
                    name="projectName"
                    value={projectName}
                    placeholder='Project name'
                    onChange={(e) => ChangeListValue(e, index, listId)}
                  />
                  <div className='project-form-section-pic-date'>
                    <div className="month-year-pickers">
                      <div className="start-end-date" onClick={() => setIsMonthYearPopup({ listId, isStartDate: true })}>
                        <p>{startDate}</p>
                        <i class="fa-regular fa-calendar-range"></i>
                      </div>
                      {isMonthYearPopup.listId === listId && isMonthYearPopup.isStartDate ? <MonthYearPicker cancel={() => setIsMonthYearPopup(false)} startingDate={true} listId={listId} sectionIndex={index} name={"startDate"} /> : <></>}
                    </div>
                    <div className="month-year-pickers">
                      <div className="start-end-date" onClick={() => setIsMonthYearPopup({ listId, isStartDate: false })}>
                        <p>{endDate ? <>{endDate}</> : <>Choose date</>}</p>
                        <i class="fa-regular fa-calendar-range"></i>
                      </div>
                      {isMonthYearPopup.listId === listId && !isMonthYearPopup.isStartDate ? <MonthYearPicker cancel={() => setIsMonthYearPopup(false)} startingDate={false} listId={listId} sectionIndex={index} name={"endDate"} /> : <></>}
                    </div>
                  </div>
                  <div className="cg-text-editor">
                    <ProjectJobEduTextEditor
                      name="aboutProject"
                      value={aboutProject}
                      onChange={(newText) => handleTextChange(newText, listId)}
                      required
                    />

                    <div className="cg-ai-text-suggestion" title='Use AI to enhance your content.' onClick={() => setAiTextSuggestion(listId)}>
                      <div className="ai-text-animated-line">
                        <i className="fa-light fa-sparkles"></i>
                        <p>Generate text</p>
                      </div>
                    </div>
                    {aiTextSuggestion === listId && (
                      <AiTextPopup
                        prompt={aboutProject}
                        cancel={() => setAiTextSuggestion(false)}
                        onTextChange={(newText) => handleTextChange(newText, listId)}
                      />
                    )}
                  </div>
                  <div className='project-form-del-add-button'>
                    <hr />
                    <i className='fa-solid fa-trash' onClick={() => DeleteItem(index, listId)}></i>
                  </div>
                </div >

              )
            })}
          </div>
          <a className="cg-web-form-fill-btn add-section" onClick={() => AddItem(index, objectData)}>
            <i className='fa-regular fa-plus'></i> Add Section
          </a>
          <button className="cg-web-form-fill-btn" type='submit'>
            Save and {isLast ? "Next" : "Preview"}
            <i className="fa-regular fa-arrow-right" />
          </button>
        </form >
      </div >
    </>
  );
}
