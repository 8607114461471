import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import '../Resume-process-css/personaldetails.css';
import SaveAlert from '../alerts/SaveAlert';
import ResumePreviewPopup from '../popup/ResumePreviewPopup';
import { TemplateData } from '../templates/TemplateData';

export default function PersonalDetails({ isLast }) {
    const { currentTemplateData, setCurrentTemplateData, authtoken, setIsSaveData, backServer } = useContext(GlobalContext);
    const [imgSrc, setImageSrc] = useState(currentTemplateData.profileImage);
    const navigate = useNavigate();
    const [isPreview, setIsPreview] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentTemplateData({ ...currentTemplateData, [name]: value, isPersonaldetailschange: "fa fa-check-circle ischeck" });

    };

    const handleImageChange = (e) => {
        const imageSource = URL.createObjectURL(e.target.files[0]);

        setCurrentTemplateData({ ...currentTemplateData, [e.target.name]: imageSource, isPersonaldetailschange: "fa fa-check-circle ischeck" });
        setImageSrc(imageSource);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!authtoken) {
                localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
                isLast && navigate(isLast);
                //RESUME PREVIEW
                !isLast && setIsPreview(
                    <ResumePreviewPopup
                        element={TemplateData.map((element) => {
                            if (element.id === currentTemplateData.id)
                                return <element.element resumeData={currentTemplateData} />;
                        })}
                        cancel={() => setIsPreview(false)}
                    />
                )
                return;
            }

            await fetch(`${backServer}/dashboard/update-templates`, {
                method: "POST",
                body: JSON.stringify(currentTemplateData),
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': authtoken
                }
            })
            setIsSaveData(<SaveAlert status={"show"} alertMsg={"All changes saved"} />);
            setTimeout(() => {
                setIsSaveData(<SaveAlert status={"hide"} alertMsg={"All changes saved"} />);
            }, 800);

            //ALSO SAVE IN LOCAL STORAGE
            localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
            isLast && navigate(isLast);

            //RESUME PREVIEW
            !isLast && setIsPreview(
                <ResumePreviewPopup
                    element={TemplateData.map((element) => {
                        if (element.id === currentTemplateData.id)
                            return <element.element resumeData={currentTemplateData} />;
                    })}
                    cancel={() => setIsPreview(false)}
                />
            )

        } catch (error) {
            console.log("Server error.", error);
        }
    };

    return (
        <>
            {isPreview}
            <div className='personal-details-form'>
                <h2 className="cg-form-h2">Personal Details</h2>
                <form onSubmit={handleSubmit}>
                    {currentTemplateData.profileImage ? (
                        <div className='personal-details-upload-photo'>
                            <div className="profileImage" style={{ backgroundImage: `url(${imgSrc})` }}></div>
                            <div className="up-img-type">
                                <p>Accepted file type</p>
                                <p>
                                    <span className="f-type">.jpg</span>
                                    <span className="f-type">.png</span>
                                    <span className="f-type">.svg</span>
                                </p>
                                <label htmlFor='uploadProfilePic' className='photo-upload'>
                                    <i className="fa-light fa-arrow-up-from-bracket" />Upload Image
                                </label>
                                <input
                                    type='file'
                                    id='uploadProfilePic'
                                    name='profileImage'
                                    onChange={handleImageChange}
                                    accept='image/*'
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="cg-wrap-inputs">
                        <input
                            type='text'
                            className='cg-form-input'
                            name='userName'
                            value={currentTemplateData.userName}
                            onChange={handleChange}
                            required
                            placeholder='Your name'
                        />
                        <input
                            type='text'
                            className='cg-form-input'
                            name='userJobRole'
                            value={currentTemplateData.userJobRole}
                            onChange={handleChange}
                            placeholder='Job Role'
                        />
                    </div>
                    <button className="cg-web-form-fill-btn" type='submit'>
                        Save and {isLast ? "Next" : "Preview"}
                        <i className="fa-regular fa-arrow-right" />
                    </button>
                </form>
            </div>
        </>
    );
}
