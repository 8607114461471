import React, { useContext } from "react";
import "./HMATSTamplate.css";
import { QRCodeCanvas } from "qrcode.react";
import GlobalContext from "../../context/GlobalContext";

function HMATSTamplate({ resumeData }) {
  const { AllSections, isQRCode, resumeStyle } = resumeData;
  const { liveTemplateURL } = useContext(GlobalContext);

  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    color: resumeData.headingTextColor,
  };

  const { backgroundPattern } = resumeStyle;

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`,
    lineHeight: `${resumeData.bodyTextSize + 8}px`
  };

  const boldParaStyleCss = {
    ...paraStyleCSS, fontSize: `${resumeData.bodyTextSize + 2}px`
  }

  const combineHeadingStyle = {
    ...headingStyleCSS,
    fontSize: `${resumeData.headingTextSize}px`,
  };

  return (
    <div
      className="header-modern-template-format-resume-template"
      style={{
        backgroundImage: `url("/background-pattern/${backgroundPattern}")`,
      }}
    >
      {isQRCode ? (
        <div className="resume-qr-code">
          <QRCodeCanvas value={liveTemplateURL} size={"50"} />
        </div>
      ) : (
        <></>
      )}
      <div className="header-modern-template-format-section header-modern-template-format-name">
        {/* <!--CONTACT SECTION--> */}

        <div className="header-modern-template-format-section header-modern-template-format-name">
          {/* <!--CONTACT SECTION--> */}

          <div className="header-modern-template-format-section header-modern-template-format-contact-me">
            {resumeData.AllSections[1].isSection && (
              <div className="header-modern-template-format-contact contact-edit">
                {
                  AllSections[1].list.map((element) => {
                    const { listId, contactName, additionalLink } = element;


                    const handleItemClick = (e) => {
                      if (!additionalLink) {
                        e.preventDefault();
                      }
                    };

                    return (
                      <div
                        key={listId}
                        style={{ ...paraStyleCSS, cursor: 'pointer' }}
                        className="header-modern-template-format-contact-grp"
                        onClick={handleItemClick}
                      >
                        {additionalLink ? (
                          <a
                            href={additionalLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit', display: 'flex', gap: '10px' }}
                          >
                            <i className={element.iconName} style={{ fontSize: `${resumeData.bodyTextSize}px` }}></i>
                            <p>{contactName}</p>
                          </a>
                        ) : (
                          <>
                            <i className={element.iconName} style={{ fontSize: `${resumeData.bodyTextSize}px` }} ></i>
                            <p>{contactName}</p>
                          </>
                        )}
                      </div>
                    );
                  })
                }

              </div>
            )}
            <div className="header-modern-template-format-name-data personal-edit">
              <div className="header-modern-template-img-sec">
                <img
                  src={resumeData.profileImage}
                  alt=""
                  style={{ width: "150px", height: "150px", borderRadius: '50%' }}
                />
              </div>
              <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
              <h4 style={boldParaStyleCss}>{resumeData.userJobRole}</h4>
            </div>
          </div>
        </div>
      </div>

      {/* <!--ABOUT SECTION--> */}
      {resumeData.AllSections[0].isSection && (
        <div className="header-modern-template-format-section summary-information summary-edit">
          <h2 style={{ ...combineHeadingStyle, borderBottom: `2px solid ${resumeData.themeColor}` }}>{resumeData.AllSections[0].sectionName}</h2>
          <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />
        </div>
      )}
      {/* <!--PROJECT SECTION--> */}

      {resumeData.AllSections[2].isSection && (
        <div className="header-modern-template-format-section common-section project-edit">
          <h2 style={{ ...combineHeadingStyle, borderBottom: `2px solid ${resumeData.themeColor}` }}> {resumeData.AllSections[2].sectionName}</h2>
          <div>
            {resumeData.AllSections[2].list.map((element, index) => {
              const { projectName, startDate, endDate, aboutProject } = element;
              return (
                <div className="common-details" key={index}>
                  <div className="common-level">
                    <p className="cl-p-a"><b style={boldParaStyleCss}>{projectName}</b></p>
                    <p className="cl-p-b"><b style={boldParaStyleCss}>{startDate} - {endDate}</b></p>
                  </div>
                  <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* <!--JOB SECTION--> */}
      {resumeData.AllSections[4].isSection && (
        <div className="header-modern-template-format-section common-section job-exp-edit">
          <h2 style={{ ...combineHeadingStyle, borderBottom: `2px solid ${resumeData.themeColor}` }}> {resumeData.AllSections[4].sectionName}</h2>
          {resumeData.AllSections[4].list.map((element, index) => {
            const { companyName, jobRole, startDate, endDate, aboutJob } =
              element;
            return (
              <div className="common-details" key={index}>
                <div className="common-level">
                  <p><b style={boldParaStyleCss}>{companyName}</b></p>
                  <p><b style={boldParaStyleCss}>{startDate} - {endDate}</b></p>
                </div>
                <p><b style={boldParaStyleCss}>{jobRole}</b></p>
                <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
              </div>
            );
          })}
        </div>
      )}

      {/* <!--SKILL SECTION--> */}

      {resumeData.AllSections[3].isSection && (
        <div className="header-modern-template-format-section common-list-styling skill-edit">
          <h2 style={{ ...combineHeadingStyle, borderBottom: `2px solid ${resumeData.themeColor}` }}>{resumeData.AllSections[3].sectionName} </h2>
          <ul className="common-list-styling">
            {resumeData.AllSections[3].list.map((element) => {
              return (
                <li style={paraStyleCSS}>{element.skillName}</li>
              );
            })}
          </ul>
        </div>
      )}

      {AllSections[7].isSection && <div className='header-modern-template-format-section common-list-styling interest-edit'>
        <h2 style={{ ...combineHeadingStyle, borderBottom: `2px solid ${resumeData.themeColor}` }}>{AllSections[7].sectionName}</h2>
        <ul className="common-list-styling">
          {AllSections[7].list.map(hobbies => {
            const { listId, interest } = hobbies;
            return (
              <li style={paraStyleCSS} key={listId}>
                {interest}
              </li>
            )
          })}
        </ul>
      </div>}
      {/* <!--EDUCATION SECTION--> */}
      {resumeData.AllSections[5].isSection && (
        <div className="header-modern-template-format-section common-section education-edit">
          <h2 style={{ ...combineHeadingStyle, borderBottom: `2px solid ${resumeData.themeColor}` }}>{resumeData.AllSections[5].sectionName}</h2>
          {resumeData.AllSections[5].list.map((element, index) => {
            const { collegeName, course, startDate, endDate, aboutEducation } = element;
            return (
              <div className="common-details" key={index}>
                <div className="common-level">
                  <p><b style={boldParaStyleCss}>{collegeName}</b></p>
                  <p><b style={boldParaStyleCss}>{startDate} - {endDate}</b></p>
                </div>
                <p><b style={boldParaStyleCss}>{course}</b></p>
                <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} />
              </div>
            );
          })}
        </div>
      )}
      {AllSections[8].isSection && <div className=' header-modern-template-format-section common-section certification-edit '>
        <h2 style={{ ...combineHeadingStyle, borderBottom: `2px solid ${resumeData.themeColor}` }}>{AllSections[8].sectionName}</h2>
        {AllSections[8].list.map(Project => {
          const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
          return (
            <div className="common-details" key={listId}>
              <div className="common-level">
                <p><b style={boldParaStyleCss}>{instituteName}</b></p>
                <p><b style={boldParaStyleCss}>{startDate} - {endDate}</b></p>
              </div>
              <p><b style={boldParaStyleCss}>{certificateName}</b></p>
              <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} />
            </div>
          )
        })}
      </div>}
      {AllSections[6].isSection && <div className='header-modern-template-format-section common-list-styling language-edit'>
        <h2 style={{ ...combineHeadingStyle, borderBottom: `2px solid ${resumeData.themeColor}` }}>{AllSections[6].sectionName}</h2>
        <ul className="common-list-styling">
          {AllSections[6].list.map(languages => {
            const { listId, languageName, languageProfiency } = languages;
            return (
              <li style={paraStyleCSS} key={listId}>
                {languageName} {languageProfiency !== "None" && ` - ${languageProfiency}`}
              </li>
            )
          })}
        </ul>
      </div>}
    </div>
  );
}
export default HMATSTamplate;