import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import AdminDashboard from "./AdminDashboard";
import AdminLogin from "./AdminLogin";
import { jwtDecode } from "jwt-decode";

export default function AdminPanel() {
    const { auth_admin } = useContext(GlobalContext);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (!auth_admin) {
            setIsAdmin(false);
            return;
        }
        try {
            const decoded = jwtDecode(auth_admin);
            if (decoded.role === 'admin') {
                setIsAdmin(true);
            } else {
                setIsAdmin(false)
            }
        } catch (err) {
            alert("Bhak bhenc****d")
        }
    }, [])

    return (
        isAdmin ? <AdminDashboard /> : <AdminLogin />
    )
}