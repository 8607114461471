import React, { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import { TemplateData } from "../templates/TemplateData";
import { AiTextPopup } from "../popup/AiTextPopup";
import ProjectJobEduTextEditor from "../popup/ProjectJobEduTextEditor";
import MonthYearPicker from "../popup/MonthYearPicker";
import './Certificate.css';
import ResumePreviewPopup from "../popup/ResumePreviewPopup";
import { useNavigate } from "react-router-dom";

export default function Certificate({ isLast }) {

    const [aiTextSuggestion, setAiTextSuggestion] = useState(false);
    const [isMonthYearPopup, setIsMonthYearPopup] = useState(false);
    const navigate = useNavigate();
    const {
        currentTemplateData,
        DeleteItem,
        AddItem,
        ChangeListValue,
        ChangeSectionValue,
        authtoken,
        backServer,
    } = useContext(GlobalContext);

    const index = 8; // Ensure this index corresponds to the certificate section
    const { sectionName, list } = currentTemplateData.AllSections[index];
    const [isPreview, setIsPreview] = useState(false);

    const objectData = {
        listId: new Date().getTime().toString(),
        certificateName: "Certificate Name",
        instituteName: "Your insitute",
        startDate: "YY",
        endDate: "YY",
        aboutCertificate: "Description",
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!authtoken) {
                localStorage.setItem(
                    "currentTemplate",
                    JSON.stringify(currentTemplateData)
                );
                isLast && navigate(isLast);
                //RESUME PREVIEW
                !isLast && setIsPreview(
                    <ResumePreviewPopup
                        element={TemplateData.map((element) => {
                            if (element.id === currentTemplateData.id)
                                return <element.element resumeData={currentTemplateData} />;
                        })}
                        cancel={() => setIsPreview(false)}
                    />
                )
                return; // Prevents further execution if no auth token
            }

            await fetch(`${backServer}/dashboard/update-templates`, {
                method: "POST",
                body: JSON.stringify(currentTemplateData),
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": authtoken,
                },
            });
            // Save in local storage
            localStorage.setItem("currentTemplate", JSON.stringify(currentTemplateData));
            isLast && navigate(isLast);
            //RESUME PREVIEW
            !isLast && setIsPreview(
                <ResumePreviewPopup
                    element={TemplateData.map((element) => {
                        if (element.id === currentTemplateData.id)
                            return <element.element resumeData={currentTemplateData} />;
                    })}
                    cancel={() => setIsPreview(false)}
                />
            )
        } catch (error) {
            console.log("Server error.", error);
        }
    };

    const handleTextChange = (newText, listId) => {
        ChangeListValue({ target: { name: 'aboutCertificate', value: newText } }, index, listId);
        setAiTextSuggestion(false);
    };

    return (
        <>
            <div className="certificate-form">
                <h2 className="cg-form-h2">Certificate</h2>
                <form onSubmit={handleSubmit}>
                    <div className="cg-wrap-inputs">
                        <input
                            type="text"
                            className="cg-form-input"
                            name="sectionName"
                            value={sectionName}
                            placeholder="Section name"
                            onChange={(e) => ChangeSectionValue(e, index)}
                            required
                        />
                        {list.map((element) => {
                            const {
                                listId,
                                certificateName,
                                instituteName,
                                startDate,
                                endDate,
                                aboutCertificate,
                            } = element;

                            return (
                                <div className="certificate-content" key={listId}>
                                    <input
                                        type="text"
                                        className="cg-form-input"
                                        name="instituteName"
                                        value={instituteName}
                                        onChange={(e) => ChangeListValue(e, index, listId)}
                                        placeholder="Institute name"
                                        required
                                    />

                                    <input
                                        type="text"
                                        className="cg-form-input"
                                        name="certificateName"
                                        value={certificateName}
                                        onChange={(e) => ChangeListValue(e, index, listId)}
                                        placeholder="Certificate name"
                                        required
                                    />
                                    <div className="certificate-datepic-monthdate">
                                        <div className="month-year-pickers">
                                            <div className="start-end-date" onClick={() => setIsMonthYearPopup({ listId, isStartDate: true })}>
                                                <p>{startDate}</p>
                                                <i className="fa-regular fa-calendar-range"></i>
                                            </div>
                                            {isMonthYearPopup.listId === listId && isMonthYearPopup.isStartDate ? <MonthYearPicker cancel={() => setIsMonthYearPopup(false)} startingDate={true} listId={listId} sectionIndex={index} name={"startDate"} /> : <></>}
                                        </div>
                                        <div className="month-year-pickers">
                                            <div className="start-end-date" onClick={() => setIsMonthYearPopup({ listId, isStartDate: false })}>
                                                <p>{endDate ? <>{endDate}</> : <>Choose date</>}</p>
                                                <i className="fa-regular fa-calendar-range"></i>
                                            </div>
                                            {isMonthYearPopup.listId === listId && !isMonthYearPopup.isStartDate ? <MonthYearPicker cancel={() => setIsMonthYearPopup(false)} startingDate={false} listId={listId} sectionIndex={index} name={"endDate"} /> : <></>}
                                        </div>
                                    </div>
                                    <div className="cg-text-editor">
                                        <ProjectJobEduTextEditor
                                            value={aboutCertificate}
                                            onChange={(newText) => handleTextChange(newText, listId)}
                                            required
                                        />
                                        <div className="cg-ai-text-suggestion" title='Use AI to enhance your content.' onClick={() => setAiTextSuggestion(listId)}>
                                        <div className="ai-text-animated-line">
                                <i className="fa-light fa-sparkles"></i>
                                <p>Generate text</p>
                            </div>
                            </div>
                                        {aiTextSuggestion === listId && (
                                            <AiTextPopup
                                                prompt={aboutCertificate}
                                                cancel={() => setAiTextSuggestion(false)}
                                                onTextChange={(newText) => handleTextChange(newText, listId)}
                                            />
                                        )}
                                    </div>

                                    <div className="certificate-del-add-button">
                                        <hr />
                                        <i
                                            className="fa-solid fa-trash"
                                            onClick={() => DeleteItem(index, listId)}
                                        ></i>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <a href="#" className="cg-web-form-fill-btn add-section" onClick={() => AddItem(index, objectData)}>
                        <i className="fa-regular fa-plus"></i> Add Section
                    </a>
                    <button className="cg-web-form-fill-btn" type="submit">
                        Save and {isLast ? "Next" : "Preview"}
                        <i className="fa-regular fa-arrow-right" />
                    </button>
                </form>
            </div>
            {isPreview}
        </>
    );
}

