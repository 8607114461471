import Blue_and_White_Clean_and_Professional_Resume from "./Blue White Clean and Professional Resume Template/Blue_and_White_Clean_and_Professional_Resume";
import Elegant_Resume_Template from "./Elegant Resume Template/Elegant_Resume_Template";
import BeigeTemplate from "./beige template/BeigeTemplate";

import HMATSTamplate from "./HMATSTamplate/HMATSTamplate";

import BlueModernTemplate from "./Blue Modern Template/BlueModernTemplate";

import { GrayThemeTemplate } from "./Gray Theme Template/GrayThemeTemplate";

import { FThemeTemplate } from "./F Theme Template/FThemeTemplate";

import Border_Chain_Resume from "./Border Chain Resume/Border_Chain_Resume";

import Entry_Level_Tech_Professional_Resume from "./Entry Level Tech Professional Resume/Entry_Level_Tech_Professional_Resume";

import { NuevaTemplate } from "./Nueva Template/NuevaTemplate";
import { SalesAchieverResume } from "./Sales Achiever Resume/SalesAchieverResume";
import Modern_Curriculum_Resume_Templates from "./Modern Curriculum Resume Template/Modern_Curriculum_Resume_Templates";
import Three_Side_Border_Resume from "./Three Side Border Resume/Three_Side_Border_Resume";

import Yellow_Theme__Template from "./Yellow Theme Template/Yellow_Theme__Template";
import Pattern_Style_Resume from "./Pattern Style Resume/Pattern_Style_Resume";
import Unique_Style_Resume from "./Unique Style Resume/Unique_Style_Resume";
import Box_Style_Resume from "./Box Style Resume/Box_Style_Resume";
import Ppvp_resume_temp from "./Ppvp Resume Template/Ppvp_resume_temp";

import Creative_Image_Resume_Template from "./Creative Image Resume Template/Creative_Image_Resume_Template";
import Simple_Professional_Template from "./Simple Professional Template/Simple_Professional_Template";
import Colorfull_Resume_Template from "./Colorfull Resume Template/Colorfull_Resume_Template";
import Blue_Creative_Modern_Resume from "./Blue Creative Modern Resume/Blue_Creative_Modern_Resume";
import ATS_Friendly_Resume from "./ATS Friendly Resume/ATS_Friendly_Resume";
import Applicant_Tracking_Resume_Template from "./Applicant Tracking Resume Template/Applicant_Tracking_Resume_Template";
import Professional_ATS_Resume from "./Professional ATS Resume/Professional_ATS_Resume";
import Career_Changer_Resume from "./Career Changer Resume/Career_Changer_Resume";
import After_Border_Resume_Template from "./After_Border_Resume_Template/After_Border_Resume_Template";
import White_Background_Resume_Template from "./White Background Resume Template/White_Background_Resume_Template";
import Professional_Web_Developer_Resume from "./Professional Web Developer Resume/Professional_Web_Developer_Resume";
import Hundred_Percent_Professional_Template from "./Hundred Percent Professional Template/Hundred_Percent_Professional_Template";
import Brown_Minimalist_Creative_Template from "./Brown Minimalist Creative Template/Brown_Minimalist_Creative_Template";
import Heading_Border_Resume_Template from "./Heading Border Resume Template/Heading_Border_Resume_Template";
import Blue_Header_Resume_Template from "./Blue Header Resume Template/Blue_Header_Resume_Template";
import Blue_ATS_Proof_Template from "./Blue ATS Proof Template/Blue_ATS_Proof_Template";
import Big_image_Typography_Template from "./Big image Typography Template/Big_image_Typography_Template";

export const TemplateData = [
  // 1) PROFESSIONAL WEB DEVELOPER RESUME TEMPLATE
  {
    id: "PROWEDERETE",
    templateName: "Professional Web Developer Resume Template",
    templateImage: "/template-images/Professional_Web_Developer_Resume.jpg",
    element: Professional_Web_Developer_Resume,
    headingTextFont: "Merriweather",
    bodyTextFont: "Inter",
    headingTextColor: "#000",
    themeColor: "#d9d9d9",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Data Analyst",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "PROWEDERETE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Detail-oriented Data Analyst with [X] years of experience in interpreting complex data sets and providing actionable insights that drive business decisions and improve efficiency.</p>",
      },
      {
        sectionId: "PROWEDERETE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "PROWEDERETE:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma@gmail.com",
            additionalLink: "",
          },
          {
            listId: "PROWEDERETE:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-9865896589",
            additionalLink: "",
          },
          {
            listId: "PROWEDERETE:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "PROWEDERETE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "PROWEDERETE:PROJECTS:1",
            projectName: "Sales Data Analysis",
            startDate: "Jun, 2021",
            endDate: "Aug, 2022",
            aboutProject:
              "<p>Conducted a comprehensive analysis of sales data to identify trends, resulting in actionable recommendations that increased sales by 20%.</p>",
          },
          {
            listId: "PROWEDERETE:PROJECTS:2",
            projectName: "Customer Segmentation Analysis",
            startDate: "Sep, 2020",
            endDate: "sep, 2021",
            aboutProject:
              "<p>Performed clustering analysis on customer data to identify distinct customer segments, enabling targeted marketing strategies that improved conversion rates.</p>",
          },
        ],
      },
      {
        sectionId: "PROWEDERETE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "PROWEDERETE:SKILLS:1",
            skillName: "Data Visualization (Tableau, Power BI)",
          },
          {
            listId: "PROWEDERETE:SKILLS:2",
            skillName: "SQL",
          },
          {
            listId: "PROWEDERETE:SKILLS:3",
            skillName: "Python (Pandas, NumPy)",
          },
          {
            listId: "PROWEDERETE:SKILLS:4",
            skillName: "Excel (Advanced)",
          },
          {
            listId: "PROWEDERETE:SKILLS:5",
            skillName: "Statistical Analysis",
          },
        ],
      },
      {
        sectionId: "PROWEDERETE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "PROWEDERETE:JOB-EXP:1",
            companyName: "Tech Solutions Pvt. Ltd.",
            jobRole: "Data Analyst",
            startDate: "Apr, 2019",
            endDate: "Jun, 2023",
            aboutJob:
              "<p>Analyzed large datasets to uncover trends and patterns, leading to actionable recommendations that improved operational efficiency by [X]% and enhanced decision-making processes.</p>",
          },
          {
            listId: "PROWEDERETE:JOB-EXP:2",
            companyName: "Data Insights Co.",
            jobRole: "Junior Data Analyst",
            startDate: "Sep, 2018",
            endDate: "Dec, 2019",
            aboutJob:
              "<p>Assisted in data collection and preparation, and performed preliminary analysis to support senior analysts in generating reports for client projects.</p>",
          },
        ],
      },
      {
        sectionId: "PROWEDERETE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "PROWEDERETE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Master in Computer Application",
            startDate: "Aug, 2017",
            endDate: "Sep, 2020",
            aboutEducation:
              "<p>Covers machine learning, predictive analytics, and big data technologies, providing hands-on experience with data analysis tools.</p>",
          },
          {
            listId: "PROWEDERETE:EDUCATION:2",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "Jan, 2017",
            endDate: "Feb, 2020",
            aboutEducation:
              "<p>Relevant coursework included database management systems, data mining, statistical analysis, and data visualization techniques.</p>",
          },
        ],
      },
      {
        sectionId: "PROWEDERETE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Language",
        list: [
          {
            listId: "PROWEDERETE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Intermediate"
          },
          {
            listId: "PROWEDERETE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert"
          },
          {
            listId: "PROWEDERETE:LANGUAGE:3",
            languageName: "Marathi",
            languageProfiency: "Expert"
          },
        ]
      },
      {
        sectionId: "PROWEDERETE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interest",
        list: [
          {
            listId: "PROWEDERETE:INTEREST:1",
            interest: "Gaming"
          },
          {
            listId: "PROWEDERETE:INTEREST:2",
            interest: "Movies"
          },
          {
            listId: "PROWEDERETE:INTEREST:3",
            interest: "Reading"
          },
          {
            listId: "PROWEDERETE:INTEREST:4",
            interest: "Travelling"
          },
        ]
      },
      {
        sectionId: "PROWEDERETE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certification",
        list: [
          {
            listId: "PROWEDERETE:CERTIFICATION:1",
            certificateName: "Data Analytics Professional Certificate",
            instituteName: "Coursera",
            startDate: "Jan, 2023",
            endDate: "Mar, 2023",
            aboutCertificate:
              "<p>Completed a comprehensive program focusing on data analysis techniques, data visualization, and statistical analysis using Python and SQL.</p>"
          },
          {
            listId: "PROWEDERETE:CERTIFICATION:2",
            certificateName: "Google Data Analytics Professional Certificate",
            instituteName: "Google",
            startDate: "June, 2023",
            endDate: "Dec, 2023",
            aboutCertificate:
              "<p>Learned foundational data analytics skills, including data cleaning, analysis, and visualization, with practical projects using real-world data.</p>"
          }
        ]
      }
    ]

  },

  // 2) HUNDRED PERCENT PROFESSIONAL TEMPLATE
  {
    id: "HUPERPRTE",
    templateImage: "/template-images/Hundred-Percent-Professional-Template.jpg",
    templateName: "Hundred Percent Professional Template",
    element: Hundred_Percent_Professional_Template,
    headingTextFont: "Ysabeau SC",
    bodyTextFont: "Mulish",
    headingTextColor: "#000",
    themeColor: "#DFD7D3",
    bodyTextColor: "#000",
    headingTextSize: 18,
    bodyTextSize: 11,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Business Analyst",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "HUPERPRTE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Results-driven Business Development Executive with [X] years of experience in driving revenue growth, building client relationships, and developing strategic partnerships. Proven track record of identifying market opportunities and achieving sales targets.</p>",
      },
      {
        sectionId: "HUPERPRTE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "HUPERPRTE:CONTACT:1",
            iconName: "fa-sharp fa-solid fa-envelope", 
            contactName: "snehasharma@gmail.com",
            additionalLink: "",
          },
          {
            listId: "HUPERPRTE:CONTACT:2",
            iconName: "fa-sharp fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "HUPERPRTE:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "HUPERPRTE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "HUPERPRTE:PROJECTS:1",
            projectName: "Market Expansion Strategy",
            startDate: "Aug, 2021",
            endDate: "Sep, 2022",
            aboutProject:
              "<p>Developed and executed a comprehensive market expansion strategy that resulted in a 30% increase in client acquisition in untapped regions.</p>",
          },
          {
            listId: "HUPERPRTE:PROJECTS:2",
            projectName: "Client Relationship Management Program",
            startDate: "Jan, 2020",
            endDate: "Aug, 2021",
            aboutProject:
              "<p>Implemented a client relationship management program that enhanced client retention by 25% through personalized follow-ups and feedback collection.</p>",
          },
        ],
      },
      {
        sectionId: "HUPERPRTE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "HUPERPRTE:SKILLS:1",
            skillName: "Sales Strategy Development",
          },
          {
            listId: "HUPERPRTE:SKILLS:2",
            skillName: "Client Relationship Management",
          },
          {
            listId: "HUPERPRTE:SKILLS:3",
            skillName: "Market Research and Analysis",
          },
          {
            listId: "HUPERPRTE:SKILLS:4",
            skillName: "Negotiation and Persuasion",
          },
          {
            listId: "HUPERPRTE:SKILLS:5",
            skillName: "Communication and Presentation",
          },
        ],
      },
      {
        sectionId: "HUPERPRTE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "HUPERPRTE:JOB-EXP:1",
            companyName: "Tech Solutions Pvt. Ltd.",
            jobRole: "Business Development Executive",
            startDate: "Oct, 2019",
            endDate: "Dec, 2023",
            aboutJob:
              "<p>Generated new business opportunities through networking and relationship building, resulting in a 40% increase in annual revenue.</p>",
          },
          {
            listId: "HUPERPRTE:JOB-EXP:2",
            companyName: "Innovate Corp.",
            jobRole: "Sales Executive",
            startDate: "Feb, 2018",
            endDate: "Aug, 2019",
            aboutJob:
              "<p>Assisted in developing sales strategies and conducting market research, contributing to a 15% growth in sales within the first year.</p>",
          },
        ],
      },
      {
        sectionId: "HUPERPRTE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "HUPERPRTE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Business Administration",
            startDate: "Jun, 2017",
            endDate: "Jul, 2020",
            aboutEducation:
              "<p>Relevant coursework included marketing principles, business strategy, consumer behavior, and sales management.</p>",
          },
          {
            listId: "HUPERPRTE:EDUCATION:2",
            collegeName: "Indian School of Business",
            course: "Executive Program in Business Management",
            startDate: "Apr, 2022",
            endDate: "Jul, 2023",
            aboutEducation:
              "<p>Focused on advanced business development strategies, leadership skills, and market analysis.</p>",
          },
        ],
      },
      {
        sectionId: "HUPERPRTE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Language",
        list: [
          {
            listId: "HUPERPRTE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "HUPERPRTE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "HUPERPRTE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interest",
        list: [
          {
            listId: "HUPERPRTE:INTEREST:1",
            interest: "Networking",
          },
          {
            listId: "HUPERPRTE:INTEREST:2",
            interest: "Traveling",
          },
          {
            listId: "HUPERPRTE:INTEREST:3",
            interest: "Reading Business Literature",
          },
          {
            listId: "HUPERPRTE:INTEREST:4",
            interest: "Attending Industry Conferences",
          },
        ],
      },
      {
        sectionId: "HUPERPRTE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certification",
        list: [
          {
            listId: "HUPERPRTE:CERTIFICATION:1",
            certificateName: "Certified Business Development Expert",
            instituteName: "International Business Management Institute",
            startDate: "Mar, 2023",
            endDate: "Dec, 2023",
            aboutCertificate:
              "<p>Gained comprehensive knowledge in business development strategies, client relationship management, and market analysis.</p>",
          },
          {
            listId: "HUPERPRTE:CERTIFICATION:2",
            certificateName: "Sales Management Certification",
            instituteName: "Coursera",
            startDate: "Mar, 2022",
            endDate: "Jul, 2022",
            aboutCertificate:
              "<p>Completed a professional certification focused on effective sales management techniques and strategies to enhance sales performance.</p>",
          },
        ],
      },
    ]

  },

  // 3) BROWN MINIMALIST CREATIVE TEMPLATE
  {
    id: "BRMINCRTE",
    templateImage: "/template-images/Brown_Minimalist_Creative_Template.jpg",
    templateName: "Brown Minimalist Creative Template",
    element: Brown_Minimalist_Creative_Template,
    headingTextFont: "volkhov",
    bodyTextFont: "inter",
    headingTextColor: "#000",
    themeColor: " #B29E84",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "creative",
    subCategory: "digital-marketing",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Digital Marketing",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BRMINCRTE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Results-oriented Digital Marketing Specialist with [X] years of experience in developing and executing digital marketing strategies. Proven track record in SEO, PPC, content marketing, and social media management to drive online engagement and revenue growth.</p>",
      },
      {
        sectionId: "BRMINCRTE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "BRMINCRTE:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma@gmail.com",
            additionalLink: "",
          },
          {
            listId: "BRMINCRTE:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "BRMINCRTE:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "BRMINCRTE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BRMINCRTE:PROJECTS:1",
            projectName: "SEO Optimization for E-commerce Site",
            startDate: "Jan, 2021",
            endDate: "Feb, 2022",
            aboutProject:
              "<p>Implemented an SEO strategy that increased organic traffic by 50% within six months, leading to a 25% boost in online sales.</p>",
          },
          {
            listId: "BRMINCRTE:PROJECTS:2",
            projectName: "Social Media Campaign for Product Launch",
            startDate: "Sep, 2022",
            endDate: "Jul, 2023",
            aboutProject:
              "<p>Developed and executed a social media campaign that reached over 200,000 users and generated a 15% increase in product awareness.</p>",
          },
        ],
      },
      {
        sectionId: "BRMINCRTE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BRMINCRTE:SKILLS:1",
            skillName: "Search Engine Optimization (SEO)",
          },
          {
            listId: "BRMINCRTE:SKILLS:2",
            skillName: "Pay-Per-Click Advertising (PPC)",
          },
          {
            listId: "BRMINCRTE:SKILLS:3",
            skillName: "Content Marketing",
          },
          {
            listId: "BRMINCRTE:SKILLS:4",
            skillName: "Social Media Management",
          },
          {
            listId: "BRMINCRTE:SKILLS:5",
            skillName: "Email Marketing",
          },
        ],
      },
      {
        sectionId: "BRMINCRTE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BRMINCRTE:JOB-EXP:1",
            companyName: "Digital Marketing Agency",
            jobRole: "Digital Marketing Specialist",
            startDate: "Aug, 2020",
            endDate: "Aug, 2023",
            aboutJob:
              "<p>Developed and managed digital marketing campaigns across various platforms, resulting in a 30% increase in client engagement and a 20% growth in lead generation.</p>",
          },
          {
            listId: "BRMINCRTE:JOB-EXP:2",
            companyName: "XYZ Corp.",
            jobRole: "Marketing Intern",
            startDate: "Jun, 2019",
            endDate: "Jul, 2020",
            aboutJob:
              "<p>Assisted in creating content for social media and email campaigns, helping to boost audience interaction by 25% during my tenure.</p>",
          },
        ],
      },
      {
        sectionId: "BRMINCRTE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BRMINCRTE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Business Administration in Marketing",
            startDate: "Mar, 2017",
            endDate: "Apr, 2020",
            aboutEducation:
              "<p>Relevant coursework included digital marketing, consumer behavior, marketing research, and branding.</p>",
          },
          {
            listId: "BRMINCRTE:EDUCATION:2",
            collegeName: "Indian School of Business",
            course: "Certificate in Digital Marketing",
            startDate: "Jun, 2021",
            endDate: "Jun, 2022",
            aboutEducation:
              "<p>Focused on digital marketing strategies, analytics, and campaign management.</p>",
          },
        ],
      },
      {
        sectionId: "BRMINCRTE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Language",
        list: [
          {
            listId: "BRMINCRTE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "BRMINCRTE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "BRMINCRTE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interest",
        list: [
          {
            listId: "BRMINCRTE:INTEREST:1",
            interest: "Digital Marketing Trends",
          },
          {
            listId: "BRMINCRTE:INTEREST:2",
            interest: "Content Creation",
          },
          {
            listId: "BRMINCRTE:INTEREST:3",
            interest: "Data Analytics",
          },
          {
            listId: "BRMINCRTE:INTEREST:4",
            interest: "Traveling",
          },
        ],
      },
      {
        sectionId: "BRMINCRTE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certification",
        list: [
          {
            listId: "BRMINCRTE:CERTIFICATION:1",
            certificateName: "Google Analytics Certification",
            instituteName: "Google",
            startDate: "Jun, 2022",
            endDate: "Aug, 2022",
            aboutCertificate:
              "<p>Gained proficiency in using Google Analytics to measure and analyze web traffic and performance.</p>",
          },
          {
            listId: "BRMINCRTE:CERTIFICATION:2",
            certificateName: "Facebook Blueprint Certification",
            instituteName: "Facebook",
            startDate: "Sep, 2021",
            endDate: "Dec, 2021",
            aboutCertificate:
              "<p>Learned best practices for advertising on Facebook and Instagram, including ad creation and targeting strategies.</p>",
          },
        ],
      },
    ]

  },

  // 4) HEADING BORDER RESUME TEMPLATE
  {
    id: "HEBORETEM",
    templateImage: "/template-images/Heading-Border-Resume-Template.jpg",
    templateName: "Heading Border Resume Template",
    element: Heading_Border_Resume_Template,
    headingTextFont: "PT Serif",
    bodyTextFont: "Inter",
    headingTextColor: "#000",
    themeColor: "#f6ebcf",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "minimal",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Web developer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "HEBORETEM:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary: "<p>Detail-oriented Web Developer with [X] years of experience in creating dynamic and responsive websites. Proficient in HTML, CSS, JavaScript, and frameworks like React and Angular. Passionate about building user-centric web applications and continuously improving skills through learning and collaboration.</p>"
      },
      {
        sectionId: "HEBORETEM:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "HEBORETEM:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: ""
          },
          {
            listId: "HEBORETEM:CONTACT:2",
            iconName: "fa-solid fa-phone-flip",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: ""
          },
          {
            listId: "HEBORETEM:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: ""
          }
        ]
      },
      {
        sectionId: "HEBORETEM:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "HEBORETEM:PROJECTS:1",
            projectName: "E-commerce Website Development",
            startDate: "Jan, 2022",
            endDate: "Mar, 2023",
            aboutProject: "<p>Developed a fully functional e-commerce website using React and Node.js, implementing features like product filtering, user authentication, and a shopping cart.</p>"
          },
          {
            listId: "HEBORETEM:PROJECTS:2",
            projectName: "Portfolio Website",
            startDate: "Mar, 2021",
            endDate: "Jun, 2022",
            aboutProject: "<p>Designed and developed a personal portfolio website to showcase projects and skills, utilizing HTML, CSS, and JavaScript for responsive design.</p>"
          }
        ]
      },
      {
        sectionId: "HEBORETEM:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "HEBORETEM:SKILLS:1",
            skillName: "HTML"
          },
          {
            listId: "HEBORETEM:SKILLS:2",
            skillName: "CSS"
          },
          {
            listId: "HEBORETEM:SKILLS:3",
            skillName: "JavaScript"
          },
          {
            listId: "HEBORETEM:SKILLS:4",
            skillName: "React"
          },
          {
            listId: "HEBORETEM:SKILLS:5",
            skillName: "Node.js"
          },
          {
            listId: "HEBORETEM:SKILLS:6",
            skillName: "Responsive Design"
          },
          {
            listId: "HEBORETEM:SKILLS:7",
            skillName: "Version Control (Git)"
          }
        ]
      },
      {
        sectionId: "HEBORETEM:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "HEBORETEM:JOB-EXP:1",
            companyName: "Web Solutions Pvt. Ltd.",
            jobRole: "Web Developer",
            startDate: "Jan, 2021",
            endDate: "Present",
            aboutJob: "<p>Developing and maintaining dynamic websites and web applications, collaborating with designers to create user-friendly interfaces, and ensuring optimal performance across devices.</p>"
          },
          {
            listId: "HEBORETEM:JOB-EXP:2",
            companyName: "Freelancer",
            jobRole: "Web Developer",
            startDate: "Jun, 2020",
            endDate: "May, 2021",
            aboutJob: "<p>Worked with various clients to design and develop custom websites, focusing on meeting specific needs and ensuring timely project delivery.</p>"
          }
        ]
      },
      {
        sectionId: "HEBORETEM:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "HEBORETEM:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "Jun, 2017",
            endDate: "Sep, 2020",
            aboutEducation: "<p>Relevant coursework included database management systems, software engineering principles, algorithms and data structures, and web development.</p>"
          },
          {
            listId: "HEBORETEM:EDUCATION:2",
            collegeName: "Mumbai University",
            course: "Master of Science in Information Technology",
            startDate: "Aug, 2021",
            endDate: "Oct, 2023",
            aboutEducation: "<p>Focused on advanced web technologies, UX design, and project management, with hands-on experience in developing web applications.</p>"
          },
        ]
      },
      {
        sectionId: "HEBORETEM:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "HEBORETEM:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "HEBORETEM:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Beginner"
          }
        ]
      },
      {
        sectionId: "HEBORETEM:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "HEBORETEM:INTEREST:1",
            interest: "Coding and Programming Challenges"
          },
          {
            listId: "HEBORETEM:INTEREST:2",
            interest: "Web Design Trends"
          },
          {
            listId: "HEBORETEM:INTEREST:3",
            interest: "Open Source Contribution"
          },
          {
            listId: "HEBORETEM:INTEREST:4",
            interest: "Tech Meetups and Networking"
          },
          {
            listId: "HEBORETEM:INTEREST:5",
            interest: "Reading Tech Blogs and Tutorials"
          }
        ]
      },
      {
        sectionId: "HEBORETEM:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "HEBORETEM:CERTIFICATION",
            certificateName: "Web Development Bootcamp",
            instituteName: "Tech Academy",
            startDate: "Jan, 2024",
            endDate: "June, 2024",
            aboutCertificate: "<p>Completed a comprehensive web development bootcamp focusing on full-stack development using HTML, CSS, JavaScript, and modern frameworks.</p>"
          }
        ]
      }

    ]
  },

  // 5) BLUE HEADER RESUME TEMPLATE
  {
    id: "BLHERETEM",
    templateName: "Blue Header Resume Template",
    templateImage: "/template-images/Blue-Header-Resume-Template.jpg",
    element: Blue_Header_Resume_Template,
    headingTextFont: "Roboto Slab",
    bodyTextFont: "Rubik",
    headingTextColor: "#000",
    themeColor: "#E4F2FD",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "minimal",
    subCategory: "web-developer",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Web Developer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BLHERETEM:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary: "<p>Results-driven Web Developer with over [X] years of experience in creating dynamic and interactive web applications. Proficient in modern front-end technologies and frameworks including React, Vue.js, and Angular, along with back-end development using Node.js and Express. Committed to delivering high-quality code and an exceptional user experience.</p>"
      },
      {
        sectionId: "BLHERETEM:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "BLHERETEM:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: ""
          },
          {
            listId: "BLHERETEM:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: ""
          },
          {
            listId: "BLHERETEM:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "456 Elm Street, Maharashtra, India",
            additionalLink: ""
          }
        ]
      },
      {
        sectionId: "BLHERETEM:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BLHERETEM:PROJECTS:1",
            projectName: "Blog Management System",
            startDate: "Jan, 2023",
            endDate: "Mar, 2024",
            aboutProject: "<p>Developed a comprehensive blog management system using Vue.js for the front end and Node.js for the back end, featuring user authentication, CRUD operations, and SEO optimization.</p>"
          }
          // ,
          // {
          //   listId: "BLHERETEM:PROJECTS:2",
          //   projectName: "Portfolio Website",
          //   startDate: "Dec, 2022",
          //   endDate: "Feb, 2023",
          //   aboutProject: "<p>Created a responsive portfolio website using HTML, CSS, and Bootstrap to showcase my web development projects and skills, emphasizing mobile-first design principles.</p>"
          // }
        ]
      },
      {
        sectionId: "BLHERETEM:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BLHERETEM:SKILLS:1",
            skillName: "HTML5"
          },
          {
            listId: "BLHERETEM:SKILLS:2",
            skillName: "CSS3"
          },
          {
            listId: "BLHERETEM:SKILLS:3",
            skillName: "JavaScript"
          },
          {
            listId: "BLHERETEM:SKILLS:4",
            skillName: "React"
          },
          {
            listId: "BLHERETEM:SKILLS:5",
            skillName: "Node.js"
          },
          {
            listId: "BLHERETEM:SKILLS:6",
            skillName: "RESTful APIs"
          },
          {
            listId: "BLHERETEM:SKILLS:7",
            skillName: "Git & GitHub"
          },
          {
            listId: "BLHERETEM:SKILLS:8",
            skillName: "Agile Methodologies"
          }
        ]
      },
      {
        sectionId: "BLHERETEM:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BLHERETEM:JOB-EXP:1",
            companyName: "Web Innovations Inc.",
            jobRole: "Full Stack Developer",
            startDate: " Jan, 2021",
            endDate: "Present",
            aboutJob: "<p>Designing and developing scalable web applications, implementing new features, and optimizing application performance. Collaborating with designers to improve usability and accessibility.</p>"
          },
          {
            listId: "BLHERETEM:JOB-EXP:2",
            companyName: "Creative Solutions Co.",
            jobRole: "Junior Web Developer",
            startDate: "Jul, 2020",
            endDate: "Feb, 2021",
            aboutJob: "<p>Assisted in the development of client websites, focusing on responsive design and user experience. Collaborated with the team on code reviews and debugging.</p>"
          }
        ]
      },
      {
        sectionId: "BLHERETEM:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BLHERETEM:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "Aug, 2017",
            endDate: "Oct, 2020",
            aboutEducation: "<p>Studied core subjects in computer science including web technologies, software engineering, and database systems. Completed projects in front-end and back-end development.</p>"
          },
          {
            listId: "BLHERETEM:EDUCATION:2",
            collegeName: "Abdul kalam Technical University ",
            course: "Master of Business Adminstration",
            startDate: "Mar, 2021",
            endDate: " Apr, 2022",
            aboutEducation: "<p>Gained hands-on experience in HTML, CSS, JavaScript, and frameworks like React and Node.js through project-based learning and collaborative coding.</p>"
          }
        ]
      },
      {
        sectionId: "BLHERETEM:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "BLHERETEM:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "BLHERETEM:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert"
          }
        ]
      },
      {
        sectionId: "BLHERETEM:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "BLHERETEM:INTEREST:1",
            interest: "Building Personal Projects"
          },
          {
            listId: "BLHERETEM:INTEREST:2",
            interest: "Exploring New Technologies"
          },
          {
            listId: "BLHERETEM:INTEREST:3",
            interest: "Participating in Hackathons"
          },
          {
            listId: "BLHERETEM:INTEREST:4",
            interest: "Web Accessibility Advocacy"
          }
        ]
      },
      {
        sectionId: "BLHERETEM:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "BLHERETEM:CERTIFICATION",
            certificateName: "Certified JavaScript Developer",
            instituteName: "Tech Academy",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate: "<p>Completed certification focused on JavaScript programming, covering both front-end and back-end development concepts and best practices.</p>"
          }
        ]
      }
    ]
  },

  // 6) BLUE ATS PROOF TEMPLATE
  {
    id: "BLATSPRTE",
    templateImage: "/template-images/blue-ats-proof-template.jpg",
    templateName: "Blue ATS Proof Template",
    element: Blue_ATS_Proof_Template,
    headingTextFont: "Roboto",
    bodyTextFont: "Rubik",
    headingTextColor: "#000",
    themeColor: "#E4F2FD",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "ats-friendly",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Software Developer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BLATSPRTE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Results-driven Software Developer with [X] years of experience in designing, developing, and deploying scalable applications. Proficient in multiple programming languages and frameworks, with a strong focus on delivering high-quality software solutions.</p>",
      },
      {
        sectionId: "BLATSPRTE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "BLATSPRTE:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma@gmail.com",
            additionalLink: "",
          },
          {
            listId: "BLATSPRTE:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "BLATSPRTE:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "BLATSPRTE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BLATSPRTE:PROJECTS:1",
            projectName: "E-Commerce Web Application",
            startDate: "Jun, 2021",
            endDate: "Aug, 2021",
            aboutProject:
              "<p>Developed a fully functional e-commerce web application using React and Node.js, enabling users to browse products, manage their cart, and complete purchases.</p>",
          },
          {
            listId: "BLATSPRTE:PROJECTS:2",
            projectName: "Social Media Dashboard",
            startDate: "Sep, 2021",
            endDate: "Nov, 2021",
            aboutProject:
              "<p>Created a social media dashboard using Angular and Firebase for real-time data synchronization, allowing users to track and manage multiple social media accounts.</p>",
          },
        ],
      },
      {
        sectionId: "BLATSPRTE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BLATSPRTE:SKILLS:1",
            skillName: "JavaScript",
          },
          {
            listId: "BLATSPRTE:SKILLS:2",
            skillName: "React",
          },
          {
            listId: "BLATSPRTE:SKILLS:3",
            skillName: "Node.js",
          },
          {
            listId: "BLATSPRTE:SKILLS:4",
            skillName: "SQL",
          },
          {
            listId: "BLATSPRTE:SKILLS:5",
            skillName: "RESTful APIs",
          },
          {
            listId: "BLATSPRTE:SKILLS:6",
            skillName: "Git",
          },
        ],
      },
      {
        sectionId: "BLATSPRTE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BLATSPRTE:JOB-EXP:1",
            companyName: "Innovative Tech Solutions",
            jobRole: "Software Developer",
            startDate: "Mar, 2021",
            endDate: "Present",
            aboutJob:
              "<p>Developing and maintaining web applications using JavaScript frameworks, collaborating with cross-functional teams to enhance functionality and user experience.</p>",
          },
          {
            listId: "BLATSPRTE:JOB-EXP:2",
            companyName: "Code Crafters Inc.",
            jobRole: "Junior Software Developer",
            startDate: "Jan, 2020",
            endDate: "Feb, 2021",
            aboutJob:
              "<p>Assisted in the development of web-based applications, wrote reusable code, and conducted debugging and troubleshooting for various projects.</p>",
          },
        ],
      },
      {
        sectionId: "BLATSPRTE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BLATSPRTE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "Aug, 2017",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Completed coursework in software engineering, database systems, algorithms, and web development, gaining a solid foundation in programming principles.</p>",
          },
        ],
      },
      {
        sectionId: "BLATSPRTE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "BLATSPRTE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "BLATSPRTE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "intermediate",
          },
        ],
      },
      {
        sectionId: "BLATSPRTE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "BLATSPRTE:INTEREST:1",
            interest: "Contributing to Open Source Projects",
          },
          {
            listId: "BLATSPRTE:INTEREST:2",
            interest: "Exploring New Technologies",
          },
          {
            listId: "BLATSPRTE:INTEREST:3",
            interest: "Learning New Programming Languages and Frameworks",
          },
          {
            listId: "BLATSPRTE:INTEREST:4",
            interest: "Participating in Coding Challenges and Competitions",
          },
        ],
      },
      {
        sectionId: "BLATSPRTE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "BLATSPRTE:CERTIFICATION:1",
            certificateName: "Certified JavaScript Developer",
            instituteName: "Tech Academy",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate: "<p>Completed certification focusing on JavaScript programming, including front-end and back-end development concepts and best practices.</p>",
          },
          {
            listId: "BLATSPRTE:CERTIFICATION:2",
            certificateName: "Full Stack Web Development",
            instituteName: "Coding Bootcamp",
            startDate: "Sep, 2022",
            endDate: "Dec, 2022",
            aboutCertificate: "<p>Gained proficiency in HTML, CSS, JavaScript, and server-side programming with Node.js and Express.</p>",
          },
        ],
      },
    ],
  },

  // 7) WHITE BACKGROUND RESUME TEMPLATE
  {
    id: "WHBARETE",
    templateImage: "/template-images/White-Background-Resume-Template.jpg",
    templateName: "White Background Resume Template",
    element: White_Background_Resume_Template,
    headingTextFont: "Space Grotesk",
    bodyTextFont: "Inter",
    headingTextColor: "#000",
    themeColor: "#B0B0B0",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Content Writer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "WHBARETE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Creative and detail-oriented Content Writer with [X] years of experience in crafting engaging content for diverse audiences. Skilled in researching, editing, and producing high-quality articles, blogs, and marketing materials.</p>",
      },
      {
        sectionId: "WHBARETE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "WHBARETE:CONTACT:1",
            iconName: "fa-regular fa-envelope",
            contactName: "snehasharma@gmail.com",
            additionalLink: "",
          },
          {
            listId: "WHBARETE:CONTACT:2",
            iconName: "fa-regular fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "WHBARETE:CONTACT:3",
            iconName: "fa-regular fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "WHBARETE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "WHBARETE:PROJECTS:1",
            projectName: "Blog Series on Digital Marketing Trends",
            startDate: "Sep, 2022",
            endDate: "Feb, 2023",
            aboutProject:
              "<p>Researched and wrote a comprehensive blog series analyzing the latest digital marketing trends, resulting in a 30% increase in website traffic.</p>",
          },
          {
            listId: "WHBARETE:PROJECTS:2",
            projectName: "Content Strategy for Start-up",
            startDate: "Jun, 2022",
            endDate: "Feb, 2023",
            aboutProject:
              "<p>Developed a content strategy for a tech start-up, creating engaging articles, newsletters, and social media posts that enhanced brand visibility and customer engagement.</p>",
          },
        ],
      },
      {
        sectionId: "WHBARETE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "WHBARETE:SKILLS:1",
            skillName: "Content Writing",
          },
          {
            listId: "WHBARETE:SKILLS:2",
            skillName: "SEO Optimization",
          },
          {
            listId: "WHBARETE:SKILLS:3",
            skillName: "Editing and Proofreading",
          },
          {
            listId: "WHBARETE:SKILLS:4",
            skillName: "Research Skills",
          },
          {
            listId: "WHBARETE:SKILLS:5",
            skillName: "Social Media Management",
          },
        ],
      },
      {
        sectionId: "WHBARETE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "WHBARETE:JOB-EXP:1",
            companyName: "Creative Content Agency",
            jobRole: "Content Writer",
            startDate: "Jan, 2021",
            endDate: "Present",
            aboutJob:
              "<p>Developing engaging content for blogs, articles, and social media, while collaborating with marketing teams to enhance brand messaging and customer outreach.</p>",
          },
          {
            listId: "WHBARETE:JOB-EXP:2",
            companyName: "Freelance Writer",
            jobRole: "Freelance Content Writer",
            startDate: "Jun, 2019",
            endDate: "Dec, 2020",
            aboutJob:
              "<p>Produced high-quality written content for various clients, focusing on SEO best practices and audience engagement strategies.</p>",
          },
        ],
      },
      {
        sectionId: "WHBARETE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "WHBARETE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Arts in English Literature",
            startDate: "Aug, 2017",
            endDate: "Mar, 2020",
            aboutEducation:
              "<p>Studied various forms of literature and writing, enhancing skills in critical thinking, research, and analytical writing.</p>",
          },
          {
            listId: "WHBARETE:EDUCATION:2",
            collegeName: "University of Mumbai",
            course: "Diploma in Creative Writing",
            startDate: "Jul, 2021",
            endDate: "Dec, 2021",
            aboutEducation:
              "<p>Completed a diploma focused on various creative writing forms, including fiction, poetry, and scriptwriting, while learning editing and storytelling techniques.</p>",
          }
        ],
      },
      {
        sectionId: "WHBARETE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "WHBARETE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "intermediate",
          },
          {
            listId: "WHBARETE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Beginner",
          },
          {
            listId: "WHBARETE:LANGUAGE:3",
            languageName: "Bengali",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "WHBARETE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "WHBARETE:INTEREST:1",
            interest: "Writing Fiction and Poetry",
          },
          {
            listId: "BLHERETEM:INTEREST:5",
            interest: "Exploring Digital Storytelling Techniques",
          },
        ],
      },
      {
        sectionId: "WHBARETE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "WHBARETE:CERTIFICATION:1",
            certificateName: "Content Marketing Certification",
            instituteName: "HubSpot Academy",
            startDate: "Mar, 2023",
            endDate: "Apr, 2023",
            aboutCertificate: "<p>Completed a certification focused on content marketing strategies, SEO best practices, and audience engagement techniques.</p>",
          },
          {
            listId: "WHBARETE:CERTIFICATION:2",
            certificateName: "Copywriting Masterclass",
            instituteName: "Udemy",
            startDate: "Jan, 2023",
            endDate: "Feb, 2023",
            aboutCertificate: "<p>Gained insights into persuasive writing techniques and effective copy for various digital platforms.</p>",
          },
        ],
      }
    ],

  },

  // 8) BIG IMAGE TYPOGRAPHY TEMPLATE
  {
    id: "BIIMTYTE",
    templateName: "Big Image Typography Template",
    templateImage: "/template-images/big-image-typography-template.jpg",
    element: Big_image_Typography_Template,
    headingTextFont: "Josefin Sans",
    bodyTextFont: "Roboto",
    headingTextColor: "#000",
    themeColor: "#404040",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 11,
    isPersonaldetailschange: false,
    category: "creative",
    userName: "SNEHA SHARMA",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Digital Marketing",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BIIMTYTE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Results-driven Digital Marketing Specialist with [X] years of experience in developing and executing data-driven marketing strategies to enhance brand visibility and drive customer engagement.</p>",
      },
      {
        sectionId: "BIIMTYTE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "BIIMTYTE:CONTACT:1",
            iconName: "fa-regular fa-envelope",
            contactName: "snehasharma@gmail.com",
            additionalLink: "",
          },
          {
            listId: "BIIMTYTE:CONTACT:2",
            iconName: "fa-regular fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "BIIMTYTE:CONTACT:3",
            iconName: "fa-regular fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "BIIMTYTE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BIIMTYTE:PROJECTS:1",
            projectName: "SEO Optimization for E-commerce Website",
            startDate: "Jun, 2022",
            endDate: "Nov, 2022",
            aboutProject:
              "<p>Conducted comprehensive keyword research and implemented on-page and off-page SEO strategies that resulted in a 30% increase in organic traffic.</p>",
          },
        ],
      },
      {
        sectionId: "BIIMTYTE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BIIMTYTE:SKILLS:1",
            skillName: "Search Engine Optimization (SEO)",
          },
          {
            listId: "BIIMTYTE:SKILLS:2",
            skillName: "Content Marketing",
          },
          {
            listId: "BIIMTYTE:SKILLS:3",
            skillName: "Social Media Marketing",
          },
          {
            listId: "BIIMTYTE:SKILLS:4",
            skillName: "Google Analytics",
          },
          {
            listId: "BIIMTYTE:SKILLS:5",
            skillName: "Email Marketing",
          },
        ],
      },
      {
        sectionId: "BIIMTYTE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BIIMTYTE:JOB-EXP:1",
            companyName: "Digital Marketing Agency",
            jobRole: "Digital Marketing Specialist",
            startDate: "Jan, 2021",
            endDate: "Present",
            aboutJob:
              "<p>Develop and implement digital marketing campaigns across various channels, resulting in a 25% increase in customer acquisition and brand awareness.</p>",
          },
        ],
      },
      {
        sectionId: "BIIMTYTE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BIIMTYTE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Business Administration in Marketing",
            startDate: "Aug, 2017",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Studied marketing principles, consumer behavior, and digital marketing strategies, gaining a strong foundation in marketing.</p>",
          },
        ],
      },
      {
        sectionId: "BIIMTYTE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "BIIMTYTE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Intermediate",
          },
          {
            listId: "BIIMTYTE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert",
          },
          {
            listId: "BIIMTYTE:LANGUAGE:3",
            languageName: "Spanish",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "BIIMTYTE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "BIIMTYTE:INTEREST:1",
            interest: "Blogging about Digital Marketing Trends",
          },
          {
            listId: "BIIMTYTE:INTEREST:2",
            interest: "Analyzing Social Media Strategies",
          },
          {
            listId: "BIIMTYTE:INTEREST:3",
            interest: "Networking with Industry Professionals",
          },
          {
            listId: "BIIMTYTE:INTEREST:4",
            interest: "Participating in Digital Marketing Workshops",
          },
        ],
      },
      {
        sectionId: "BIIMTYTE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "BIIMTYTE:CERTIFICATION:1",
            certificateName: "Google Analytics Certification",
            instituteName: "Google",
            startDate: "Mar, 2023",
            endDate: "Apr, 2023",
            aboutCertificate:
              "<p>Completed certification covering Google Analytics for digital marketing, focusing on data analysis and reporting.</p>",
          },
          {
            listId: "BIIMTYTE:CERTIFICATION:2",
            certificateName: "Facebook Blueprint Certification",
            instituteName: "Facebook",
            startDate: "May, 2023",
            endDate: "Jun, 2023",
            aboutCertificate:
              "<p>Achieved certification in Facebook advertising, learning to create effective ad campaigns and measure performance.</p>",
          },
        ],
      },
    ]
  },

  // 9) AFTER BORDER RESUME TEMPLATE
  {
    id: "AFBORETE",
    templateImage: "/template-images/after-border-resume-template.jpg",
    templateName: "After Border Resume Template",
    element: After_Border_Resume_Template,
    headingTextFont: "Roboto",
    bodyTextFont: "Inter",
    headingTextColor: "#2D5B7A",
    themeColor: "#E5E5E5",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "ats-friendly",
    subCategory: "content-writer",
    userName: "Sneha Sharma",
    profileImage: "",
    userJobRole: "Content Writer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "AFBORETE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Creative and detail-oriented Content Writer with [X] years of experience in producing engaging, SEO-optimized content. Skilled in content strategy, audience engagement, and collaboration, with a passion for delivering high-quality, results-driven work.</p>",
      },
      {
        sectionId: "AFBORETE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "AFBORETE:CONTACT:1",
            iconName: "fa-regular fa-envelope",
            contactName: "sneha.sharma@gmail.com",
            additionalLink: "",
          },
          {
            listId: "AFBORETE:CONTACT:2",
            iconName: "fa-regular fa-phone-flip",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "AFBORETE:CONTACT:3",
            iconName: "fa-regular fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "AFBORETE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "AFBORETE:PROJECTS:1",
            projectName: "Blog Series on Digital Marketing Trends",
            startDate: "Mar, 2023",
            endDate: "Jun, 2023",
            aboutProject:
              "<p>Researched and wrote a series of blogs on the latest trends in digital marketing, including SEO, social media algorithms, and content marketing strategies. The blog series led to a 40% increase in website traffic and reader engagement. In addition, it boosted the company’s social media presence, with posts related to the series generating 500+ shares on LinkedIn and Twitter.</p>",
          },
        ],
      },
      {
        sectionId: "AFBORETE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "AFBORETE:SKILLS:1",
            skillName: "Content Writing",
          },
          {
            listId: "AFBORETE:SKILLS:2",
            skillName: "SEO Optimization",
          },
          {
            listId: "AFBORETE:SKILLS:3",
            skillName: "Research and Analysis",
          },
          {
            listId: "AFBORETE:SKILLS:4",
            skillName: "Social Media Management",
          },
          {
            listId: "AFBORETE:SKILLS:5",
            skillName: "Copywriting",
          },
        ],
      },
      {
        sectionId: "AFBORETE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "AFBORETE:JOB-EXP:1",
            companyName: "Content Creators Agency",
            jobRole: "Content Writer",
            startDate: "Jan, 2021",
            endDate: "Present",
            aboutJob:
              "<p>Developed high-quality content for websites, blogs, and social media platforms, leading to a 30% increase in audience engagement and brand awareness. Collaborated with marketing teams to create content aligned with the brand’s voice, resulting in a 25% increase in conversion rates. Utilized SEO strategies to optimize articles and blog posts, enhancing search engine rankings by 15%. Spearheaded content creation for email newsletters, product descriptions, and promotional campaigns, contributing to a 40% increase in customer retention.</p>",
          },
        ],
      },
      {
        sectionId: "AFBORETE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "AFBORETE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Arts in English Literature",
            startDate: "Aug, 2017",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Studied a diverse range of English literature, from classical to modern texts, with a focus on narrative techniques, critical analysis, and creative writing. Developed a deep understanding of literature’s impact on culture and communication, which has enhanced my content writing skills. Actively participated in literary discussions, writing workshops, and student publications, honing both creative and analytical writing abilities.</p>",
          },
        ],
      },

      {
        sectionId: "AFBORETE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "AFBORETE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "AFBORETE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "AFBORETE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "AFBORETE:INTEREST:1",
            interest: "Writing Short Stories and Poetry",
          },
          {
            listId: "AFBORETE:INTEREST:2",
            interest: "Exploring New Writing Styles",
          },
          {
            listId: "AFBORETE:INTEREST:3",
            interest: "Participating in Writing Workshops",
          },
          {
            listId: "AFBORETE:INTEREST:4",
            interest: "Reading Literature and Non-Fiction",
          },
        ],
      },
      {
        sectionId: "BLHERETEM:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "AFBORETE:CERTIFICATION:1",
            certificateName: "Content Marketing Certification",
            instituteName: "HubSpot Academy",
            startDate: "Feb, 2023",
            endDate: "Mar, 2023",
            aboutCertificate:
              "<p>Completed a comprehensive course on content marketing strategies, focusing on content creation, audience engagement, and brand storytelling. Learned to design content campaigns that not only attract traffic but also convert leads into loyal customers. The course included practical case studies, and I applied these strategies to increase engagement with blog content and social media.</p>",
          },
        ],
      },
    ]
  },

  // 10) CAREER CHANGER RESUME
  {
    id: "CARCHARES",
    templateImage: "/template-images/Career-Changer-Resume.jpg",
    templateName: "Career Changer Resume",
    element: Career_Changer_Resume,
    headingTextFont: "Roboto",
    bodyTextFont: "Inter",
    headingTextColor: "#000",
    themeColor: "#F2BD1E",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "ats-friendly",
    userName: "Sneha Sharma",
    profileImage: "",
    userJobRole: "Software Developer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "CARCHARES:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Results-driven Software Developer with [X] years of experience in designing, developing, and maintaining software applications. Proficient in multiple programming languages and frameworks, with a strong focus on delivering high-quality code.</p>",
      },
      {
        sectionId: "CARCHARES:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "CARCHARES:CONTACT:1",
            iconName: "fa-regular fa-at",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "CARCHARES:CONTACT:2",
            iconName: "fa-regular fa-phone-flip",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "CARCHARES:CONTACT:3",
            iconName: "fa-regular fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "CARCHARES:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "CARCHARES:PROJECTS:1",
            projectName: "E-Commerce Website Development",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutProject:
              "<p>Developed a full-stack e-commerce application using React and Node.js, implementing user authentication, product management, and payment integration, resulting in a user-friendly shopping experience.</p>",
          },
          {
            listId: "CARCHARES:PROJECTS:2",
            projectName: "Mobile App for Fitness Tracking",
            startDate: "Jul, 2022",
            endDate: "Dec, 2022",
            aboutProject:
              "<p>Designed and developed a mobile application using Flutter for fitness tracking, enabling users to log workouts and track progress, achieving over 1,000 downloads in the first month.</p>",
          },
        ],
      },
      {
        sectionId: "CARCHARES:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "CARCHARES:SKILLS:1",
            skillName: "Java",
          },
          {
            listId: "CARCHARES:SKILLS:2",
            skillName: "Python",
          },
          {
            listId: "CARCHARES:SKILLS:3",
            skillName: "JavaScript",
          },
          {
            listId: "CARCHARES:SKILLS:4",
            skillName: "React",
          },
          {
            listId: "CARCHARES:SKILLS:5",
            skillName: "Node.js",
          },
          {
            listId: "CARCHARES:SKILLS:6",
            skillName: "Spring Boot",
          },
        ],
      },
      {
        sectionId: "CARCHARES:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "CARCHARES:JOB-EXP:1",
            companyName: "Tech Solutions Pvt. Ltd.",
            jobRole: "Software Developer",
            startDate: "Jan, 2021",
            endDate: "Present",
            aboutJob:
              "<p>Developed and maintained software applications using Java and Spring Boot, contributing to a 25% increase in application performance and ensuring timely delivery of projects.</p>",
          },
          {
            listId: "CARCHARES:JOB-EXP:2",
            companyName: "Innovative Software Co.",
            jobRole: "Junior Software Developer",
            startDate: "Jun, 2019",
            endDate: "Dec, 2020",
            aboutJob:
              "<p>Collaborated with a team to create responsive web applications using JavaScript and React, enhancing user experience and functionality based on client feedback.</p>",
          },
        ],
      },
      {
        sectionId: "CARCHARES:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "CARCHARES:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "Aug, 2017",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Relevant coursework included software engineering, database management, algorithms, and web development, providing a strong foundation in computer science principles.</p>",
          },
        ],
      },
      {
        sectionId: "CARCHARES:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "CARCHARES:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "CARCHARES:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Intermediate",
          },
        ],
      },
      {
        sectionId: "CARCHARES:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "CARCHARES:INTEREST:1",
            interest: "Contributing to Open Source Projects",
          },
          {
            listId: "CARCHARES:INTEREST:2",
            interest: "Building Personal Software Applications",
          },
          {
            listId: "CARCHARES:INTEREST:3",
            interest: "Participating in Coding Competitions",
          },
          {
            listId: "CARCHARES:INTEREST:4",
            interest: "Exploring Emerging Technologies",
          },
        ],
      },
      {
        sectionId: "CARCHARES:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "CARCHARES:CERTIFICATION:1",
            certificateName: "Certified Java Developer",
            instituteName: "Oracle Academy",
            startDate: "Jan, 2023",
            endDate: "Mar, 2023",
            aboutCertificate:
              "<p>Completed a comprehensive certification program focused on Java programming, covering core concepts and advanced techniques.</p>",
          },
          {
            listId: "CARCHARES:CERTIFICATION:2",
            certificateName: "Full Stack Web Development Certification",
            instituteName: "Coursera",
            startDate: "Apr, 2023",
            endDate: "Jun, 2023",
            aboutCertificate:
              "<p>Earned certification in full stack web development, emphasizing front-end and back-end technologies including JavaScript, Node.js, and React.</p>",
          },
        ],
      },
    ]

  },

  // 11) PROFESSIONAL ATS RESUME
  {
    id: "PROATRE",
    templateImage: "/template-images/professional-ats-resume.jpg",
    templateName: "Professional ATS Resume",
    element: Professional_ATS_Resume,
    headingTextFont: "Merriweather",
    bodyTextFont: "Inter",
    headingTextColor: "#757500",
    themeColor: "#F2BD1E",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Graphic Designer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "PROATRE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Creative and detail-oriented Graphic Designer with [X] years of experience in creating visually appealing designs for digital and print media. Proficient in Adobe Creative Suite, with a strong ability to collaborate with clients and deliver compelling visual solutions.</p>"
      },
      {
        sectionId: "PROATRE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "PROATRE:CONTACT:1",
            iconName: "fa-regular fa-envelope",
            contactName: "snehasharma@gmail.com",
            additionalLink: ""
          },
          {
            listId: "PROATRE:CONTACT:2",
            iconName: "fa-regular fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: ""
          },
          {
            listId: "PROATRE:CONTACT:3",
            iconName: "fa-regular fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: ""
          }
        ]
      },
      {
        sectionId: "PROATRE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "PROATRE:PROJECTS:1",
            projectName: "Brand Identity Design for XYZ Company",
            startDate: "Jan, 2023",
            endDate: "Mar, 2023",
            aboutProject:
              "<p>Developed a comprehensive brand identity for XYZ Company, including logo design, color palette, and typography, resulting in a cohesive brand image and increased brand recognition.</p>"
          },
          {
            listId: "PROATRE:PROJECTS:2",
            projectName: "Social Media Campaign Graphics",
            startDate: "Apr, 2023",
            endDate: "Jun, 2023",
            aboutProject:
              "<p>Created visually engaging graphics for social media campaigns across various platforms, leading to a 30% increase in engagement and follower growth.</p>"
          }
        ]
      },
      {
        sectionId: "PROATRE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          { listId: "PROATRE:SKILLS:1", skillName: "Adobe Photoshop" },
          { listId: "PROATRE:SKILLS:2", skillName: "Adobe Illustrator" },
          { listId: "PROATRE:SKILLS:3", skillName: "Adobe InDesign" },
          { listId: "PROATRE:SKILLS:4", skillName: "UI/UX Design" }
        ]
      },
      {
        sectionId: "PROATRE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "PROATRE:JOB-EXP:1",
            companyName: "Creative Studio Ltd.",
            jobRole: "Graphic Designer",
            startDate: "Jan, 2022",
            endDate: "Apr, 2022",
            aboutJob:
              "<p>Designed marketing materials, including brochures, flyers, and advertisements, enhancing brand visibility and attracting new customers.</p>"
          },
          {
            listId: "PROATRE:JOB-EXP:2",
            companyName: "Digital Media Agency",
            jobRole: "Junior Graphic Designer",
            startDate: "May, 2018",
            endDate: "May, 2019",
            aboutJob:
              "<p>Assisted senior designers in creating visual content for clients, contributing to successful campaigns and client satisfaction.</p>"
          }
        ]
      },
      {
        sectionId: "PROATRE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "PROATRE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Fine Arts in Graphic Design",
            startDate: "Apr, 2014",
            endDate: "Jul, 2018",
            aboutEducation:
              "<p>Relevant coursework included visual communication, typography, color theory, and digital design.</p>"
          },
          {
            listId: "PROATRE:EDUCATION:2",
            collegeName: "Mumbai University",
            course: "Master of Fine Arts in Graphic Design",
            startDate: "May, 2018",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Relevant coursework included visual communication, typography, color theory, and digital design.</p>"
          }
        ]
      },
      {
        sectionId: "PROATRE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          { listId: "PROATRE:LANGUAGE:1", languageName: "Hindi", languageProfiency: "Expert" },
          { listId: "PROATRE:LANGUAGE:2", languageName: "English", languageProfiency: "Expert" }
        ]
      },
      {
        sectionId: "PROATRE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          { listId: "PROATRE:INTEREST:1", interest: "Digital Illustration" },
          { listId: "PROATRE:INTEREST:2", interest: "Photography" },
          { listId: "PROATRE:INTEREST:3", interest: "Attending Design Workshops" },
          { listId: "PROATRE:INTEREST:4", interest: "Exploring Typography" }
        ]
      },
      {
        sectionId: "PROATRE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "PROATRE:CERTIFICATION:1",
            certificateName: "Adobe Certified Expert",
            instituteName: "Adobe Academy",
            startDate: "Jul, 2022",
            endDate: "Dec, 2022",
            aboutCertificate:
              "<p>Completed certification focusing on advanced techniques in Adobe Photoshop and Illustrator, enhancing my design skills and workflow efficiency.</p>"
          },
          {
            listId: "PROATRE:CERTIFICATION:2",
            certificateName: "UI/UX Design Certification",
            instituteName: "Design Institute",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate:
              "<p>Learned the principles of user-centered design, conducting user research, and creating wireframes and prototypes.</p>"
          }
        ],
      },
    ],
  },

  // 12) APPLICANT TRACKING RESUME TEMPLATE
  {
    id: "APTRRETE",
    templateImage: "/template-images/applicant-tracking-resume-template.jpg",
    templateName: "Applicant Tracking Resume Template",
    element: Applicant_Tracking_Resume_Template,
    headingTextFont: "Roboto",
    bodyTextFont: "Inter",
    headingTextColor: "#333",
    themeColor: "#f1f1f1",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "ats-friendly",
    subCategory: "seo-executive",
    userName: "Sneha Sharma",
    profileImage: "",
    userJobRole: "SEO Executive",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },


    AllSections: [
      {
        sectionId: "APTRRETE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary: "<p>Results-driven SEO Executive with over [X] years of experience in enhancing website visibility and driving organic traffic through innovative SEO strategies, data-driven decision making, and continuous optimization efforts that yield measurable results.</p>"
      },
      {
        sectionId: "APTRRETE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "APTRRETE:CONTACT:1",
            iconName: "fa-regular fa-envelope",
            contactName: "snehasharma@gmail.com",
            additionalLink: ""
          },
          {
            listId: "APTRRETE:CONTACT:2",
            iconName: "fa-regular fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: ""
          },
          {
            listId: "APTRRETE:CONTACT:3",
            iconName: "fa-regular fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: ""
          }
        ]
      },
      {
        sectionId: "APTRRETE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "APTRRETE:PROJECTS:1",
            projectName: "E-commerce SEO Optimization",
            startDate: "Jan, 2022",
            endDate: "Dec, 2022",
            aboutProject: "<p>Implemented comprehensive SEO strategies that increased organic search traffic by over 50% for an e-commerce platform, leading to a significant boost in sales. Focused on optimizing product pages, content, and improving technical SEO aspects of the website.</p>"
          }
        ]
      },
      {
        sectionId: "APTRRETE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "APTRRETE:SKILLS:1",
            skillName: "SEO Strategy Development"
          },
          {
            listId: "APTRRETE:SKILLS:2",
            skillName: "Keyword Research"
          },
          {
            listId: "APTRRETE:SKILLS:3",
            skillName: "Content Optimization"
          },
          {
            listId: "APTRRETE:SKILLS:4",
            skillName: "Google Analytics"
          },
          {
            listId: "APTRRETE:SKILLS:5",
            skillName: "On-page and Off-page SEO"
          }
        ]
      },
      {
        sectionId: "APTRRETE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "APTRRETE:JOB-EXP:1",
            companyName: "Digital Marketing Agency",
            jobRole: "SEO Executive",
            startDate: "Mar, 2021",
            endDate: "Present",
            aboutJob: "<p>Conducted thorough keyword research and analysis to develop targeted content strategies, leading to an increase in search rankings for multiple clients. Collaborated with content teams to implement optimization techniques and tracked performance through analytics tools.</p>"
          },
          {
            listId: "APTRRETE:JOB-EXP:2",
            companyName: "Web Solutions Co.",
            jobRole: "SEO Specialist",
            startDate: "Jan, 2019",
            endDate: "Feb, 2021",
            aboutJob: "<p>Optimized website content and structure, resulting in a 40% increase in organic traffic and improved site engagement metrics through data analysis and reporting. Focused on improving page speed and mobile optimization, which contributed to better search rankings.</p>"
          }
        ]
      },
      {
        sectionId: "APTRRETE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "APTRRETE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "Aug, 2016",
            endDate: "May, 2020",
            aboutEducation: "<p>Specialized in digital marketing strategies and analytics, with coursework in web development, data structures, and marketing principles. Gained hands-on experience in building digital campaigns and analyzing performance data to improve marketing efforts.</p>"
          }
        ]
      },
      {
        sectionId: "APTRRETE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "APTRRETE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "APTRRETE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert"
          }
        ]
      },
      {
        sectionId: "APTRRETE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "APTRRETE:INTEREST:1",
            interest: "Search Engine Trends"
          },
          {
            listId: "APTRRETE:INTEREST:2",
            interest: "Content Marketing"
          },
          {
            listId: "APTRRETE:INTEREST:3",
            interest: "Analyzing SEO Tools"
          },
          {
            listId: "APTRRETE:INTEREST:4",
            interest: "Digital Marketing Innovations"
          }
        ]
      },
      {
        sectionId: "APTRRETE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "APTRRETE:CERTIFICATION:1",
            certificateName: "Google Analytics Certification",
            instituteName: "Google",
            startDate: "Jan, 2023",
            endDate: "Mar, 2023",
            aboutCertificate: "<p>Gained comprehensive knowledge in Google Analytics for tracking and reporting website performance metrics. Learned advanced features such as custom reporting, goals tracking, and audience segmentation.</p>"
          }
        ]
      }

    ]
  },

  // 13) ATS FRIENDLY RESUME
  {
    id: "ATFRME",
    templateImage: "/template-images/ATS-Friendly-Resume.jpg",
    templateName: "ATS Friendly Resume",
    element: ATS_Friendly_Resume,
    headingTextFont: "Space Grotesk",
    bodyTextFont: "Inter",
    headingTextColor: "#333",
    themeColor: "#3765e2",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "ats-friendly",
    userName: "Sneha Sharma",
    profileImage: "",
    userJobRole: "Software Developer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },
    AllSections: [
      {
        sectionId: "ATFRME:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary: "<p>Results-driven Software Developer with 3 years of experience in designing, developing, and implementing robust applications and solutions. Expertise in modern programming languages, frameworks, and agile development methodologies, with a proven ability to deliver scalable and efficient software solutions that meet business objectives.</p>"
      },
      {
        sectionId: "ATFRME:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "ATFRME:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma@email.com",
            additionalLink: ""
          },
          {
            listId: "ATFRME:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: ""
          },
          {
            listId: "ATFRME:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: ""
          }
        ]
      },
      {
        sectionId: "ATFRME:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "ATFRME:PROJECTS:1",
            projectName: "E-Commerce Web Application",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutProject: "<p>Developed a dynamic full-stack e-commerce web application using React and Node.js, implementing features such as real-time inventory management, user authentication, and payment gateway integration. Enhanced the user experience through intuitive design and optimized performance, achieving a 25% reduction in page load times.</p>"
          }
        ]
      },
      {
        sectionId: "ATFRME:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          { listId: "ATFRME:SKILLS:1", skillName: "Java" },
          { listId: "ATFRME:SKILLS:2", skillName: "Python" },
          { listId: "ATFRME:SKILLS:3", skillName: "JavaScript" },
          { listId: "ATFRME:SKILLS:4", skillName: "React" },
          { listId: "ATFRME:SKILLS:5", skillName: "Node.js" },
          { listId: "ATFRME:SKILLS:6", skillName: "SQL" },
          { listId: "ATFRME:SKILLS:7", skillName: "Git" }
        ]
      },
      {
        sectionId: "ATFRME:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "ATFRME:JOB-EXP:1",
            companyName: "Innovative Tech Solutions",
            jobRole: "Software Developer",
            startDate: "Jul, 2021",
            endDate: "Present",
            aboutJob: "<p>Collaborated with cross-functional teams to develop and deploy high-performance software solutions. Spearheaded optimization projects that enhanced application performance by 30% and improved server response times. Provided technical mentorship to junior developers, resulting in higher team efficiency and code quality.</p>"
          },
          {
            listId: "ATFRME:JOB-EXP:2",
            companyName: "NextGen IT Systems",
            jobRole: "Junior Full-Stack Developer",
            startDate: "Jun, 2020",
            endDate: "Jun, 2021",
            aboutJob: "<p>Played a key role in developing responsive web applications using React and Node.js. Improved website usability by implementing UI enhancements, reducing bounce rates by 15%. Contributed to back-end API integrations and collaborated closely with designers to ensure seamless user experiences.</p>"
          }
        ]
      },
      {
        "sectionId": "ATFRME:EDUCATION",
        "isSection": true,
        "isSectionDetailsChanges": false,
        "sectionName": "Education",
        "list": [
          {
            listId: "ATFRME:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "Aug, 2017",
            endDate: "May, 2020",
            aboutEducation: "<p>Specialized in software engineering and data structures, completing practical projects that emphasized the development of scalable and efficient algorithms. Graduated with distinction.</p>"
          }
        ]
      },
      {
        sectionId: "ATFRME:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          { listId: "ATFRME:LANGUAGE:1", languageName: "Hindi", languageProfiency: "Expert" },
          { listId: "ATFRME:LANGUAGE:2", languageName: "English", languageProfiency: "Expert" }
        ]
      },
      {
        sectionId: "ATFRME:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          { listId: "ATFRME:INTEREST:1", interest: "Developing Open Source Projects" },
          { listId: "ATFRME:INTEREST:2", interest: "Learning New Frameworks" },
          { listId: "ATFRME:INTEREST:3", interest: "Contributing to Tech Blogs" },
          { listId: "ATFRME:INTEREST:4", interest: "Engaging in Tech Meetups" }
        ]
      },
      {
        sectionId: "ATFRME:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "ATFRME:CERTIFICATION:1",
            certificateName: "Certified Java Developer",
            instituteName: "Tech Academy",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate: "<p>Earned certification with a curriculum emphasizing advanced Java programming, including design patterns, multithreading, and integration of RESTful services. Gained hands-on experience through real-world projects.</p>"
          }
        ]
      }

    ]
  },

  // 14) BLUE CREATIVE MODERN TEMPLATE
  {
    id: "BLCRMOTE",
    templateImage: "/template-images/blue-creative-modern-resume.jpg",
    templateName: "Blue Creative Modern Template ",
    element: Blue_Creative_Modern_Resume,
    headingTextFont: "outfit",
    bodyTextFont: "Inter",
    headingTextColor: "#333",
    themeColor: "#03989f",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "creative",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Business Development Executive",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BLCRMOTE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Dynamic Business Development Executive with [X] years of experience in driving revenue growth and building strategic partnerships. Skilled in identifying untapped market opportunities, crafting innovative sales strategies, and fostering long-term client relationships. Adept at leveraging analytical insights to inform decision-making and ensure consistent achievement of organizational goals.</p>",
      },
      {
        sectionId: "BLCRMOTE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "BLCRMOTE:CONTACT:1",
            iconName: "fa-regular fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "BLCRMOTE:CONTACT:2",
            iconName: "fa-regular fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "BLCRMOTE:CONTACT:3",
            iconName: "fa-regular fa-location-dot",
            contactName: "123 Main Street, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "BLCRMOTE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BLCRMOTE:PROJECTS:1",
            projectName: "Market Penetration Strategy",
            startDate: "Jan, 2022",
            endDate: "Dec, 2022",
            aboutProject:
              "<p>Developed and executed a market penetration strategy that resulted in a 30% increase in sales over one year. This involved identifying emerging market segments, designing targeted campaigns, and leveraging strategic partnerships to enhance brand visibility and customer acquisition.</p>",
          },
          {
            listId: "BLCRMOTE:PROJECTS:2",
            projectName: "Customer Feedback Initiative",
            startDate: "Mar, 2023",
            endDate: "Aug, 2023",
            aboutProject:
              "<p>Implemented a comprehensive customer feedback initiative to improve client satisfaction metrics by 40%. The project entailed designing survey tools, analyzing feedback patterns, and collaborating with departments to implement client-centric enhancements.</p>",
          },
        ],
      },
      {
        sectionId: "BLCRMOTE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BLCRMOTE:SKILLS:1",
            skillName: "Negotiation",
          },
          {
            listId: "BLCRMOTE:SKILLS:2",
            skillName: "Sales Strategy",
          },
          {
            listId: "BLCRMOTE:SKILLS:3",
            skillName: "Client Relationship Management",
          },
          {
            listId: "BLCRMOTE:SKILLS:4",
            skillName: "Market Research",
          },
        ],
      },
      {
        sectionId: "BLCRMOTE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BLCRMOTE:JOB-EXP:1",
            companyName: "Innovative Solutions Pvt. Ltd.",
            jobRole: "Business Development Executive",
            startDate: "Mar, 2021",
            endDate: "Present",
            aboutJob:
              "<p>Developed new business opportunities through networking, market research, and strategic outreach, achieving a 25% growth in client base over two years. Additionally, collaborated with cross-functional teams to enhance service delivery and ensure client satisfaction.</p>",
          },
          {
            listId: "BLCRMOTE:JOB-EXP:2",
            companyName: "Creative Ventures Inc.",
            jobRole: "Sales Associate",
            startDate: "Jun, 2019",
            endDate: "Feb, 2021",
            aboutJob:
              "<p>Managed client relationships, executed sales initiatives, and exceeded quarterly revenue targets by 20%. Played a pivotal role in launching a new product line, contributing significantly to market share expansion.</p>",
          },
        ],
      },
      {
        sectionId: "BLCRMOTE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BLCRMOTE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Business Administration",
            startDate: "Aug, 2016",
            endDate: "May, 2019",
            aboutEducation:
              "<p>Relevant coursework included marketing management, consumer behavior, and business strategy, equipping me with essential skills in analytical thinking, decision-making, and effective communication.</p>",
          },
          {
            listId: "BLCRMOTE:EDUCATION:2",
            collegeName: "Indian Institute of Management",
            course: "Executive Program in Business Management",
            startDate: "Jan, 2023",
            endDate: "Dec, 2023",
            aboutEducation:
              "<p>This program provided advanced knowledge in strategic decision-making, marketing analytics, and leadership, enabling me to navigate complex business environments effectively.</p>",
          },
        ],
      },
      {
        sectionId: "BLCRMOTE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "BLCRMOTE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "BLCRMOTE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "BLCRMOTE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "BLCRMOTE:INTEREST:1",
            interest: "Networking Events",
          },
          {
            listId: "BLCRMOTE:INTEREST:2",
            interest: "Sales Strategy Development",
          },
          {
            listId: "BLCRMOTE:INTEREST:3",
            interest: "Industry Trends Analysis",
          },
          {
            listId: "BLCRMOTE:INTEREST:4",
            interest: "Entrepreneurship Initiatives",
          },
        ],
      },
      {
        sectionId: "BLCRMOTE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "BLCRMOTE:CERTIFICATION:1",
            certificateName: "Certified Sales Professional",
            instituteName: "Sales Academy",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate:
              "<p>Completed a certification focused on advanced sales techniques, client management, and strategic negotiation skills. This program enhanced my ability to close high-value deals and maintain strong client partnerships.</p>",
          },
          {
            listId: "BLCRMOTE:CERTIFICATION:2",
            certificateName: "Digital Marketing Certification",
            instituteName: "Digital Academy",
            startDate: "Jul, 2023",
            endDate: "Oct, 2023",
            aboutCertificate:
              "<p>Covered topics such as digital marketing strategies, SEO, and social media marketing. This certification strengthened my capability to effectively promote products and services in competitive digital landscapes.</p>",
          },
        ],
      },
    ],
  },

  // 15) COLORFULL RESUME TEMPLATE
  {
    id: "CORETE",
    templateImage: "/template-images/colorfull-resume-template.jpg",
    templateName: "Colorfull Resume Template",
    element: Colorfull_Resume_Template,
    headingTextFont: "poppins",
    bodyTextFont: "Montserrat",
    headingTextColor: "#333",
    themeColor: "#608ABF",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "creative",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Graphic Designer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "CORETE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Creative Graphic Designer with [X] years of experience in creating visually compelling designs across digital and print media. Adept at translating client requirements into innovative design solutions.</p>",
      },
      {
        sectionId: "CORETE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "CORETE:CONTACT:1",
            iconName: "fa-regular fa-at",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "CORETE:CONTACT:2",
            iconName: "fa-sharp fa-regular fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "CORETE:CONTACT:3",
            iconName: "fa-regular fa-location-dot",
            contactName: "123 Main Street, Mumbai, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "CORETE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "CORETE:PROJECTS:1",
            projectName: "Brand Identity Redesign",
            startDate: "Mar, 2022",
            endDate: "Aug, 2022",
            aboutProject:
              "<p>Led the redesign of a brand identity for a local startup, creating a cohesive visual style that increased brand recognition and customer engagement by 50%.</p>",
          },
          {
            listId: "CORETE:PROJECTS:2",
            projectName: "E-commerce Website Graphics",
            startDate: "Sep, 2023",
            endDate: "Nov, 2023",
            aboutProject:
              "<p>Developed visually appealing graphics and promotional materials for an e-commerce platform, enhancing user experience and contributing to a 30% increase in online sales.</p>",
          },
        ],
      },
      {
        sectionId: "CORETE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "CORETE:SKILLS:1",
            skillName: "Adobe Photoshop",
          },
          {
            listId: "CORETE:SKILLS:3",
            skillName: "UI/UX Design",
          },
          {
            listId: "CORETE:SKILLS:4",
            skillName: "Web Design",
          },
        ],
      },
      {
        sectionId: "CORETE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "CORETE:JOB-EXP:1",
            companyName: "Creative Designs Studio",
            jobRole: "Graphic Designer",
            startDate: "Jan, 2021",
            endDate: "Present",
            aboutJob:
              "<p>Created visually striking graphics for various projects, collaborating closely with clients to ensure their vision was realized while adhering to brand guidelines.</p>",
          },
          {
            listId: "CORETE:JOB-EXP:2",
            companyName: "Artistry Agency",
            jobRole: "Junior Graphic Designer",
            startDate: "Jun, 2019",
            endDate: "Dec, 2020",
            aboutJob:
              "<p>Assisted in the development of marketing materials and digital content, honing skills in design software and gaining experience in project management.</p>",
          },
        ],
      },
      {
        sectionId: "CORETE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "CORETE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Fine Arts in Graphic Design",
            startDate: "Aug, 2015",
            endDate: "May, 2019",
            aboutEducation:
              "<p>Focused on design theory, typography, and digital media production, preparing me for a successful career in graphic design.</p>",
          },
          {
            listId: "CORETE:EDUCATION:2",
            collegeName: "National Institute of Design",
            course: "Diploma in Graphic Design",
            startDate: "Jun, 2019",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Completed a diploma program focusing on design fundamentals, visual communication, and typography, which strengthened my foundational skills in graphic design.</p>",
          }
        ],
      },
      {
        sectionId: "CORETE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "CORETE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "CORETE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Beginner",
          },
        ],
      },
      {
        sectionId: "CORETE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "CORETE:INTEREST:1",
            interest: "Digital Art Creation",
          },
          {
            listId: "CORETE:INTEREST:2",
            interest: "Photography",
          },
          {
            listId: "CORETE:INTEREST:3",
            interest: "Graphic Design Trends",
          },
          {
            listId: "CORETE:INTEREST:4",
            interest: "Volunteer Design Work",
          },
        ],
      },
      {
        sectionId: "CORETE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "CORETE:CERTIFICATION:1",
            certificateName: "Adobe Certified Expert",
            instituteName: "Adobe Academy",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate: "<p>Achieved certification demonstrating advanced proficiency in Adobe Creative Suite, focusing on graphic design and digital media.</p>",
          },
          {
            listId: "CORETE:CERTIFICATION:2",
            certificateName: "UI/UX Design Certification",
            instituteName: "Design Institute",
            startDate: "Jul, 2023",
            endDate: "Oct, 2023",
            aboutCertificate: "<p>Completed a course in UI/UX design principles, enhancing my ability to create user-centered designs for digital products.</p>",
          },
        ],
      }
    ],
  },

  // 16) SIMPLE PROFESSIONAL TEMPLATE
  {
    id: "SIPROTE",
    templateImage: "/template-images/Simple-professional-template.jpg",
    templateName: "Simple Professional Template",
    element: Simple_Professional_Template,
    headingTextFont: "Roboto Slab",
    bodyTextFont: "mulish",
    headingTextColor: "#333",
    themeColor: "#608ABF",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "SEO Executive",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "SIPROTE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary: "<p>Results-driven SEO Executive with [X] years of experience in optimizing website content to improve search engine rankings and enhance user engagement. Expertise in developing and executing effective SEO strategies, using data-driven insights to drive traffic, increase conversions, and improve user experience.</p>"
      },
      {
        sectionId: "SIPROTE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "SIPROTE:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma@gmail.com",
            additionalLink: ""
          },
          {
            listId: "SIPROTE:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: ""
          },
          {
            listId: "SIPROTE:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Mumbai, Maharashtra, India",
            additionalLink: ""
          }
        ]
      },
      {
        sectionId: "SIPROTE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "SIPROTE:PROJECTS:1",
            projectName: "SEO Strategy Development",
            startDate: "Jan 2021",
            endDate: "June 2021",
            aboutProject: "<p>Developed and executed comprehensive SEO strategies that improved organic search traffic by [X]% within six months. Collaborated with content teams to optimize web pages, improve technical SEO, and increase conversion rates by targeting high-value keywords.</p>"
          },
          {
            listId: "SIPROTE:PROJECTS:2",
            projectName: "Website Audit and Optimization",
            startDate: "July, 2021",
            endDate: "Dec, 2021",
            aboutProject: "<p>Conducted a thorough website audit, identifying technical SEO issues, and implemented solutions that enhanced site performance and increased search rankings. Optimized page load speed, fixed broken links, and improved mobile responsiveness, leading to a [X]% improvement in user experience.</p>"
          }
        ]
      },
      {
        sectionId: "SIPROTE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "SIPROTE:SKILLS:1",
            skillName: "SEO Optimization"
          },
          {
            listId: "SIPROTE:SKILLS:2",
            skillName: "Google Analytics"
          },
          {
            listId: "SIPROTE:SKILLS:3",
            skillName: "Content Marketing"
          },
          {
            listId: "SIPROTE:SKILLS:4",
            skillName: "Keyword Research"
          },
          {
            listId: "SIPROTE:SKILLS:5",
            skillName: "HTML & CSS"
          }
        ]
      },
      {
        sectionId: "SIPROTE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "SIPROTE:JOB-EXP:1",
            companyName: "Digital Marketing Agency",
            jobRole: "SEO Executive",
            startDate: "March, 2021",
            endDate: "Present",
            aboutJob: "<p>Managed SEO campaigns for various clients, resulting in a consistent increase in organic search visibility and website traffic. Optimized content, developed link-building strategies, and analyzed data to improve SEO performance across multiple client websites.</p>"
          },
        ]
      },
      {
        sectionId: "SIPROTE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "SIPROTE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Arts in Mass Media",
            startDate: "Aug, 2015",
            endDate: "June, 2018",
            aboutEducation: "<p>Focused on digital marketing and media strategies, with coursework in content creation and SEO principles. Gained knowledge in web development, marketing analytics, and social media management, providing a strong foundation for a career in SEO and digital marketing.</p>"
          },
          {
            listId: "SIPROTE:EDUCATION:2",
            collegeName: "Online Marketing Institute",
            course: "Diploma in Search Engine Optimization",
            startDate: "Sep, 2018",
            endDate: "Dec, 2018",
            aboutEducation: "<p>Completed a diploma program specializing in SEO techniques, analytics, and website optimization strategies. Gained hands-on experience in SEO tools like Google Analytics, SEMrush, and Moz, applying best practices to improve search rankings and drive website traffic.</p>"
          },
        ]
      },
      {
        sectionId: "SIPROTE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "SIPROTE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "SIPROTE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert"
          },
        ]
      },
      {
        sectionId: "SIPROTE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "SIPROTE:INTEREST:1",
            interest: "SEO Trends and Strategies"
          },
          {
            listId: "SIPROTE:INTEREST:2",
            interest: "Content Creation"
          },
          {
            listId: "SIPROTE:INTEREST:3",
            interest: "Digital Marketing Innovations"
          },
          {
            listId: "SIPROTE:INTEREST:4",
            interest: "Participating in Webinars"
          }
        ]
      },
      {
        sectionId: "BLHERETEM:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "SIPROTE:CERTIFICATION:1",
            certificateName: "Google Analytics Certification",
            instituteName: "Google",
            startDate: "Feb, 2023",
            endDate: "Apr, 2023",
            aboutCertificate: "<p>Completed certification focused on Google Analytics, emphasizing data-driven decision-making for online marketing. Learned to analyze user behavior, measure traffic sources, and optimize campaigns for better performance.</p>"
          },
          {
            listId: "SIPROTE:CERTIFICATION:2",
            certificateName: "SEO Fundamentals Certification",
            instituteName: "SEMrush Academy",
            startDate: "May, 2023",
            endDate: "Jul, 2023",
            aboutCertificate: "<p>Earned certification in SEO fundamentals, covering essential practices, tools, and techniques to enhance online visibility. Focused on link-building, on-page optimization, and keyword strategy to improve search rankings.</p>"
          }
        ]
      },

    ],


  },

  // 17) CREATIVE IMAGE TEMPLATE
  {
    id: "CRTIMGTEST",
    templateImage: "/template-images/Creative-Image-Resume-Template.jpg",
    templateName: "Creative Image Template",
    element: Creative_Image_Resume_Template,
    headingTextFont: "laila",
    bodyTextFont: "Nunito",
    headingTextColor: "#333",
    themeColor: "#d18c5c",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "creative",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Graphic Designer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "CRTIMGTEST:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary: "<p>Creative Graphic Designer with [X] years of experience in creating compelling visual narratives and engaging designs that enhance brand identity.</p>"
      },
      {
        sectionId: "CRTIMGTEST:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "CRTIMGTEST:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "designersneha@gmail.com",
            additionalLink: ""
          },
          {
            listId: "CRTIMGTEST:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: ""
          },
          {
            listId: "CRTIMGTEST:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "789 Design Avenue, Mumbai, Maharashtra, India",
            additionalLink: ""
          }
        ]
      },
      {
        sectionId: "CRTIMGTEST:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "CRTIMGTEST:PROJECTS:1",
            projectName: "Brand Identity Design",
            startDate: "Mar, 2022",
            endDate: "Aug, 2022",
            aboutProject: "<p>Developed a comprehensive brand identity for a startup, resulting in a cohesive visual language that enhanced customer recognition, leading to a 30% increase in brand recall and market presence.</p>"
          },
          {
            listId: "CRTIMGTEST:PROJECTS:2",
            projectName: "E-commerce Website Redesign",
            startDate: "Sep, 2022",
            endDate: "Dec, 2022",
            aboutProject: "<p>Led the redesign of an e-commerce website, improving user experience, implementing responsive design, and increasing sales conversion rates by [X]%. Conducted user testing and collaborated with the development team to ensure seamless functionality and aesthetics.</p>"
          }
        ]
      },
      {
        sectionId: "CRTIMGTEST:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "CRTIMGTEST:SKILLS:1",
            skillName: "Adobe Creative Suite"
          },
          {
            listId: "CRTIMGTEST:SKILLS:2",
            skillName: "UI/UX Design"
          },
          {
            listId: "CRTIMGTEST:SKILLS:3",
            skillName: "Illustration"
          },
          {
            listId: "CRTIMGTEST:SKILLS:4",
            skillName: "Typography"
          },
          {
            listId: "CRTIMGTEST:SKILLS:5",
            skillName: "Web Design"
          }
        ]
      },
      {
        sectionId: "CRTIMGTEST:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "CRTIMGTEST:JOB-EXP:1",
            companyName: "Creative Agency Pvt. Ltd.",
            jobRole: "Graphic Designer",
            startDate: "Jan, 2021",
            endDate: "Present",
            aboutJob: "<p>Designed innovative graphics for digital and print media, collaborating with marketing teams to align visual strategies with brand goals. Managed multiple projects simultaneously, creating high-quality visual content that adhered to brand guidelines and client expectations. Enhanced digital campaigns through graphics that improved audience engagement and retention.</p>"
          },
          {
            listId: "CRTIMGTEST:JOB-EXP:2",
            companyName: "Design Studio",
            jobRole: "Junior Graphic Designer",
            startDate: "Jun, 2019",
            endDate: "Dec, 2020",
            aboutJob: "<p>Assisted in creating branding materials and promotional content, contributing to successful campaigns that boosted client visibility. Worked closely with senior designers to refine creative concepts and learned to adapt design styles based on diverse client needs. Developed skills in layout design, typography, and Adobe suite tools to produce high-quality visuals.</p>"
          }
        ]
      },
      {
        sectionId: "CRTIMGTEST: EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "CRTIMGTEST: EDUCATION: 1",
            collegeName: "Mumbai School of Design",
            course: "Bachelor of Fine Arts in Graphic Design",
            startDate: "Aug, 2016",
            endDate: "Jun, 2019",
            aboutEducation: "<p>Focused on visual communication, design theory, and digital media production, preparing for a career in graphic design. Gained comprehensive knowledge in creating digital art, photography, and advertising techniques. Participated in numerous workshops that fostered creativity and professional development.</p>"
          },
          {
            listId: "CRTIMGTEST: EDUCATION: 2",
            collegeName: "Online Design Institute",
            course: "Certificate in Visual Design",
            startDate: "Jul, 2019",
            endDate: "Dec, 2019",
            aboutEducation: "<p>Completed coursework in advanced design techniques, branding, and user experience, enhancing practical skills in graphic design. Focused on creating visual identities and designing with accessibility in mind. Worked on real-world design problems and collaborated with peers in creative projects.</p>"
          }
        ]
      },
      {
        sectionId: "CRTIMGTEST: LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "CRTIMGTEST: LANGUAGE: 1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "CRTIMGTEST: LANGUAGE: 2",
            languageName: "English",
            languageProfiency: "Intermediate"
          }
        ]
      },
      {
        sectionId: "CRTIMGTEST: INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "CRTIMGTEST: INTEREST: 1",
            interest: "Exploring Typography"
          },
          {
            listId: "CRTIMGTEST: INTEREST: 2",
            interest: "Digital Illustration"
          },
          {
            listId: "CRTIMGTEST: INTEREST: 3",
            interest: "Attending Design Conferences"
          },
          {
            listId: "CRTIMGTEST: INTEREST: 4",
            interest: "Photography"
          }
        ]
      },
      {
        sectionId: "CRTIMGTEST: CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "CRTIMGTEST: CERTIFICATION: 1",
            certificateName: "Adobe Certified Expert",
            instituteName: "Adobe",
            startDate: "Feb, 2023",
            endDate: "May, 2023",
            aboutCertificate: "<p>Achieved certification demonstrating advanced proficiency in Adobe Creative Suite tools and design principles. Gained expertise in digital illustration, photo editing, and vector graphics. This certification has allowed me to stay up to date with the latest trends in graphic design and enhanced my ability to deliver high-quality visual content.</p>"
          }
        ]
      }

    ]
  },

  // 18) PPVP RESUME TEMPLATE
  {
    id: "ppvp",
    templateImage: "/template-images/ppvp-resume-temp.jpg",
    templateName: "Ppvp_resume_temp",
    element: Ppvp_resume_temp,
    headingTextFont: "kanit",
    bodyTextFont: "inter",
    headingTextColor: "#fff",
    themeColor: "#63769B",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "creative",
    userName: "Sneha Sharma",
    userJobRole: "Digital Marketing",
    profileImage: "/images/User_Default_Image.png",
    resumeStyle: {
      backgroundPattern: false,
    },
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },

    AllSections: [

      {
        sectionId: "ppvp:summary",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary: "<p>Dynamic Digital Marketing Specialist with 5 years of experience in developing data-driven marketing strategies that enhance brand visibility and drive engagement.</p>",
      },
      {
        sectionId: "ppvp:contact",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Contact Me",
        list: [
          {
            listId: "ppvp:contact:1",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "ppvp:contact:2",
            iconName: "fa-solid fa-location",
            contactName: "Greater Noida, India",
            additionalLink: "",
          },
          {
            listId: "ppvp:contact:3",
            iconName: "fa-brands fa-github",
            contactName: "hello@reallygreatsite.com",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "ppvp:project",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "ppvp:project:1",
            projectName: "Social Media Campaign for Brand X",
            startDate: "Mar, 2021",
            endDate: "Jun, 2021",
            aboutProject: "<p>Designed and executed a comprehensive social media campaign for Brand X that significantly increased brand awareness and engagement. Leveraged analytics tools to track campaign performance, identifying areas for optimization that led to a 30% increase in reach and a 50% boost in user interaction within three months.</p>",
          },
        ],
      },
      {
        sectionId: "ppvp:skills",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "ppvp:skills:1",
            skillName: "SEO Optimization"
          },
          {
            listId: "ppvp:skills:2",
            skillName: "Content Marketing"
          },
          {
            listId: "ppvp:skills:3",
            skillName: "Google Analytics"
          },
          {
            listId: "ppvp:skills:4",
            skillName: "Social Media Management"
          },
          {
            listId: "ppvp:skills:5",
            skillName: "Email Marketing"
          },
          {
            listId: "ppvp:skills:6",
            skillName: "PPC Advertising"
          },
        ],
      },
      {
        sectionId: "ppvp:exp",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Experience",
        list: [
          {
            listId: "ppvp:exp:1",
            companyName: "Digital Solutions Inc.",
            jobRole: "Digital Marketing Specialist",
            startDate: "Jun, 2019",
            endDate: "Present",
            aboutJob: "<p>Developed and managed comprehensive digital marketing strategies that significantly enhanced online visibility, optimized lead generation, and contributed to a 20% increase in sales revenue. Responsible for creating targeted campaigns across various platforms, including social media, Google Ads, and email marketing. Collaborated with cross-functional teams to ensure consistent brand messaging and maximize marketing ROI.</p>",
          },
        ],
      },
      {
        sectionId: "ppvp:educ",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "ppvp:educ:1",
            collegeName: "MJPRU Bareilly, Uttar Pradesh",
            course: "Bachelor of Business Administration (BBA)",
            startDate: "Aug, 2018",
            endDate: "Oct, 2021",
            aboutEducation: "<p>Focused on developing a strong foundation in marketing strategies, consumer behavior, and digital marketing techniques. Engaged in practical coursework that applied real-world scenarios, preparing me for a successful career in digital marketing. Gained knowledge of digital platforms and advertising, essential for shaping innovative marketing campaigns in the future.</p>",
          },
        ],
      },
      {
        sectionId: "ppvp:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "ppvp:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "ppvp:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert"
          }
        ]
      },
      {
        sectionId: "ppvp:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "ppvp:INTEREST:1",
            interest: "Content Creation"
          },
          {
            listId: "ppvp:INTEREST:2",
            interest: "Data Analytics"
          },
          {
            listId: "ppvp:INTEREST:3",
            interest: "Networking Events"
          },
          {
            listId: "ppvp:INTEREST:4",
            interest: "Exploring SEO Trends"
          },
          {
            listId: "ppvp:INTEREST:4",
            interest: "Participation in SEO Trends"
          }
        ]
      },
      {
        sectionId: "ppvp:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "ppvp:CERTIFICATION:1",
            certificateName: "Google Ads Certification",
            instituteName: "Google",
            startDate: "Jan, 2023",
            endDate: "Apr, 2023",
            aboutCertificate: "<p>Achieved certification in Google Ads, demonstrating expertise in creating effective advertising campaigns across Google platforms. The course covered advanced techniques in PPC management, keyword strategy, and campaign analysis, empowering me to drive successful paid advertising strategies for clients.</p>"
          },
          {
            listId: "ppvp:CERTIFICATION:2",
            certificateName: "Digital Marketing Certification",
            instituteName: "HubSpot Academy",
            startDate: "May, 2023",
            endDate: "Jul, 2023",
            aboutCertificate: "<p>Completed a comprehensive digital marketing certification, focusing on strategies for content marketing, social media optimization, and email campaigns. Gained in-depth knowledge on creating effective digital strategies, measuring campaign success, and utilizing analytical tools to optimize results for clients.</p>"
          },
        ],
      }
    ],
  },

  // 19) BOX STYLE RESUME
  {
    id: "BOXSTLR",
    templateImage: "/template-images/Box_Style_Resume.jpg",
    templateName: "Box Style Resume",
    element: Box_Style_Resume,
    headingTextFont: "poppins",
    bodyTextFont: "Inter",
    headingTextColor: "#333",
    themeColor: "#C4DAD2",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Web Developer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BOXSTLR:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Creative and detail-oriented Web Developer with 3 years of experience in building responsive and user-friendly websites. Proficient in modern web technologies and passionate about enhancing user experiences.</p>",
      },
      {
        sectionId: "BOXSTLR:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: false,
        sectionName: "Contact",
        list: [
          {
            listId: "BOXSTLR:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "BOXSTLR:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "BOXSTLR:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Mumbai, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "BOXSTLR:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BOXSTLR:PROJECTS:1",
            projectName: "Portfolio Website",
            startDate: "Jan, 2023",
            endDate: "Mar, 2023",
            aboutProject:
              "<p>Developed a personal portfolio website using HTML, CSS, and JavaScript to showcase projects and skills. Implemented responsive design for optimal viewing on various devices.</p>",
          },
          {
            listId: "BOXSTLR:PROJECTS:2",
            projectName: "E-commerce Web Application",
            startDate: "May, 2022",
            endDate: "Dec, 2022",
            aboutProject:
              "<p>Created a fully functional e-commerce web application using React and Node.js, integrating payment gateways and managing user authentication to enhance the shopping experience.</p>",
          },
          {
            listId: "BOXSTLR:PROJECTS:3",
            projectName: "Blog Platform",
            startDate: "Jun, 2021",
            endDate: "Aug, 2021",
            aboutProject:
              "<p>Designed and developed a blogging platform utilizing WordPress, enabling users to create, edit, and publish blog posts while maintaining a responsive layout.</p>",
          },
        ],
      },
      {
        sectionId: "BOXSTLR:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BOXSTLR:SKILLS:1",
            skillName: "HTML5",
          },
          {
            listId: "BOXSTLR:SKILLS:2",
            skillName: "CSS3",
          },
          {
            listId: "BOXSTLR:SKILLS:3",
            skillName: "JavaScript",
          },
          {
            listId: "BOXSTLR:SKILLS:4",
            skillName: "React",
          },
          {
            listId: "BOXSTLR:SKILLS:5",
            skillName: "Node.js",
          },
        ],
      },
      {
        sectionId: "BOXSTLR:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BOXSTLR:JOB-EXP:1",
            companyName: "Web Innovations Inc.",
            jobRole: "Web Developer",
            startDate: "Feb, 2021",
            endDate: "Present",
            aboutJob:
              "<p>Designed and developed responsive websites and applications using modern frameworks, improving site performance by 30% through optimized code and best practices.</p>",
          },
          {
            listId: "BOXSTLR:JOB-EXP:2",
            companyName: "Digital Solutions Ltd.",
            jobRole: "Junior Web Developer",
            startDate: "Jul, 2019",
            endDate: "Jan, 2021",
            aboutJob:
              "<p>Assisted in the development of web applications and websites, collaborating with designers and stakeholders to ensure a seamless user experience and meeting project deadlines.</p>",
          },
        ],
      },
      {
        sectionId: "BOXSTLR:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BOXSTLR:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "Aug, 2016",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Completed coursework in web development, database management, and software engineering, gaining hands-on experience with various programming languages and frameworks.</p>",
          },
          {
            listId: "BOXSTLR:EDUCATION:2",
            collegeName: "Mumbai Institute of Technology",
            course: "Diploma in Web Development",
            startDate: "Sep, 2020",
            endDate: "Jun, 2021",
            aboutEducation:
              "<p>Focused on front-end and back-end technologies, including HTML, CSS, JavaScript, and PHP, with hands-on projects that enhanced practical skills in web development.</p>",
          },
        ],
      },
      {
        sectionId: "BOXSTLR:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "BOXSTLR:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "BOXSTLR:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Beginner"
          },
          {
            listId: "BOXSTLR:LANGUAGE:3",
            languageName: "Franch",
            languageProfiency: "Beginner"
          }
        ]
      },
      {
        sectionId: "BOXSTLR:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "BOXSTLR:INTEREST:1",
            interest: "Developing Personal Websites"
          },
          {
            listId: "BOXSTLR:INTEREST:2",
            interest: "Learning New Frameworks"
          },

          {
            listId: "BLHERETEM:INTEREST:3",
            interest: "Advocating for Web Accessibility"
          }
        ]
      },
      {
        sectionId: "BOXSTLR:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "BOXSTLR:CERTIFICATION:1",
            certificateName: "Certified Front-End Web Developer",
            instituteName: "Web Development Academy",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate: "<p>Completed a comprehensive certification program focusing on front-end development, including HTML, CSS, JavaScript, and responsive design principles.</p>"
          },
          {
            listId: "BOXSTLR:CERTIFICATION:2",
            certificateName: "Full Stack Web Development Certification",
            instituteName: "Tech Training Institute",
            startDate: "Jul, 2023",
            endDate: "Dec, 2023",
            aboutCertificate: "<p>Completed an extensive program covering both front-end and back-end development, focusing on React, Node.js, Express, and MongoDB.</p>"
          },
        ],
      },
    ]

  },

  // 20) UNIQUE STYLE RESUME
  {
    id: "UNIQUESR",
    templateImage: "/template-images/Unique-Style_Resume.jpg",
    templateName: "Unique Style Resume",
    element: Unique_Style_Resume,
    headingTextFont: "Laila",
    bodyTextFont: "Fjord One",
    headingTextColor: "#333",
    themeColor: "#DF61A6",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    category: "creative",
    subCategory: "ui-ux-designer",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "UI/UX Designer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "UNIQUESR:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Creative UI/UX Designer with 3 years of experience in crafting engaging user experiences through thoughtful design and research. Adept at transforming user needs into functional and visually appealing interfaces.</p>",
      },
      {
        sectionId: "UNIQUESR:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: false,
        sectionName: "Contact",
        list: [
          {
            listId: "UNIQUESR:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "UNIQUESR:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "UNIQUESR:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Mumbai, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "UNIQUESR:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "UNIQUESR:PROJECTS:1",
            projectName: "E-commerce Website",
            startDate: "Mar, 2022",
            endDate: "Aug, 2022",
            aboutProject:
              "<p>Led the redesign of an e-commerce platform, improving user flow and aesthetics, resulting in a 25% increase in conversion rates.</p>",
          },
          {
            listId: "UNIQUESR:PROJECTS:2",
            projectName: "Mobile App UI Design",
            startDate: "Sep, 2021",
            endDate: "Dec, 2021",
            aboutProject:
              "<p>Designed user interfaces for a mobile application focused on health tracking, incorporating user feedback and usability testing to enhance functionality.</p>",
          },
        ],
      },
      {
        sectionId: "UNIQUESR:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "UNIQUESR:SKILLS:1",
            skillName: "Adobe XD",
          },
          {
            listId: "UNIQUESR:SKILLS:2",
            skillName: "Figma",
          },
          {
            listId: "UNIQUESR:SKILLS:3",
            skillName: "Sketch",
          },
          {
            listId: "UNIQUESR:SKILLS:4",
            skillName: "User Research",
          },
          {
            listId: "UNIQUESR:SKILLS:5",
            skillName: "Prototyping",
          },
        ],
      },
      {
        sectionId: "UNIQUESR:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "UNIQUESR:JOB-EXP:1",
            companyName: "Creative Design Studio",
            jobRole: "UI/UX Designer",
            startDate: "Feb, 2021",
            endDate: "Present",
            aboutJob:
              "<p>Collaborated with product teams to design intuitive user interfaces and enhance user experiences, leading to a 30% reduction in user drop-off rates.</p>",
          },
          {
            listId: "UNIQUESR:JOB-EXP:2",
            companyName: "Innovative Solutions Ltd.",
            jobRole: "Junior UI/UX Designer",
            startDate: "Jun, 2019",
            endDate: "Jan, 2021",
            aboutJob:
              "<p>Assisted in designing user-friendly interfaces and conducted user testing sessions to gather feedback and improve product designs.</p>",
          },
        ],
      },
      {
        sectionId: "UNIQUESR:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "UNIQUESR:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Design in UI/UX",
            startDate: "Aug, 2016",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Studied design principles, user experience strategies, and visual communication, completing projects that emphasized user-centered design.</p>",
          },
          {
            listId: "UNIQUESR:EDUCATION:2",
            collegeName: "Navi Mumbai Design Institute",
            course: "Diploma in Graphic Design",
            startDate: "Jun, 2020",
            endDate: "Dec, 2020",
            aboutEducation:
              "<p>Focused on graphic design techniques, software skills, and visual storytelling, enhancing my ability to create compelling design narratives.</p>",
          },
        ],
      },
      {
        sectionId: "UNIQUESR:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "UNIQUESR:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "UNIQUESR:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "UNIQUESR:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "UNIQUESR:INTEREST:1",
            interest: "User Experience Research",
          },
          {
            listId: "UNIQUESR:INTEREST:2",
            interest: "Designing Interactive Prototypes",
          },
          {
            listId: "UNIQUESR:INTEREST:3",
            interest: "Creating Design Systems",
          },
          {
            listId: "UNIQUESR:INTEREST:4",
            interest: "Mentoring Aspiring Designers",
          },
        ],
      },
      {
        sectionId: "UNIQUESR:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "UNIQUESR:CERTIFICATION:1",
            certificateName: "Certified UI/UX Designer",
            instituteName: "Design Institute of Mumbai",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate:
              "<p>Completed a certification program focusing on advanced UI/UX design techniques, user research methodologies, and interaction design principles.</p>",
          }
          // ,
          // {
          //   listId: "UNIQUESR:CERTIFICATION:2",
          //   certificateName: "Human-Computer Interaction Certification",
          //   instituteName: "Online Design Academy",
          //   startDate: "Jul, 2023",
          //   endDate: "Dec, 2023",
          //   aboutCertificate:
          //     "<p>Gained knowledge in human-computer interaction, usability testing, and designing for accessibility, applying concepts to real-world projects.</p>",
          // },
        ],
      },
    ]

  },

  // 21) PATTERN STYLE RESUME
  {
    id: "PATTRNRES",
    templateImage: "/template-images/pattern-style-resume.jpg",
    templateName: "Pattern Style Resume",
    element: Pattern_Style_Resume,
    headingTextFont: "Mulish",
    bodyTextFont: "Inter",
    headingTextColor: "#000",
    themeColor: "#E08573",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "UI/UX Designer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "PATTRNRES:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Creative and user-focused UI/UX Designer with [X] years of experience in designing intuitive user interfaces and enhancing user experiences across web and mobile platforms.</p>",
      },
      {
        sectionId: "PATTRNRES:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: false,
        sectionName: "Contact",
        list: [
          {
            listId: "PATTRNRES:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "PATTRNRES:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "PATTRNRES:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Mumbai, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "PATTRNRES:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "PATTRNRES:PROJECTS:1",
            projectName: "E-commerce Website Redesign",
            startDate: "Dec, 2021",
            endDate: "Feb, 2022",
            aboutProject:
              "<p>Redesigned an e-commerce platform to enhance user experience, resulting in a [X]% increase in user retention and improved conversion rates.</p>",
          },
          {
            listId: "PATTRNRES:PROJECTS:2",
            projectName: "Mobile App UI Design",
            startDate: "Sep, 2022",
            endDate: "Jan, 2023",
            aboutProject:
              "<p>Designed a modern and intuitive mobile app interface, ensuring accessibility compliance and seamless user interactions.</p>",
          },
        ],
      },
      {
        sectionId: "PATTRNRES:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "PATTRNRES:SKILLS:1",
            skillName: "Figma",
          },
          {
            listId: "PATTRNRES:SKILLS:2",
            skillName: "Adobe XD",
          },
          {
            listId: "PATTRNRES:SKILLS:3",
            skillName: "Wireframing & Prototyping",
          },
          {
            listId: "PATTRNRES:SKILLS:4",
            skillName: "User Research",
          },
        ],
      },
      {
        sectionId: "PATTRNRES:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "PATTRNRES:JOB-EXP:1",
            companyName: "Design Studio Pvt. Ltd.",
            jobRole: "UI/UX Designer",
            startDate: "Jan, 2020",
            endDate: "Mar, 2023",
            aboutJob:
              "<p>Developed user-centric designs for web and mobile platforms, conducted usability tests, and collaborated with developers to implement design solutions effectively.</p>",
          },
        ],
      },
      {
        sectionId: "PATTRNRES:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "PATTRNRES:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Design in Communication",
            startDate: "Mar, 2016",
            endDate: "Jun, 2020",
            aboutEducation:
              "<p>Relevant coursework included UI/UX design principles, interaction design, graphic design, and human-computer interaction.</p>",
          },
          {
            listId: "PATTRNRES:EDUCATION:2",
            collegeName: "National Institute of Design",
            course: "Postgraduate Diploma in User Experience Design",
            startDate: "Jul, 2020",
            endDate: "Aug, 2022",
            aboutEducation:
              "<p>Specialized in creating user-centric designs, conducting user research, and prototyping for various digital platforms.</p>",
          },
          {
            listId: "PATTRNRES:EDUCATION:3",
            collegeName: "Coursera",
            course: "Specialization in Interaction Design",
            startDate: "Jan, 2023",
            endDate: "Dec, 2023",
            aboutEducation:
              "<p>Completed online specialization focusing on design systems, user testing, and advanced prototyping techniques.</p>",
          },
        ],
      },
      {
        sectionId: "PATTRNRES:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "PATTRNRES:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "PATTRNRES:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Intermediate",
          },
        ],
      },
      {
        sectionId: "PATTRNRES:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "PATTRNRES:INTEREST:1",
            interest: "Exploring UI/UX Trends",
          },
          {
            listId: "PATTRNRES:INTEREST:2",
            interest: "Creating Concept Designs",
          },
          {
            listId: "PATTRNRES:INTEREST:3",
            interest: "Participating in Design Challenges",
          },
          {
            listId: "PATTRNRES:INTEREST:4",
            interest: "Learning New Design Tools",
          },
          {
            listId: "PATTRNRES:INTEREST:5",
            interest: "Attending Design Meetups",
          },
        ],
      },
      {
        sectionId: "PATTRNRES:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "PATTRNRES:CERTIFICATION:1",
            certificateName: "Certified UX Designer",
            instituteName: "Interaction Design Foundation",
            startDate: "Feb, 2023",
            endDate: "Aug, 2023",
            aboutCertificate:
              "<p>Completed an in-depth certification on user experience design, covering user research, design thinking, and accessibility best practices.</p>",
          },
        ],
      },
    ],
  },

  // 22) YELLOW THEME TEMPLATE
  {
    id: "YELLOWTMP",
    templateImage: "/template-images/Yellow_Theme_Template.jpg",
    templateName: "Yellow Theme Template",
    element: Yellow_Theme__Template,
    headingTextFont: "Space Grotesk",
    bodyTextFont: "Inter",
    headingTextColor: "#333",
    themeColor: "#FFCF00",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "creative",
    userName: "Sneha Sharma",
    profileImage: "",
    userJobRole: "Graphic Designer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "YELLOWTMP:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Creative and detail-oriented Graphic Designer with [X] years of experience in delivering innovative and visually appealing designs. Proficient in Adobe Creative Suite, branding, and visual storytelling to drive engagement and meet business objectives.</p>",
      },

      {
        sectionId: "YELLOWTMP:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "YELLOWTMP:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "YELLOWTMP:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "YELLOWTMP:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Mumbai, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },

      {
        sectionId: "YELLOWTMP:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "YELLOWTMP:PROJECTS:1",
            projectName: "E-commerce Website Design",
            startDate: "Mar, 2022",
            endDate: "Jul, 2022",
            aboutProject:
              "<p>Redesigned the UI/UX of an e-commerce platform, enhancing user engagement by 25% through visually appealing layouts and streamlined navigation.</p>",
          },
          {
            listId: "YELLOWTMP:PROJECTS:2",
            projectName: "Corporate Branding for XYZ Ltd.",
            startDate: "Sep, 2022",
            endDate: "Dec, 2022",
            aboutProject:
              "<p>Developed a cohesive brand identity, including logo, typography, and marketing collateral, leading to a 30% increase in brand recognition.</p>",
          },
        ],
      },

      {
        sectionId: "YELLOWTMP:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "YELLOWTMP:SKILLS:1",
            skillName: "Adobe Photoshop",
          },
          {
            listId: "YELLOWTMP:SKILLS:2",
            skillName: "Adobe Illustrator",
          },
          {
            listId: "YELLOWTMP:SKILLS:3",
            skillName: "Figma",
          },
          {
            listId: "YELLOWTMP:SKILLS:4",
            skillName: "UI/UX Design",
          },
          {
            listId: "YELLOWTMP:SKILLS:5",
            skillName: "Branding and Identity",
          },
          {
            listId: "YELLOWTMP:SKILLS:6",
            skillName: "Typography",
          },
        ],
      },

      {
        sectionId: "YELLOWTMP:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "YELLOWTMP:JOB-EXP:1",
            companyName: "Creative Studio Pvt. Ltd.",
            jobRole: "Graphic Designer",
            startDate: "Sep, 2019",
            endDate: "Jul, 2022",
            aboutJob:
              "<p>Designed marketing materials, social media assets, and digital campaigns that boosted client engagement by 35%.</p>",
          },
          {
            listId: "YELLOWTMP:JOB-EXP:2",
            companyName: "Design Works Co.",
            jobRole: "Junior Graphic Designer",
            startDate: "Jan, 2017",
            endDate: "Mar, 2019",
            aboutJob:
              "<p>Collaborated with cross-functional teams to create innovative design solutions for clients in various industries, meeting 95% of project deadlines.</p>",
          },
        ],
      },

      {
        sectionId: "YELLOWTMP:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "YELLOWTMP:EDUCATION:1",
            collegeName: "National Institute of Design",
            course: "Bachelor of Design in Communication Design",
            startDate: "Jun, 2014",
            endDate: "Aug, 2017",
            aboutEducation:
              "<p>Focused on graphic design, branding, and user-centered design principles, graduating with distinction.</p>",
          },
          {
            listId: "YELLOWTMP:EDUCATION:2",
            collegeName: "Creative Design Academy",
            course: "Diploma in Graphic Design",
            startDate: "Sep, 2013",
            endDate: "Aug, 2014",
            aboutEducation:
              "<p>Completed an intensive program emphasizing Adobe Creative Suite tools, visual hierarchy, and design theory. Gained hands-on experience in creating logos, brochures, and social media assets for live client projects.</p>",
          },
        ],
      },

      {
        sectionId: "YELLOWTMP:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "YELLOWTMP:LANGUAGE:1",
            languageName: "English",
            languageProfiency: "Intermediate",
          },
          {
            listId: "YELLOWTMP:LANGUAGE:2",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
        ],
      },

      {
        sectionId: "YELLOWTMP:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "YELLOWTMP:INTEREST:1",
            interest: "Typography Design",
          },
          {
            listId: "YELLOWTMP:INTEREST:2",
            interest: "Brand Identity Creation",
          },
          {
            listId: "YELLOWTMP:INTEREST:3",
            interest: "Motion Graphics",
          },
          {
            listId: "YELLOWTMP:INTEREST:4",
            interest: "Visual Storytelling",
          },
        ],
      },

      {
        sectionId: "YELLOWTMP:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "YELLOWTMP:CERTIFICATION:1",
            certificateName: "Advanced Graphic Design",
            instituteName: "Coursera",
            startDate: "Jan, 2023",
            endDate: "June, 2023",
            aboutCertificate:
              "<p>Completed a comprehensive course on advanced graphic design techniques, including branding and digital illustrations.</p>",
          },
          {
            listId: "YELLOWTMP:CERTIFICATION:2",
            certificateName: "Digital Illustration Masterclass",
            instituteName: "Skillshare",
            startDate: "March, 2021",
            endDate: "May, 2021",
            aboutCertificate:
              "<p>Gained expertise in digital sketching, vector graphics, and creating compelling illustrations for branding and storytelling.</p>",
          },
        ],
      },

    ],
  },

  // 23) MODERN CURRICULUM RESUME TEMPLATE
  {
    id: "MCRTEMP",
    templateImage: "/template-images/Modern-Curriculum-Resume-Templates.jpg",
    templateName: "Modern Curriculum Resume Template",
    element: Modern_Curriculum_Resume_Templates,
    headingTextFont: "Chakra Petch",
    bodyTextFont: "Rajdhani",
    headingTextColor: "#333",
    themeColor: "#cca43b",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    subCategory: "accontants",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Accountant",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "MCRTEMP:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Meticulous Account and Finance Executive with 5+ years of experience in financial analysis, budgeting, and reporting. Skilled in managing financial statements, preparing tax filings, and streamlining accounting processes. Proficient in financial software, including QuickBooks and SAP, with a deep understanding of GAAP and IFRS standards. Adept at collaborating with cross-functional teams to ensure compliance and drive financial efficiency.</P>"
      },

      {
        sectionId: "MCRTEMP:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: false,
        sectionName: "",
        list: [
          {
            listId: "MCRTEMP:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "MCRTEMP:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "MCRTEMP:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Mumbai, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },

      {
        sectionId: "MCRTEMP:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "MCRTEMP:PROJECTS:1",
            projectName: "Automated Budget Forecast System",
            startDate: "Jul, 2022",
            endDate: "Nov, 2022",
            aboutProject:
              "<p>Developed and implemented an automated budget forecast system, reducing forecasting time by 40%. Integrated financial data from various departments to create a centralized reporting dashboard.</p>",
          },
          {
            listId: "MCRTEMP:PROJECTS:2",
            projectName: "Compliance and Audit Improvement",
            startDate: "Jan, 2021",
            endDate: "Apr, 2021",
            aboutProject:
              "<p>Led a project to improve internal audit procedures, ensuring compliance with IFRS and tax regulations. Achieved a 95% accuracy rate in financial reporting within the first quarter of implementation.</p>",
          },
        ],
      },

      {
        sectionId: "MCRTEMP:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "MCRTEMP:SKILLS:1",
            skillName: "Financial Analysis",
          },
          {
            listId: "MCRTEMP:SKILLS:2",
            skillName: "Budgeting & Forecasting",
          },
          {
            listId: "MCRTEMP:SKILLS:3",
            skillName: "Tax Compliance",
          },
          {
            listId: "MCRTEMP:SKILLS:4",
            skillName: "QuickBooks & SAP",
          },
          {
            listId: "MCRTEMP:SKILLS:5",
            skillName: "Financial Reporting",
          },
          {
            listId: "MCRTEMP:SKILLS:6",
            skillName: "GAAP & IFRS Standards",
          },
        ],
      },

      {
        sectionId: "MCRTEMP:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "MCRTEMP:JOB-EXP:1",
            companyName: "FinCorp Solutions",
            jobRole: "Senior Account Executive",
            startDate: "Mar, 2021",
            endDate: "Oct, 2023",
            aboutJob:
              "<p>Managed financial records, prepared monthly balance sheets, and conducted variance analysis for annual budgets. Improved cash flow management strategies, leading to a 20% reduction in operational expenses. Supervised a team of 4 accountants to ensure timely and accurate reporting.</p>",
          },
          {
            listId: "MCRTEMP:JOB-EXP:2",
            companyName: "Global Finance Pvt. Ltd.",
            jobRole: "Junior Accountant",
            startDate: "May, 2018",
            endDate: "Feb, 2021",
            aboutJob:
              "<p>Handled daily bookkeeping, reconciled accounts, and assisted in quarterly tax filings. Supported the finance team in preparing financial reports and achieving compliance with local tax laws and audit standards.</p>",
          },
        ],
      },

      {
        sectionId: "MCRTEMP:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "MCRTEMP:EDUCATION:1",
            collegeName: "University of Mumbai",
            course: "Master of Commerce in Accounting",
            startDate: "Jul, 2020",
            endDate: "Jun, 2022",
            aboutEducation:
              "<p>Gained a strong foundation in financial management, tax accounting, and corporate laws. Proficient in financial statement analysis, budget forecasting, and auditing principles. Completed an extensive final-year project focusing on the impact of GST on Indian businesses, receiving high accolades.</p>",
          },
          {
            listId: "MCRTEMP:EDUCATION:2",
            collegeName: "University of Mumbai",
            course: "Bachelor of Commerce in Finance",
            startDate: "Jul, 2017",
            endDate: "Jun, 2020",
            aboutEducation:
              "<p>Specialized in financial strategy, risk management, and international finance. Conducted research on investment portfolio optimization and secured distinctions in financial analytics and advanced accounting. Coursework also included advanced statistical tools for business decision-making and credit risk management.</p>",
          },
        ],
      },

      {
        sectionId: "MCRTEMP:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "MCRTEMP:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "MCRTEMP:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Beginner",
          },
        ],
      },

      {
        sectionId: "MCRTEMP:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "MCRTEMP:INTEREST:1",
            interest: "Financial Planning Strategies",
          },
          {
            listId: "MCRTEMP:INTEREST:2",
            interest: "Analyzing Market Trends",
          },
          {
            listId: "MCRTEMP:INTEREST:3",
            interest: "Taxation Policies Research",
          },
          {
            listId: "MCRTEMP:INTEREST:4",
            interest: "Reading Economic Journals",
          },
        ],
      },

      {
        sectionId: "MCRTEMP:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "MCRTEMP:CERTIFICATION:1",
            certificateName: "Certified Financial Analyst (CFA)",
            instituteName: "CFA Institute",
            startDate: "Jan, 2023",
            endDate: "Dec, 2023",
            aboutCertificate:
              "<p>Learned advanced financial modeling techniques, including building robust forecasting models, analyzing cash flow projections, and conducting valuation using DCF and comparable company analysis. Demonstrated expertise in Excel and financial tools for strategic decision-making.</p>",
          },
          {
            listId: "MCRTEMP:CERTIFICATION:2",
            certificateName: "Advanced Excel for Accounting",
            instituteName: "Coursera",
            startDate: "Jun, 2022",
            endDate: "Aug, 2022",
            aboutCertificate:
              "<p>Learned advanced financial modeling techniques, including building robust forecasting models, analyzing cash flow projections, and conducting valuation using DCF and comparable company analysis. Demonstrated expertise in Excel and financial tools for strategic decision-making.</p>",
          },
        ],
      },

    ],
  },

  // 24) THREE SIDE BORDER RESUME
  {
    id: "THSIBORE",
    templateImage: "/template-images/Three_Side_Border_Resume.jpg",
    templateName: "Three Side Border Resume",
    element: Three_Side_Border_Resume,
    headingTextFont: "Space Grotesk",
    bodyTextFont: "Titillium Web",
    headingTextColor: "#000",
    themeColor: "#669bbc",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    subCategory: "data-analyst",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Data Analyst",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "THSIBORE:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Detail-oriented Data Analyst with 4+ years of experience in analyzing complex datasets and providing actionable insights. Proficient in tools such as Python, SQL, Excel, and Tableau, with expertise in data visualization and predictive modeling. Skilled in collaborating with cross-functional teams to enhance decision-making and drive business growth.</p>"
      },
      {
        sectionId: "THSIBORE:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact Us",
        list: [
          {
            listId: "THSIBORE:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "THSIBORE:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "THSIBORE:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Mumbai, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "THSIBORE:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "THSIBORE:PROJECTS:1",
            projectName: "Customer Segmentation Analysis",
            startDate: "Jan, 2022",
            endDate: "Jun, 2022",
            aboutProject:
              "<p>Utilized Python and SQL to perform clustering analysis on customer data, identifying distinct customer segments and optimizing marketing strategies, resulting in a 15% increase in customer retention.</p>",
          },
          {
            listId: "THSIBORE:PROJECTS:2",
            projectName: "Sales Forecasting Model",
            startDate: "Jul, 2022",
            endDate: "Dec, 2022",
            aboutProject:
              "<p>Developed a machine learning model using Python to predict sales trends, improving forecasting accuracy by 25% and enabling better inventory management.</p>",
          },
        ],
      },
      {
        sectionId: "THSIBORE:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "THSIBORE:SKILLS:1",
            skillName: "Python",
          },
          {
            listId: "THSIBORE:SKILLS:2",
            skillName: "SQL",
          },
          {
            listId: "THSIBORE:SKILLS:3",
            skillName: "Tableau",
          },
          {
            listId: "THSIBORE:SKILLS:4",
            skillName: "Excel",
          },
          {
            listId: "THSIBORE:SKILLS:5",
            skillName: "Machine Learning",
          },
          {
            listId: "THSIBORE:SKILLS:6",
            skillName: "Spring Boot",
          },
        ],
      },
      {
        sectionId: "THSIBORE:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "THSIBORE:JOB-EXP:1",
            companyName: "Data Insights Pvt. Ltd.",
            jobRole: "Data Analyst",
            startDate: "Jan, 2020",
            endDate: "Dec, 2022",
            aboutJob:
              "<p>Analyzed large datasets using Python and SQL to uncover trends, providing actionable insights that improved operational efficiency by 20%. Delivered automated dashboards in Tableau for real-time decision-making.</p>",
          },
          {
            listId: "THSIBORE:JOB-EXP:2",
            companyName: "Analytics Hub",
            jobRole: "Data Analyst Intern",
            startDate: "Jun, 2019",
            endDate: "Dec, 2019",
            aboutJob:
              "<p>Assisted in developing automated reporting solutions, reducing reporting time by 30%. Conducted statistical analysis to support business strategies and decisions.</p>",
          },
        ],
      },
      {
        sectionId: "THSIBORE:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "THSIBORE:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Data Science",
            startDate: "Jul, 2016",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Relevant coursework included database management systems, software engineering principles, algorithms and data structures, web development, and cloud computing. Gained hands-on experience in designing and deploying software solutions and working with databases and modern frameworks.</p>",
          },
          {
            listId: "THSIBORE:EDUCATION:2",
            collegeName: "Mumbai University",
            course: "Master of Science in Data Science",
            startDate: "Aug, 2020",
            endDate: "May, 2022",
            aboutEducation: "<p>Focused on advanced data analysis techniques, machine learning, artificial intelligence, and data visualization. Completed practical projects using tools such as Python, R, and SQL to analyze and interpret large datasets, and delivered insights to optimize business decisions.</p>"
          }
        ],
      },
      {
        sectionId: "THSIBORE:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "THSIBORE:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "THSIBORE:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Intermediate",
          },
          {
            listId: "THSIBORE:LANGUAGE:3",
            languageName: "Sanskrit",
            languageProfiency: "Beginner",
          },
        ],
      },
      {
        sectionId: "THSIBORE:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "THSIBORE:INTEREST:1",
            interest: "Data Visualization Projects",
          },
          {
            listId: "THSIBORE:INTEREST:2",
            interest: "Exploring Machine Learning",
          },
          {
            listId: "THSIBORE:INTEREST:3",
            interest: "Participating in Hackathons",
          },
          {
            listId: "THSIBORE:INTEREST:4",
            interest: "Advancing Business Intelligence",
          },
          {
            listId: "THSIBORE:INTEREST:5",
            interest: "Web Accessibility Advocacy"
          },
          {
            listId: "THSIBORE:INTEREST:6",
            interest: "Artificial Intelligence Research"
          }
        ],
      },
      {
        sectionId: "THSIBORE:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "THSIBORE:CERTIFICATION",
            certificateName: "Data Analyst Professional Certificate",
            instituteName: "IBM Skills Network",
            startDate: "Feb, 2023",
            endDate: "Aug, 2023",
            aboutCertificate:
              "<p>Completed hands-on training in Python, SQL, and data visualization tools. Gained expertise in creating impactful data-driven solutions for business challenges.</p>",
          },
        ],
      }

    ],
  },

  // 25) ENTRY LEVEL TECH PROFESSIONAL RESUME
  {
    id: "ELTPR",
    templateImage: "/template-images/Entry level tech profeesional resume.jpg",
    templateName: "Entry Level Tech Professional Resume",
    element: Entry_Level_Tech_Professional_Resume,
    headingTextFont: "Titillium Web",
    bodyTextFont: "Inter",
    headingTextColor: "#000",
    themeColor: "#D2E4E2",
    bodyTextColor: "rgba(0,0,0,0.9)",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "minimal",
    userName: "Sneha Sharma",
    profileImage: "",
    userJobRole: "Account and Finance Executive",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: "Background_Pattern_2.png",
    },

    AllSections: [
      {
        sectionId: "FINACCT:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Dedicated Accounts and Finance Executive with [X] years of expertise in financial management, budgeting, and account reconciliation. Proficient in preparing financial reports, analyzing data for decision-making, and ensuring compliance with regulatory standards. Skilled in leveraging financial tools to streamline processes and enhance accuracy.</p>",
      },
      {
        sectionId: "FINACCT:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "FINACCT:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "aditya.singh@email.com",
            additionalLink: "",
          },
          {
            listId: "FINACCT:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "FINACCT:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "456 Finance Avenue, Pune, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "FINACCT:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "FINACCT:PROJECTS:1",
            projectName: "Budget Optimization Framework",
            startDate: "May, 2019",
            endDate: "Jun, 2020",
            aboutProject:
              "Developed a framework for optimizing budget allocation, leading to a 15% reduction in unnecessary expenses.",
          },
        ],
      },
      {
        sectionId: "FINACCT:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "FINACCT:SKILLS:1",
            skillName: "Financial Analysis",
          },
          {
            listId: "FINACCT:SKILLS:2",
            skillName: "Budgeting and Forecasting",
          },
          {
            listId: "FINACCT:SKILLS:3",
            skillName: "Accounting Software (Tally, QuickBooks)",
          },
          {
            listId: "FINACCT:SKILLS:4",
            skillName: "Risk Assessment",
          },
          {
            listId: "FINACCT:SKILLS:5",
            skillName: "Data Visualization Tools",
          },
        ],
      },
      {
        sectionId: "FINACCT:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "FINACCT:JOB-EXP:1",
            companyName: "Elite Financial Services",
            jobRole: "Accounts Manager",
            startDate: "Jan, 2020",
            endDate: "Jul, 2023",
            aboutJob:
              "Managed end-to-end accounting processes, including account reconciliation and financial reporting, ensuring compliance with corporate policies.",
          },
        ],
      },
      {
        sectionId: "FINACCT:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "FINACCT:EDUCATION:1",
            collegeName: "University of Pune",
            course: "Master of Commerce (M.Com) in Finance",
            startDate: "Feb, 2015",
            endDate: "Jun, 2017",
            aboutEducation:
              "<p>Specialized in accounting, finance, and taxation with a focus on corporate laws and economics. Completed advanced coursework in financial management, auditing, and business analytics. Actively participated in the university's commerce society, organizing seminars on financial literacy and investment strategies.</p>",
          },
        ],
      },
      {
        sectionId: "FINACCT:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "FINACCT:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
          {
            listId: "FINACCT:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "FINACCT:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "FINACCT:INTEREST:1",
            interest: "Reading Financial Journals",
          },
          {
            listId: "FINACCT:INTEREST:2",
            interest: "Exploring Investment Strategies",
          },
          {
            listId: "FINACCT:INTEREST:3",
            interest: "Data-Driven Decision Making",
          },
          {
            listId: "FINACCT:INTEREST:4",
            interest: "Mentoring Budding Accountants",
          },
        ],
      },
      {
        sectionId: "FINACCT:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "FINACCT:CERTIFICATION",
            certificateName: "Certified Financial Analyst (CFA) - Level I",
            instituteName: "CFA Institute",
            startDate: "Jun, 2022",
            endDate: "Dec, 2022",
            aboutCertificate:
              "<p>Covered essential topics such as equity investments, fixed income, financial reporting, quantitative methods, and ethics. Acquired in-depth knowledge of global financial markets and developed analytical skills for evaluating investment opportunities. Completed rigorous training in portfolio management and financial analysis techniques, preparing for advanced certifications.</p>",
          },
        ],
      }

    ],
  },

  // 26) SALES ACHIEVER RESUME TEMPLATE
  {
    id: "SALESACH",
    templateImage: "/template-images/SalesAchieverResume.jpg",
    templateName: "SALES ACHIEVER RESUME",
    element: SalesAchieverResume,
    headingTextFont: "DM Serif Text",
    bodyTextFont: "Inter",
    headingTextColor: "#000",
    themeColor: "#B3CCF1",
    bodyTextColor: "#000000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    subCategory: "bde",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Business Development and Executive",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "SALESACH:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Dynamic Business Development Executive with 5+ years of experience driving growth through strategic partnerships, market analysis, and innovative sales initiatives. Proven expertise in client acquisition, revenue expansion, and fostering long-term business relationships.</p>",
      },
      {
        sectionId: "SALESACH:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact",
        list: [
          {
            listId: "SALESACH:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma@email.com",
            additionalLink: "",
          },
          {
            listId: "SALESACH:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "SALESACH:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "456 Park Avenue, Pune, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "SALESACH:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "SALESACH:PROJECTS:1",
            projectName: "Client Onboarding Process Redesign",
            startDate: "Jan, 2021",
            endDate: "Dec, 2021",
            aboutProject:
              "Streamlined client onboarding processes, reducing onboarding time by 30% and improving client satisfaction ratings by 15%. This involved analyzing existing workflows, identifying bottlenecks, and implementing automated solutions using CRM tools like Salesforce.",
          },
          {
            listId: "SALESACH:PROJECTS:2",
            projectName: "Market Penetration Strategy",
            startDate: "Mar, 2022",
            endDate: "Sep, 2022",
            aboutProject:
              "Developed a comprehensive market penetration strategy targeting emerging markets. Conducted in-depth market research, identified potential client segments, and designed tailored approaches for engagement. Achieved a 25% increase in revenue and established strategic partnerships with 5 key industry players.",
          },
        ],
      },
      {
        sectionId: "SALESACH:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "SALESACH:SKILLS:1",
            skillName: "Lead Generation",
          },
          {
            listId: "SALESACH:SKILLS:2",
            skillName: "CRM Tools (Salesforce, Zoho)",
          },
          {
            listId: "SALESACH:SKILLS:3",
            skillName: "Strategic Planning",
          },
          {
            listId: "SALESACH:SKILLS:4",
            skillName: "Negotiation Skills",
          },
          {
            listId: "SALESACH:SKILLS:5",
            skillName: "Market Research",
          },
        ],
      },
      {
        sectionId: "SALESACH:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "SALESACH:JOB-EXP:1",
            companyName: "NextGen Solutions Pvt. Ltd.",
            jobRole: "Business Development Executive",
            startDate: "Jun, 2018",
            endDate: "Dec, 2020",
            aboutJob:
              "Developed and executed business strategies, securing partnerships with over 15 key clients, leading to a 20% increase in company revenue. Enhanced brand visibility through targeted campaigns and effectively managed a pipeline of high-value prospects using Zoho CRM.",
          },
          {
            listId: "SALESACH:JOB-EXP:2",
            companyName: "Global Reach Pvt. Ltd.",
            jobRole: "Senior Business Development Manager",
            startDate: "Jan, 2021",
            endDate: "Present",
            aboutJob:
              "Led a team of 8 in identifying growth opportunities, successfully expanding the company's client base by 40% within two years. Established a structured framework for sales forecasting and performance analysis, boosting team efficiency by 30%. Worked closely with marketing teams to align sales and marketing strategies, enhancing customer retention rates by 25%.",
          },
        ],
      },
      {
        sectionId: "SALESACH:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "SALESACH:EDUCATION:1",
            collegeName: "Symbiosis International University",
            course: "MBA in Business Administration",
            startDate: "Jul, 2016",
            endDate: "Jun, 2018",
            aboutEducation:
              "<p>Specialized in marketing and business strategy. Participated in case competitions and won accolades for innovative market analysis presentations.</p>",
          },
          {
            listId: "SALESACH:EDUCATION:2",
            collegeName: "Harvard Business School (Online)",
            course: "Certificate in Business Analytics",
            startDate: "Sep, 2021",
            endDate: "Dec, 2021",
            aboutEducation:
              "<p>Learned to leverage data-driven approaches for decision-making in sales and business development. The coursework covered predictive analytics, customer segmentation, and advanced Excel modeling.</p>",
          }
        ],
      },
      {
        sectionId: "SALESACH:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "SALESACH:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "SALESACH:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert"
          },
          {
            listId: "SALESACH:LANGUAGE:3",
            languageName: "Marathi",
            languageProfiency: "Intermediate"
          }
        ]
      },
      {
        sectionId: "SALESACH:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "SALESACH:INTEREST:1",
            interest: "Networking and Public Speaking"
          },
          {
            listId: "SALESACH:INTEREST:2",
            interest: "Exploring Business Trends"
          },
          {
            listId: "SALESACH:INTEREST:3",
            interest: "Traveling to Emerging Markets"
          },
          {
            listId: "SALESACH:INTEREST:4",
            interest: "Innovative Sales Techniques"
          }
        ]
      },
      {
        sectionId: "SALESACH:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "SALESACH:CERTIFICATION",
            certificateName: "Certified Business Development Professional",
            instituteName: "National Institute of Sales Management",
            startDate: "Aug, 2022",
            endDate: "Dec, 2022",
            aboutCertificate:
              "<p>Earned certification in advanced business development strategies, focusing on client acquisition, lead nurturing, and revenue growth. Gained expertise in negotiation techniques, data-driven decision-making, and building scalable sales pipelines.</p>",
          },
          {
            listId: "SALESACH:CERTIFICATION:2",
            certificateName: "Strategic Sales Leadership",
            instituteName: "Harvard Online Business School",
            startDate: "Jan, 2023",
            endDate: "Mar, 2023",
            aboutCertificate:
              "<p>Completed an intensive program on sales leadership, covering strategic planning, team management, and enhancing operational efficiency. Learned to integrate leadership principles with analytical tools for improved sales performance.</p>",
          }
        ],
      }

    ],
  },

  // 27) NUEVA TEMPLATE
  {
    id: "NUEVAT",
    templateImage: "/template-images/NuevaTemplate.jpg",
    templateName: "Nueva Template",
    element: NuevaTemplate,
    headingTextFont: "Raleway",
    bodyTextFont: "Inter",
    headingTextColor: "#fff",
    themeColor: "#7FAFA8",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "minimal",
    userName: "Sneha Sharma",
    profileImage: "",
    userJobRole: "Content Writer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "NUEVAT:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Creative and detail-oriented Content Writer with 4+ years of experience crafting compelling content for digital platforms, blogs, and marketing campaigns. Skilled in SEO optimization, audience engagement, and storytelling to drive brand visibility and business growth.</p>",
      },
      {
        sectionId: "NUEVAT:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: false,
        sectionName: "Contact",
        list: [
          {
            listId: "NUEVAT:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma000@email.com",
            additionalLink: "",
          },
          {
            listId: "NUEVAT:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "NUEVAT:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "456 Elm Street, Pune, Maharashtra, India",
            additionalLink: "",
          }
        ],
      },
      {
        sectionId: "NUEVAT:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "NUEVAT:PROJECTS:1",
            projectName: "SEO Content Strategy for E-Commerce",
            startDate: "Jan, 2022",
            endDate: "Apr, 2022",
            aboutProject:
              "<p>Developed a comprehensive SEO strategy for a mid-sized e-commerce business. Created keyword-optimized blogs and product descriptions that increased organic traffic by 45% within three months.</p>"
          },
          {
            listId: "NUEVAT:PROJECTS:2",
            projectName: "Website Content Overhaul for NGO",
            startDate: "Jul, 2022",
            endDate: "Oct, 2022",
            aboutProject:
              "<p>Revamped the website copy for an environmental NGO. Crafted compelling mission-driven content and call-to-actions, leading to a 30% increase in volunteer sign-ups and donations.</p>"
          },
        ],
      },
      {
        sectionId: "NUEVAT:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "NUEVAT:SKILLS:1",
            skillName: "Content Strategy",
          },
          {
            listId: "NUEVAT:SKILLS:2",
            skillName: "SEO Optimization",
          },
          {
            listId: "NUEVAT:SKILLS:3",
            skillName: "Copywriting",
          },
          {
            listId: "NUEVAT:SKILLS:4",
            skillName: "Social Media Writing",
          },
          {
            listId: "NUEVAT:SKILLS:5",
            skillName: "CMS Platforms",
          }
        ],
      },
      {
        sectionId: "NUEVAT:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "NUEVAT:JOB-EXP:1",
            companyName: "Creative Hive Solutions",
            jobRole: "Content Writer",
            startDate: "Jun, 2020",
            endDate: "Present",
            aboutJob:
              "<p>Authored over 200 high-performing articles and blogs for various industries, including tech, healthcare, and retail. Collaborated with marketing teams to develop compelling campaigns and social media strategies.</p>",
          },
        ],
      },
      {
        sectionId: "NUEVAT:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "NUEVAT:EDUCATION:1",
            collegeName: "Delhi University",
            course: "Bachelor of Arts in English Literature",
            startDate: "Jul, 2016",
            endDate: "Jun, 2019",
            aboutEducation:
              "<p>Specialized in creative and analytical writing, literature analysis, and modern communication theories. Completed a capstone project focused on the evolution of storytelling in digital media.</p>"
          },
        ],
      },
      {
        sectionId: "NUEVAT:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "NUEVAT:LANGUAGE:1",
            languageName: "English",
            languageProfiency: "Intermediate",
          },
          {
            listId: "NUEVAT:LANGUAGE:2",
            languageName: "Hindi",
            languageProfiency: "Intermediate",
          }
        ]
      },
      {
        sectionId: "NUEVAT:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "NUEVAT:INTEREST:1",
            interest: "Creative Writing",
          },
          {
            listId: "NUEVAT:INTEREST:2",
            interest: "Exploring New Cultures",
          },
          {
            listId: "NUEVAT:INTEREST:3",
            interest: "Reading Historical Novels",
          },
          {
            listId: "NUEVAT:INTEREST:4",
            interest: "Travel Blogging",
          }
        ]
      },
      {
        sectionId: "NUEVAT:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "NUEVAT:CERTIFICATION:1",
            certificateName: "Content Marketing Certified",
            instituteName: "HubSpot Academy",
            startDate: "Feb, 2021",
            endDate: "May, 2021",
            aboutCertificate:
              "<p>Mastered strategies for creating high-quality, engaging content tailored for digital audiences. Focused on SEO optimization, audience segmentation, and performance analysis.</p>"
          },
        ],
      }
    ],
  },

  // 28) BORDER CHAIN RESUME TEMPLATE
  {
    id: "BORDERCRT",
    templateName: "Border Chain Resume",
    templateImage: "/template-images/Border-Chain-Resume-Template.jpg",
    element: Border_Chain_Resume,
    headingTextFont: "Poppins",
    bodyTextFont: "Mulish",
    headingTextColor: "#000",
    themeColor: "#7FAFA8",
    bodyTextColor: "rgba(0,0,0,0.9)",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Content Writer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BORDERCRT:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "Creative and results-driven content writer specializing in crafting engaging digital content to enhance brand identity and user engagement."
      },
      {
        sectionId: "BORDERCRT:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: false,
        sectionName: "Contact",
        list: [
          {
            listId: "BORDERCRT:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma@email.com",
            additionalLink: ""
          },
          {
            listId: "BORDERCRT:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: ""
          },
          {
            listId: "BORDERCRT:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "45/A Writers Lane, Kolkata, West Bengal, India",
            additionalLink: ""
          }
        ]
      },
      {
        sectionId: "BORDERCRT:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BORDERCRT:PROJECTS:1",
            projectName: "Travel Blog Campaign",
            startDate: "Jun, 2022",
            endDate: "Dec, 2022",
            aboutProject:
              "Designed and executed a series of SEO-optimized travel blogs, driving a 35% increase in site traffic. Focused on keyword research and user-centric content."
          },
          {
            listId: "BORDERCRT:PROJECTS:2",
            projectName: "Product Review Content Strategy",
            startDate: "Feb, 2023",
            endDate: "May, 2023",
            aboutProject:
              "Developed a structured content approach for product reviews, resulting in enhanced user engagement and a 20% increase in affiliate link clicks."
          }
        ]
      },
      {
        sectionId: "BORDERCRT:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BORDERCRT:SKILLS:1",
            skillName: "SEO Content Writing"
          },
          {
            listId: "BORDERCRT:SKILLS:2",
            skillName: "Copywriting"
          },
          {
            listId: "BORDERCRT:SKILLS:3",
            skillName: "Content Strategy"
          },
          {
            listId: "BORDERCRT:SKILLS:4",
            skillName: "Social Media Content"
          },
          {
            listId: "BORDERCRT:SKILLS:5",
            skillName: "Creative Writing"
          }
        ]
      },
      {
        sectionId: "BORDERCRT:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BORDERCRT:JOB-EXP:1",
            companyName: "ContentEdge Pvt. Ltd.",
            jobRole: "Content Writer",
            startDate: "Mar, 2021",
            endDate: "Apr, 2023",
            aboutJob:
              "Created engaging blog posts, newsletters, and marketing materials. Conducted keyword research and collaborated with the design team to optimize content for diverse platforms."
          },
          {
            listId: "BORDERCRT:JOB-EXP:2",
            companyName: "DigitalHive Agency",
            jobRole: "Freelance Copywriter",
            startDate: "May, 2023",
            endDate: "Present",
            aboutJob:
              "Developing compelling ad copies and social media posts tailored to client needs. Focused on brand storytelling and increasing audience retention."
          }
        ]
      },
      {
        sectionId: "BORDERCRT:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BORDERCRT:EDUCATION:1",
            collegeName: "University of Calcutta",
            course: "Bachelor of Arts in Journalism and Mass Communication",
            startDate: "Jul, 2017",
            endDate: "Jun, 2020",
            aboutEducation:
              "<p>Studied various aspects of media communication, including media ethics, creative writing, and public relations. Gained hands-on experience with news writing, editing, and digital storytelling. Spearheaded a student-led campus blog that attracted over 5,000 monthly readers.</p>"
          },
          {
            listId: "BORDERCRT:EDUCATION:2",
            collegeName: "Xavier Institute of Communications",
            course: "Postgraduate Diploma in Digital Marketing",
            startDate: "Jul, 2020",
            endDate: "Jun, 2021",
            aboutEducation:
              "<p>Specialized in digital content strategy, analytics, and marketing campaigns. Completed a capstone project on 'The Role of Content in Brand Building' that received high acclaim. Learned advanced tools like Google Analytics, SEMrush, and Canva, applying them to real-world marketing challenges.</p>"
          }
        ]
      },
      {
        sectionId: "BORDERCRT:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "BORDERCRT:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "BORDERCRT:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Beginner"
          },
          {
            listId: "BORDERCRT:LANGUAGE:3",
            languageName: "Bengali",
            languageProfiency: "Intermediate"
          }
        ]
      },
      {
        sectionId: "BORDERCRT:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "BORDERCRT:INTEREST:1",
            interest: "Creative Blogging"
          },
          {
            listId: "BORDERCRT:INTEREST:2",
            interest: "Exploring Digital Media Trends"
          },
          {
            listId: "BORDERCRT:INTEREST:3",
            interest: "Fictional Storytelling"
          },
          {
            listId: "BORDERCRT:INTEREST:4",
            interest: "Community Building through Content"
          }
        ]
      },
      {
        sectionId: "BORDERCRT:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "BORDERCRT:CERTIFICATION",
            certificateName: "Certified Content Marketing Specialist",
            instituteName: "HubSpot Academy",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate:
              "<p>Learned to create impactful, user-centric content strategies and optimized content for online visibility and engagement.</p>"
          }
        ]
      }

    ],
  },

  // 29) HMATSTEMPLATE
  {
    id: "HMATST",
    templateImage: "/template-images/HMATS-Resume-Template.jpg",
    templateName: "HMATS Resume Template",
    element: HMATSTamplate,
    headingTextFont: "Oswald",
    bodyTextFont: "Inter",
    headingTextColor: "#000",
    bodyTextColor: "#333",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "minimal",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Product Manager",
    subCategory: "productManager",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    //RESUME DETAILS
    resumeDetails: {
      ATS_Score: 60,
    },

    // SECTIONS
    AllSections: [
      {
        sectionId: "HMATST:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "Results-oriented Product Manager with [X] years of experience in driving product strategy and development through data-driven insights. Expertise in leveraging data analysis and market research to guide product decisions, optimize features.",
      },
      {
        sectionId: "HMATST:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: false,
        sectionName: "Contact",
        list: [
          {
            listId: "HMATST:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "sneha.sharma@email.com",
            additionalLink: "",
          },
          {
            listId: "HMATST:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "HMATST:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "123 Main Street, Mumbai, Maharashtra, India",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "HMATST:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "HMATST:PROJECTS:1",
            projectName: "Online Shopping Portal",
            startDate: "Jun, 2020",
            endDate: "Aug, 2020",
            aboutProject:
              "Developed a scalable e-commerce platform using Java Spring Boot and Angular, enabling seamless online shopping experiences for users.",
          },
        ],
      },
      {
        sectionId: "HMATST:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "HMATST:SKILLS:1",
            skillName: "Python",
          },
          {
            listId: "HMATST:SKILLS:2",
            skillName: " Power BI",
          },
          {
            listId: "HMATST:SKILLS:3",
            skillName: "SQL",
          },
          {
            listId: "HMATST:SKILLS:4",
            skillName: "Agile",
          },
          {
            listId: "HMATST:SKILLS:5",
            skillName: "Git and GitHub",
          },
        ],
      },
      {
        sectionId: "HMATST:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "HMATST:JOB-EXP:1",
            companyName: "Tech Solutions Pvt. Ltd.",
            jobRole: "Product Manager",
            startDate: "Sep, 2019",
            endDate: "Jan, 2021",
            aboutJob:
              "Spearheaded the development and launch of [Product Name], utilizing data-driven insights to inform product features and improvements.",
          },
          {
            listId: "HMATST:JOB-EXP:2",
            companyName: "Tech Solutions Pvt. Ltd.",
            jobRole: "Product Manager",
            startDate: "Jan, 2019",
            endDate: "Nov, 2021",
            aboutJob:
              "Spearheaded the development and launch of [Product Name], utilizing data-driven insights to inform product features and improvements.",
          },
        ],
      },
      {
        sectionId: "HMATST:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "HMATST:EDUCATION:1",
            collegeName: "Mumbai University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "May, 2017",
            endDate: "May, 2020",
            aboutEducation:
              "Relevant coursework included database management systems, software engineering principles, algorithms and data structures, and web development. Developed strong analytical and problem-solving skills through practical projects and internships, contributing to a solid foundation in software development.",
          },
        ],
      },
      {
        sectionId: "HMATST:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "HMATST:LANGUAGE:1",
            languageName: "Hindi",
            languageProfiency: "Expert"
          },
          {
            listId: "HMATST:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert"
          }
        ]
      },
      {
        sectionId: "HMATST:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "HMATST:INTEREST:1",
            interest: "Building Personal Projects"
          },
          {
            listId: "HMATST:INTEREST:2",
            interest: "Exploring New Technologies"
          },
          {
            listId: "HMATST:INTEREST:3",
            interest: "Participating in Hackathons"
          },
          {
            listId: "HMATST:INTEREST:4",
            interest: "Web Accessibility Advocacy"
          }
        ]
      },
      {
        sectionId: "HMATST:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "HMATST:CERTIFICATION",
            certificateName: "Certified JavaScript Developer",
            instituteName: "Tech Academy",
            startDate: "Jan, 2023",
            endDate: "Jun, 2023",
            aboutCertificate: "<p>Completed certification focused on JavaScript programming, covering both front-end and back-end development concepts and best practices.</p>"
          },
        ],
      },
    ],
  },

  // 30) BLUE AND WHITE CLEAN PROFESSIONAL RESUME
  {
    id: "BAWCAPR",
    templateImage: "/template-images/blue-and-white-clean-resume.jpg",
    templateName: "ATS Friendly",
    element: Blue_and_White_Clean_and_Professional_Resume,
    userName: "Aria Sterling",
    headingTextFont: "Oswald",
    bodyTextFont: "Inter",
    headingTextColor: "#00366D",
    bodyTextColor: "#333",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "minimal",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Software Developer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BAWCAPR:ABOUT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "About Me",
        summary:
          "A passionate software developer with expertise in building scalable web and mobile applications. Adept at collaborating with teams to create innovative solutions and proficient in modern programming languages and frameworks. Dedicated to continuous learning and problem-solving.",
      },
      {
        sectionId: "BAWCAPR:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: false,
        sectionName: "Contact Me",
        list: [
          {
            listId: "BAWCAPR:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma@gmail.com",
            additionalLink: "",
          },
          {
            listId: "BAWCAPR:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+91-XXXXXXXXXX",
            additionalLink: "",
          },
          {
            listId: "BAWCAPR:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "45 Green Park, Bangalore, Karnataka, India",
            additionalLink: "",
          }
        ]
      },
      {
        sectionId: "BAWCAPR:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BAWCAPR:PROJECTS:1",
            projectName: "CodeSphere",
            startDate: "Sep, 2022",
            endDate: "Dec, 2022",
            aboutProject:
              "Developed a collaborative code editor supporting real-time editing, syntax highlighting, and multi-language support. Integrated WebSockets for seamless collaboration and deployed the project using Docker and AWS.",
          },
        ]
      },
      {
        sectionId: "BAWCAPR:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BAWCAPR:SKILLS:1",
            skillName: "React.js",
          },
          {
            listId: "BAWCAPR:SKILLS:2",
            skillName: "Node.js",
          },
          {
            listId: "BAWCAPR:SKILLS:3",
            skillName: "Python",
          },
          {
            listId: "BAWCAPR:SKILLS:4",
            skillName: "SQL",
          },
          {
            listId: "BAWCAPR:SKILLS:5",
            skillName: "Git & GitHub",
          }
        ]
      },
      {
        sectionId: "BAWCAPR:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BAWCAPR:JOB-EXP:1",
            companyName: "Tech Innovators Inc.",
            jobRole: "Software Developer",
            startDate: "Jan, 2021",
            endDate: "Oct, 2024",
            aboutJob:
              "Developed scalable web applications using JavaScript frameworks. Collaborated with cross-functional teams to deliver software solutions. Optimized database queries to enhance performance and reduced application load times by 20%.",
          }
        ]
      },
      {
        sectionId: "BAWCAPR:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BAWCAPR:EDUCATION:1",
            collegeName: "Indian Institute of Technology, Delhi",
            course: "B.Tech in Computer Science",
            startDate: "Jul, 2016",
            endDate: "Jun, 2020",
            aboutEducation:
              "Studied advanced algorithms, data structures, and computer networks. Worked on projects like a ride-sharing algorithm optimization. Participated in hackathons and won accolades for innovative solutions.",
          }
        ]
      },
      {
        sectionId: "BORDERCRT:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "BORDERCRT:LANGUAGE:1",
            languageName: "English",
            languageProfiency: "Expert"
          },
          {
            listId: "BORDERCRT:LANGUAGE:2",
            languageName: "Hindi",
            languageProfiency: "Intermediate"
          }
        ]
      },
      {
        sectionId: "BORDERCRT:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "BORDERCRT:INTEREST:1",
            interest: "Open Source Contributions"
          },
          {
            listId: "BORDERCRT:INTEREST:2",
            interest: "AI & Machine Learning"
          },
          {
            listId: "BORDERCRT:INTEREST:3",
            interest: "Competitive Coding"
          },
          {
            listId: "BORDERCRT:INTEREST:4",
            interest: "Tech Blogging"
          }
        ]
      },
      {
        sectionId: "BORDERCRT:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "BORDERCRT:CERTIFICATION",
            certificateName: "AWS Certified Developer - Associate",
            instituteName: "Amazon Web Services",
            startDate: "Feb, 2023",
            endDate: "Jul, 2023",
            aboutCertificate: "<p>Achieved expertise in cloud computing, deploying and managing AWS applications, and leveraging AWS tools for software development. This certification demonstrates the ability to design, develop, and deploy cloud-based applications using AWS, with an emphasis on security, scalability, and high availability.</p>"
          }
        ]
      }

    ],
  },

  // 31) ELEGANT RESUME TEMPLATE
  {
    id: "ERT1",
    templateImage: "/template-images/Elegant_Resume_Template.jpg",
    templateName: "Elegant Resume Template",
    element: Elegant_Resume_Template,
    headingTextFont: "Montserrat Alternates",
    bodyTextFont: "Montserrat",
    headingTextColor: "#000",
    bodyTextColor: "#333",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Oliver Greene",
    profileImage: "",
    userJobRole: "Graphic Designer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: "Background_Pattern_4.png",
    },

    AllSections: [
      {
        sectionId: "ERT1:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "Creative and detail-oriented graphic designer with over 5 years of experience. Skilled in branding, typography, and digital design, with a passion for crafting compelling visuals that captivate audiences and drive engagement.",
      },
      {
        sectionId: "ERT1:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact Me",
        list: [
          {
            listId: "ERT1:CONTACT:1",
            iconName: "fa-regular fa-envelope",
            contactName: "sarah.jones@email.com",
          },
          {
            listId: "ERT1:CONTACT:2",
            iconName: "fa-regular fa-phone",
            contactName: "+1 (555) 987-6543",
          },
          {
            listId: "ERT1:CONTACT:3",
            iconName: "fa-regular fa-location-dot",
            contactName: "45 Green Park, Bangalore, Karnataka, India",
            additionalLink: "",
          }
        ],
      },
      {
        sectionId: "ERT1:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "ERT1:PROJECTS:1",
            projectName: "Urban Canvas",
            startDate: "Jan, 2022",
            endDate: "Aug, 2022",
            aboutProject:
              "Developed a dynamic visual identity and marketing materials for a local art collective, emphasizing bold typography and vibrant color schemes to attract a younger audience.",
          },
          {
            listId: "ERT1:PROJECTS:2",
            projectName: "EcoBrand Initiative",
            startDate: "Mar, 2021",
            endDate: "Dec, 2021",
            aboutProject:
              "Created sustainable packaging designs and branding assets for an eco-friendly startup, ensuring alignment with their mission to promote environmental consciousness.",
          },
        ],
      },
      {
        sectionId: "ERT1:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "ERT1:SKILLS:1",
            skillName: "Adobe Photoshop & Illustrator",
          },
          {
            listId: "ERT1:SKILLS:2",
            skillName: "Typography & Layout Design",
          },
          {
            listId: "ERT1:SKILLS:3",
            skillName: "Branding & Visual Identity",
          },
          {
            listId: "ERT1:SKILLS:4",
            skillName: "User Interface (UI) Design",
          }
        ],
      },
      {
        sectionId: "ERT1:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "ERT1:JOB-EXP:1",
            companyName: "Creative Horizon Studio",
            jobRole: "Lead Graphic Designer",
            startDate: "Feb, 2020",
            endDate: "Oct, 2023",
            aboutJob:
              "Directed branding projects for high-profile clients, ensuring cohesive and impactful visual storytelling. Supervised junior designers and managed project timelines to deliver exceptional results.",
          },
          {
            listId: "ERT1:JOB-EXP:2",
            companyName: "Pixel Perfect Agency",
            jobRole: "Junior Graphic Designer",
            startDate: "Jul, 2017",
            endDate: "Jan, 2020",
            aboutJob:
              "Collaborated with senior designers on marketing campaigns and branding projects. Focused on print media, social media graphics, and custom illustrations for various industries.",
          },
        ],
      },
      {
        sectionId: "ERT1:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "ERT1:EDUCATION:1",
            collegeName: "Parsons School of Design",
            course: "B.F.A. in Graphic Design",
            startDate: "Aug, 2013",
            endDate: "May, 2017",
            aboutEducation:
              "Developed a strong foundation in design principles, including color theory, branding, and digital illustration. Participated in multiple design showcases and competitions.",
          },
          {
            listId: "ERT1:EDUCATION:2",
            collegeName: "California Institute of the Arts (CalArts)",
            course: "Online Course in Graphic Design",
            startDate: "Jan, 2019",
            endDate: "Apr, 2019",
            aboutEducation:
              "Completed a comprehensive online program covering typography, visual communication, and design theory to enhance professional design skills.",
          }
        ],
      },
      {
        sectionId: "ERT1:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Languages",
        list: [
          {
            listId: "ERT1:LANGUAGE:1",
            languageName: "English",
            languageProfiency: "Expert",
          },
          {
            listId: "ERT1:LANGUAGE:2",
            languageName: "Spanish",
            languageProfiency: "Intermediate",
          }
        ],
      },
      {
        sectionId: "ERT1:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interests",
        list: [
          {
            listId: "ERT1:INTEREST:1",
            interest: "Branding Exploration",
          },
          {
            listId: "ERT1:INTEREST:2",
            interest: "Typography Design Challenges",
          },
          {
            listId: "ERT1:INTEREST:3",
            interest: "Creative Photography",
          },
          {
            listId: "ERT1:INTEREST:4",
            interest: "Art Exhibitions",
          }
        ],
      },
      {
        sectionId: "ERT1:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certifications",
        list: [
          {
            listId: "ERT1:CERTIFICATION",
            certificateName: "Adobe Certified Expert",
            instituteName: "Adobe",
            startDate: "Jun, 2021",
            endDate: "Dec, 2021",
            aboutCertificate:
              "<p>Achieved certification for advanced proficiency in Adobe Creative Suite, specializing in Photoshop, Illustrator, and InDesign for professional design solutions.</p>",
          },
          {
            listId: "ERT1:CERTIFICATION:2",
            certificateName: "UX Design Professional",
            instituteName: "Interaction Design Foundation",
            startDate: "Jan, 2022",
            endDate: "Jun, 2022",
            aboutCertificate:
              "<p>Gained in-depth knowledge of user-centric design principles, usability testing, and wireframing to enhance digital experiences.</p>",
          }
        ],
      }

    ],
  },

  // 32) BLUE MODERN TEMPLATE
  {
    id: "BLUEMT",
    templateImage: "/template-images/Blue-Modern-Resume-Template.jpg",
    templateName: "HMATSTamplate",
    element: BlueModernTemplate,
    headingTextFont: "Kanit",
    bodyTextFont: "Inter",
    headingTextColor: "#333",
    bodyTextColor: "#000",
    themeColor:'#151515',
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "modern",
    userName: "Sneha Sharma",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Business Development Executive ",
    subCategory: "BDE",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BLUEMT:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Dynamic Business Development Executive with over 5 years of experience in driving revenue growth, forging strategic partnerships, and spearheading market expansion efforts. Adept at creating innovative solutions to achieve business objectives and foster client satisfaction.</p>",
      },
      {
        sectionId: "BLUEMT:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: false,
        sectionName: "Contact Me",
        list: [
          {
            listId: "BLUEMT:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "snehasharma@email.com",
            additionalLink: "",
          },
          {
            listId: "BLUEMT:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+1 (987) 654-3210",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "BLUEMT:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BLUEMT:PROJECTS:1",
            projectName: "Global Sales Expansion",
            startDate: "Mar, 2021",
            endDate: "Feb, 2022",
            aboutProject:
              "<p>Launched a global sales initiative that resulted in a 40% increase in international client acquisitions across Europe and Asia.</p>",
          },
          {
            listId: "BLUEMT:PROJECTS:2",
            projectName: "Revenue Optimization Strategy",
            startDate: "Jan, 2020",
            endDate: "Dec, 2020",
            aboutProject:
              "<p>Designed and implemented a revenue optimization plan, increasing profitability by 30% through better resource allocation and pricing models.</p>",
          },
        ],
      },
      {
        sectionId: "BLUEMT:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BLUEMT:SKILLS:1",
            skillName: "Strategic Planning",
          },
          {
            listId: "BLUEMT:SKILLS:2",
            skillName: "Partnership Development",
          },
          {
            listId: "BLUEMT:SKILLS:3",
            skillName: "Client Acquisition",
          },
          {
            listId: "BLUEMT:SKILLS:4",
            skillName: "Market Trend Analysis",
          },
          {
            listId: "BLUEMT:SKILLS:5",
            skillName: "Team Leadership",
          },
        ],
      },
      {
        sectionId: "BLUEMT:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BLUEMT:JOB-EXP:1",
            companyName: "Global Enterprises Ltd",
            jobRole: "Senior Business Development Executive",
            startDate: "May, 2022",
            endDate: "Nov, 2024",
            aboutJob:
              "<p><li>Developed and executed strategic plans to secure high-value clients, achieving a 40% boost in company revenue.Organized and conducted 20+ corporate presentations to C-level executives, ensuring a consistent sales pipeline</li><li>Worked closely with product development teams to align offerings with client needs, leading to a 25% improvement in customer satisfaction scores.</li></p>",
          },
          {
            listId: "BLUEMT:JOB-EXP:2",
            companyName: "Innovative Solutions Inc.",
            jobRole: "Business Development Executive",
            startDate: "Jan, 2020",
            endDate: "Apr, 2022",
            aboutJob:
              "<p><li>Managed end-to-end sales cycles for B2B clients, achieving 120% of the annual sales targets consistently for two years.</li><li>Expanded company presence into two new market regions, leading to a 15% increase in market share.</li><li>Coordinated with cross-functional teams to launch a key account management initiative, driving a 35% increase in customer retention rates.</li></p>",
          },
        ],
      },
      {
        sectionId: "BLUEMT:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BLUEMT:EDUCATION:1",
            collegeName: "Harvard Extension School",
            course: "Master of Business Administration",
            startDate: "Sep, 2018",
            endDate: "May, 2020",
            aboutEducation:
              "<p>Graduated with an MBA specializing in Business Strategy and Leadership, completing a capstone project on market entry strategies.</p>",
          },
          {
            listId: "BLUEMT:EDUCATION:2",
            collegeName: "University of California, Berkeley",
            course: "B.S. in Business Administration",
            startDate: "Aug, 2013",
            endDate: "May, 2017",
            aboutEducation:
              "<p>Focused on marketing and global business trends, graduating with honors.</p>",
          },
        ],
      },
      {
        sectionId: "BLUEMT:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Language",
        list: [
          {
            listId: "BLUEMT:LANGUAGE:1",
            languageName: "Spanish",
            languageProfiency: "intermediate",
          },
          {
            listId: "BLUEMT:LANGUAGE:2",
            languageName: "English",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "BLUEMT:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interest",
        list: [
          {
            listId: "BLUEMT:INTEREST:1",
            interest: "Market Analytics",
          },
          {
            listId: "BLUEMT:INTEREST:2",
            interest: "Public Speaking",
          },
          {
            listId: "BLUEMT:INTEREST:3",
            interest: "Adventure Travel",
          },
        ],
      },
      {
        sectionId: "BLUEMT:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certification",
        list: [
          {
            listId: "BLUEMT:CERTIFICATION:1",
            certificateName: "Certified Business Development Professional",
            instituteName: "Institute of Business Development",
            startDate: "Feb, 21",
            endDate: "Jun, 21",
            aboutCertificate:
              "<p>Completed an intensive program covering advanced business development strategies, market analysis, and client negotiation techniques. Acquired skills to identify high-value opportunities, build strong client relationships, and drive sustainable revenue growth. Successfully implemented a capstone project showcasing a mock market expansion plan.</p>",
          },
        ],
      }
    ],

  },

  // 33) GRAY THEME TEMPLATE
  {
    id: "GRTHTEM",
    templateImage: "template-images/GTT-resume-template.jpg",
    templateName: "Gray Theme Template",
    element: GrayThemeTemplate,
    userName: "Priya Sharma",
    headingTextFont: "Poppins",
    bodyTextFont: "Mulish",
    headingTextColor: "#333",
    themeColor: "#737373",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "ats-friendly",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Software Developer",
    subCategory: "SoftwareDeveloper",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "GRTHTEM:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Profile",
        summary:
          "<p>Dedicated Software Developer with 5+ years of experience in building and maintaining robust software solutions. Proficient in modern programming languages and frameworks, with a focus on scalability, efficiency, and user satisfaction. Experienced in Agile environments with a proven ability to deliver quality software on tight deadlines.</p>",
      },
      {
        sectionId: "GRTHTEM:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Contact Me",
        list: [
          {
            listId: "GRTHTEM:CONTACT:1",
            iconName: "fa-solid fa-phone",
            contactName: "(123) 456-7890",
            additionalLink: "",
          },
          {
            listId: "GRTHTEM:CONTACT:2",
            iconName: "fa-solid fa-location-dot",
            contactName: "456 Software Drive, Tech City, USA",
            additionalLink: "",
          },
          {
            listId: "GRTHTEM:CONTACT:3",
            iconName: "fa-solid fa-envelope",
            contactName: "alice.developer@email.com",
            additionalLink: "",
          },
          {
            listId: "GRTHTEM:CONTACT:4",
            iconName: "fa-solid fa-link",
            contactName: "alicesoftwaredev.com",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "GRTHTEM:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "GRTHTEM:PROJECTS:1",
            projectName: "Inventory Management System",
            startDate: "Jan, 2023",
            endDate: "Aug, 2023",
            aboutProject:
              "<p>Developed a cross-platform inventory management system using Python and Django. Features included real-time stock tracking, sales reports, and automated reorder alerts, integrated with a MySQL database.</p>",
          },
          {
            listId: "GRTHTEM:PROJECTS:2",
            projectName: "Mobile Banking App",
            startDate: "Jun, 2022",
            endDate: "Dec, 2022",
            aboutProject:
              "<p>Designed and implemented a secure mobile banking application with Kotlin for Android and Swift for iOS. Added features like biometric authentication and live transaction notifications.</p>",
          },
        ],
      },
      {
        sectionId: "GRTHTEM:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "GRTHTEM:SKILLS:1",
            skillName: "Python",
          },
          {
            listId: "GRTHTEM:SKILLS:2",
            skillName: "Java",
          },
          {
            listId: "GRTHTEM:SKILLS:3",
            skillName: "Kotlin",
          },
          {
            listId: "GRTHTEM:SKILLS:4",
            skillName: "SQL",
          },
          {
            listId: "GRTHTEM:SKILLS:5",
            skillName: "Docker",
          },
        ],
      },
      {
        sectionId: "GRTHTEM:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "GRTHTEM:JOB-EXP:1",
            companyName: "Innovative Solutions Ltd., Dev Valley, DV",
            jobRole: "Software Developer",
            startDate: "Feb, 2020",
            endDate: "Oct, 2024",
            aboutJob:
              "<p>Developing and maintaining scalable web applications using React, Node.js, and MongoDB. Led the migration of legacy systems to modern microservices architecture, resulting in a 30% performance improvement. Collaborated with cross-functional teams to design user-friendly interfaces and ensure responsive design across all platforms. Implemented CI/CD pipelines using Jenkins, streamlining deployment processes. Conducted regular code reviews and mentoring sessions to improve team efficiency and maintain coding standards.</p>",
          },
          {
            listId: "GRTHTEM:JOB-EXP:2",
            companyName: "NextGen Tech, AppCity, AC",
            jobRole: "Junior Software Developer",
            startDate: "Jul, 2018",
            endDate: "Jan, 2020",
            aboutJob:
              "<p>Designed and implemented visually appealing and intuitive user interfaces using HTML, CSS, and JavaScript. Played a pivotal role in launching a customer management portal, enhancing client retention rates by 25%. Optimized website performance through modern techniques like lazy loading and code splitting. Collaborated with backend developers to integrate RESTful APIs and ensure seamless data flow. Conducted user testing sessions to gather feedback and improve usability, boosting customer satisfaction scores significantly.</p>",
          },
        ],
      },
      {
        sectionId: "BLUEMT:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "33dd34",
            collegeName: "TechSphere Academy",
            course: "Full Stack Software Development Bootcamp",
            startDate: "Aug, 2017",
            endDate: "Dec, 2017",
            aboutEducation: "<p>Completed an immersive program that emphasized core and advanced topics in software development, including agile methodologies and DevOps practices. Worked on real-world projects using modern frameworks like React, Node.js, and Docker. Gained expertise in integrating REST APIs and developing scalable applications.</p>"
          },
          {
            listId: "33dd35",
            collegeName: "Global University",
            course: "Bachelor of Technology in Computer Science",
            startDate: "Jul, 2013",
            endDate: "May, 2017",
            aboutEducation: "<p>Acquired a strong foundation in programming, algorithms, and system design. Led a team project to develop a library management system using Java. Achieved top marks in courses like Software Engineering, Artificial Intelligence, and Data Structures. Participated in international hackathons and presented research papers on machine learning.</p>"
          },
        ],
      },
      {
        sectionId: "GRTHTEM:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Language",
        list: [
          {
            listId: "GRTHTEM:LANGUAGE:1",
            languageName: "English",
            languageProfiency: "Expert",
          },
          {
            listId: "GRTHTEM:LANGUAGE:2",
            languageName: "Spanish",
            languageProfiency: "Beginner",
          },
        ],
      },
      {
        sectionId: "GRTHTEM:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interest",
        list: [
          {
            listId: "GRTHTEM:INTEREST:1",
            interest: "Algorithm Optimization",
          },
          {
            listId: "GRTHTEM:INTEREST:2",
            interest: "Tech Meetups",
          },
          {
            listId: "GRTHTEM:INTEREST:3",
            interest: "Machine Learning",
          },
          {
            listId: "GRTHTEM:INTEREST:4",
            interest: "Artificial Intelligence"
          },
          {
            listId: "GRTHTEM:INTEREST:5",
            interest: "Blockchain Technology"
          },
          {
            listId: "GRTHTEM:INTEREST:6",
            interest: "Competitive Coding"
          }
        ],
      },
      {
        sectionId: "GRTHTEM:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certification",
        list: [
          {
            listId: "GRTHTEM:CERTIFICATION:1",
            certificateName: "AWS Certified Solutions Architect",
            instituteName: "Amazon Web Services",
            startDate: "May, 2021",
            endDate: "Aug, 2022",
            aboutCertificate:
              "<p>Earned certification for designing secure, scalable cloud applications using AWS services. Completed hands-on labs and project scenarios.</p>",
          },
          {
            listId: "GRTHTEM:CERTIFICATION:2",
            certificateName: "Oracle Certified Java Programmer",
            instituteName: "Oracle",
            startDate: "Feb, 2019",
            endDate: "Mar, 2020",
            aboutCertificate:
              "<p>Validated expertise in Java SE programming, covering object-oriented concepts, API design, and Java language fundamentals.</p>",
          },
        ],
      },

    ]

  },

  // 34) FTHEME TEMPLATE
  {
    id: "FTHTEM",
    templateImage: "/template-images/F_Theme_Template.jpg",
    templateName: "F Theme Template",
    element: FThemeTemplate,
    userName: "Emily Smith",
    headingTextFont: "Poppins",
    bodyTextFont: "Mulish",
    headingTextColor: "#333",
    bodyTextColor: "#000",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "ats-friendly",
    profileImage: "",
    userJobRole: "Web Developer",
    subCategory: "softwareDeveloper",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "FTHTEM",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary:
          "<p>Results-driven Software Developer with over 5 years of experience in building scalable and efficient software applications. Proficient in designing user-friendly interfaces and implementing robust backend systems. Adept at working collaboratively in agile environments to meet project goals and client expectations.</p>",
      },
      {
        sectionId: "FTHTEM:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Contact Me",
        list: [
          {
            listId: "FTHTEM:CONTACT:1",
            iconName: "fa-solid fa-phone",
            contactName: "(123) 456-7890",
            additionalLink: "",
          },
          {
            listId: "FTHTEM:CONTACT:2",
            iconName: "fa-solid fa-location-dot",
            contactName: "789 Innovation Drive, Tech Valley",
            additionalLink: "",
          },
          {
            listId: "FTHTEM:CONTACT:3",
            iconName: "fa-solid fa-envelope",
            contactName: "sarah.dev@example.com",
            additionalLink: "",
          },
        ],
      },
      {
        sectionId: "FTHTEM:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "FTHTEM:PROJECT:1",
            projectName: "E-Commerce Platform",
            startDate: "Jan, 2023",
            endDate: "Dec, 2023",
            aboutProject:
              "<p>Developed a full-stack e-commerce platform using React and Node.js. Integrated payment gateways, implemented product search functionality, and optimized the website for high traffic. Used MongoDB for data storage and deployed the application on Azure.</p>",
          },
          {
            listId: "FTHTEM:PROJECT:2",
            projectName: "Healthcare Management System",
            startDate: "Jul, 2021",
            endDate: "May, 2022",
            aboutProject:
              "<p>Designed and built a healthcare management system using Python and Flask. Features included patient record management, appointment scheduling, and secure data handling. Deployed the solution on AWS and conducted load testing to ensure reliability.</p>",
          },
        ],
      },
      {
        sectionId: "FTHTEM:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "FTHTEM:SKILLS:1",
            skillName: "Python",
          },
          {
            listId: "FTHTEM:SKILLS:2",
            skillName: "React.js",
          },
          {
            listId: "FTHTEM:SKILLS:3",
            skillName: "Node.js",
          },
          {
            listId: "FTHTEM:SKILLS:4",
            skillName: "SQL",
          },
          {
            listId: "FTHTEM:SKILLS:5",
            skillName: "MongoDB",
          },
          {
            listId: "FTHTEM:SKILLS:6",
            skillName: "Django",
          },
          {
            listId: "FTHTEM:SKILLS:7",
            skillName: "Azure",
          },
          {
            listId: "FTHTEM:SKILLS:8",
            skillName: "RESTful APIs",
          },
          {
            listId: "FTHTEM:SKILLS:9",
            skillName: "Git and Bitbucket",
          },
          {
            listId: "FTHTEM:SKILLS:10",
            skillName: "Scrum",
          },
        ],
      },
      {
        sectionId: "FTHTEM:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "FTHTEM:JOB-EXP:1",
            companyName: "Tech Innovators, Software City",
            jobRole: "Senior Software Developer",
            startDate: "Feb, 2020",
            endDate: "Oct, 2024",
            aboutJob:
              "<p>Led the development of cloud-based applications, delivering scalable solutions to complex business problems. Enhanced software performance by 30% through code refactoring and system optimization. Collaborated with stakeholders to define project requirements and timelines.</p>",
          },
          {
            listId: "FTHTEM:JOB-EXP:2",
            companyName: "Digital Coders, Innovation Hub",
            jobRole: "Software Developer",
            startDate: "Aug, 2017",
            endDate: "Jan, 2020",
            aboutJob:
              "<p>Worked on web and mobile application development, focusing on robust back-end systems and responsive front-end interfaces. Streamlined deployment processes by introducing CI/CD pipelines and contributed to team-wide knowledge sharing sessions.</p>",
          },
        ],
      },
      {
        sectionId: "FTHTEM:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "FTHTEM:EDUCATION:1",
            collegeName: "Tech University",
            course: "Master's in Computer Science",
            startDate: "Sep, 2015",
            endDate: "May, 2017",
            aboutEducation:
              "<p>Specialized in software engineering and machine learning. Completed capstone project on predictive analytics, which received university recognition for innovation and practicality.</p>",
          },
          {
            listId: "FTHTEM:EDUCATION:2",
            collegeName: "National Institute of Technology",
            course: "Bachelor's in Computer Science",
            startDate: "Jul, 2011",
            endDate: "Apr, 2015",
            aboutEducation:
              "<p>Studied core computer science concepts, including algorithms, data structures, and system design. Actively participated in hackathons and secured top positions in coding contests.</p>",
          },
        ],
      },
      {
        sectionId: "FTHTEM:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Language",
        list: [
          {
            listId: "FTHTEM:LANGUAGE:1",
            languageName: "English",
            languageProfiency: "Intermediate",
          },
          {
            listId: "FTHTEM:LANGUAGE:2",
            languageName: "Spanish",
            languageProfiency: "Intermediate",
          },
          {
            listId: "FTHTEM:LANGUAGE:3",
            languageName: "Hindi",
            languageProfiency: "Expert",
          },
        ],
      },
      {
        sectionId: "FTHTEM:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interest",
        list: [
          {
            listId: "FTHTEM:INTEREST:1",
            interest: "Machine Learning",
          },
          {
            listId: "FTHTEM:INTEREST:2",
            interest: "Cloud Computing",
          },
          {
            listId: "FTHTEM:INTEREST:3",
            interest: "Tech Mentoring",
          },
          {
            listId: "FTHTEM:INTEREST:4",
            interest: "Open Source Contribution",
          },
          {
            listId: "FTHTEM:INTEREST:5",
            interest: "Game Development",
          },
          {
            listId: "FTHTEM:INTEREST:6",
            interest: "Cybersecurity Research",
          },
          {
            listId: "FTHTEM:INTEREST:7",
            interest: "IoT (Internet of Things)",
          },
        ],
      },
      {
        sectionId: "FTHTEM:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certification",
        list: [
          {
            listId: "FTHTEM:CERTIFICATION:1",
            certificateName: "AWS Certified Solutions Architect",
            instituteName: "Amazon Web Services",
            startDate: "Jun, 2022",
            endDate: "Jun, 2022",
            aboutCertificate:
              "<p>Achieved certification in designing and deploying scalable systems on AWS. This included proficiency in creating cost-effective, highly available, and fault-tolerant architectures for cloud-based applications. Mastered key AWS services such as EC2, S3, RDS, and Lambda, with hands-on experience in implementing disaster recovery strategies and security measures.</p>",
          },
          {
            listId: "FTHTEM:CERTIFICATION:2",
            certificateName: "Google Professional Cloud Architect",
            instituteName: "Google Cloud Platform",
            startDate: "Jan, 2023",
            endDate: "Jan, 2023",
            aboutCertificate:
              "<p>Validated skills in designing and managing secure and scalable cloud architectures on Google Cloud Platform. The certification emphasized expertise in deploying compute, storage, and networking solutions while integrating advanced tools such as Kubernetes Engine, BigQuery, and Cloud Functions. Demonstrated a strong understanding of DevOps practices and optimizing application performance on GCP.</p>",
          }
        ]
      }
    ],
  },

  // 35) BEIGE TEMPLATE
  {
    id: "BEITEM",
    templateName: "Beige Resume Template",
    templateImage: "/template-images/Beige-Resume-Template.jpg",
    element: BeigeTemplate,
    headingTextFont: "Montserrat Alternates",
    bodyTextFont: "Montserrat",
    headingTextColor: "#000",
    bodyTextColor: "#333",
    themeColor: "#f36e45",
    headingTextSize: 14,
    bodyTextSize: 10,
    isPersonaldetailschange: false,
    category: "creative",
    subCategory: "graphic-designer",
    userName: "Oliver Greene",
    profileImage:
      "/images/User_Default_Image.png",
    userJobRole: "Graphic Designer",
    isQRCode: false,
    qrPosition: {
      vertical: "bottom",
      horizontal: "left",
      X: 10,
      Y: 10
    },
    resumeATS: "",
    initialResumeData: "",
    initialSuggestion: "",
    resumeStyle: {
      backgroundPattern: false,
    },

    AllSections: [
      {
        sectionId: "BEITEM:SUMMARY",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Summary",
        summary: "<p>Experienced and dynamic graphic designer with a passion for creating visually captivating designs. Specializes in branding, print design, and digital media. Focused on delivering innovative design solutions that effectively communicate brand messages and engage audiences. Proficient in modern design tools and committed to continuous learning to stay ahead of design trends.</p>"
      },
      {
        sectionId: "BEITEM:CONTACT",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionVisible: true,
        sectionName: "Contact Me",
        list: [
          {
            listId: "BEITEM:CONTACT:1",
            iconName: "fa-solid fa-envelope",
            contactName: "john.doe@email.com",
            additionalLink: ""
          },
          {
            listId: "BEITEM:CONTACT:2",
            iconName: "fa-solid fa-phone",
            contactName: "+1 (555) 678-1234",
            additionalLink: ""
          },
          {
            listId: "BEITEM:CONTACT:3",
            iconName: "fa-solid fa-location-dot",
            contactName: "789 Innovation Drive, Tech Valley",
            additionalLink: "",
          },
        ]
      },
      {
        sectionId: "BEITEM:PROJECTS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Projects",
        list: [
          {
            listId: "BEITEM:PROJECTS:1",
            projectName: "Innovative Logo Redesign",
            startDate: "Jan, 2022",
            endDate: "May, 2022",
            aboutProject: "<p>Redesigned the logo for a leading international tech company to better align with modern design trends and brand vision. Focused on creating a more minimalist and dynamic design. This project required close collaboration with the client to ensure the logo reflected their evolving brand identity and resonated with their global audience.</p>"
          },
          {
            listId: "BEITEM:PROJECTS:2",
            projectName: "Urban Identity",
            startDate: "Apr, 2021",
            endDate: "Jul, 2021",
            aboutProject: "<p>Developed a comprehensive branding identity for a new urban clothing brand, focusing on a bold and edgy aesthetic to appeal to a younger demographic. This project included logo design, product packaging, and online assets. The project’s success led to the brand securing major retail partnerships and gaining significant traction in the market.</p>"
          }
        ]
      },
      {
        sectionId: "BEITEM:SKILLS",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Skills",
        list: [
          {
            listId: "BEITEM:SKILLS:1",
            skillName: "Illustrator"
          },
          {
            listId: "BEITEM:SKILLS:2",
            skillName: "After Effects"
          },
          {
            listId: "BEITEM:SKILLS:3",
            skillName: "InDesign"
          },
          {
            listId: "BEITEM:SKILLS:4",
            skillName: "Sketch"
          },
          {
            listId: "BEITEM:SKILLS:5",
            skillName: "Figma"
          }
        ]
      },
      {
        sectionId: "BEITEM:JOB-EXP",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Job Experience",
        list: [
          {
            listId: "BEITEM:JOB-EXP:1",
            companyName: "Design Studio X",
            jobRole: "Lead Graphic Designer",
            startDate: "Jul, 2020",
            endDate: "Present",
            aboutJob: "<p>Currently working as the Lead Graphic Designer at Design Studio X, overseeing a team of designers. Responsible for driving creative direction, client presentations, and final project execution. Successfully led the design of high-profile campaigns for top-tier clients in tech and fashion industries. I've also introduced new design strategies that increased team efficiency and client satisfaction by 30%.</p>"
          }
        ]
      },
      {
        sectionId: "BEITEM:EDUCATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Education",
        list: [
          {
            listId: "BEITEM:EDUCATION:1",
            collegeName: "Rhode Island School of Design",
            course: "B.F.A. Graphic Design",
            startDate: "Sep, 2016",
            endDate: "May, 2020",
            aboutEducation: "<p>Bachelor of Fine Arts in Graphic Design from the Rhode Island School of Design. Gained in-depth knowledge of design principles, typography, visual communication, and color theory, alongside hands-on experience with industry-standard software. Also participated in various design exhibitions, showcasing student projects.</p>"
          },
          {
            listId: "BEITEM:EDUCATION:2",
            collegeName: "California Institute of the Arts",
            course: "M.F.A. Visual Arts",
            startDate: "Sep, 2021",
            endDate: "May, 2023",
            aboutEducation: "<p>Master of Fine Arts in Visual Arts from the California Institute of the Arts. Focused on advanced design techniques, experimental typography, and digital media. The program helped develop my creative process and refined my ability to think outside of the box when approaching design challenges.</p>"
          }
        ]
      },
      {
        sectionId: "BEITEM:LANGUAGE",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Language",
        list: [
          {
            listId: "BEITEM:LANGUAGE:1",
            languageName: "English",
            languageProfiency: "Expert"
          },
          {
            listId: "BEITEM:LANGUAGE:2",
            languageName: "Spanish",
            languageProfiency: "Expert"
          }
        ]
      },
      {
        sectionId: "BEITEM:INTEREST",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Interest",
        list: [
          {
            listId: "FTHTEM:INTEREST:1",
            interest: "Photography"
          },
          {
            listId: "FTHTEM:INTEREST:2",
            interest: "Travel"
          },
          {
            listId: "FTHTEM:INTEREST:3",
            interest: "Fashion Design"
          },
          {
            listId: "FTHTEM:INTEREST:4",
            interest: "Typography"
          }
        ]
      },
      {
        sectionId: "BEITEM:CERTIFICATION",
        isSection: true,
        isSectionDetailsChanges: false,
        sectionName: "Certification",
        list: [
          {
            listId: "BEITEM:CERTIFICATION:1",
            certificateName: "Adobe Certified Expert",
            instituteName: "Adobe",
            startDate: "Aug, 2019",
            endDate: "Aug, 2019",
            aboutCertificate: "<p>Certified Adobe Expert in Photoshop, Illustrator, and InDesign, demonstrating advanced skills in graphic design and software proficiency.</p>"
          }
          // ,
          // {
          //   listId: "BEITEM:CERTIFICATION:2",
          //   certificateName: "UX/UI Design Professional",
          //   instituteName: "Coursera",
          //   startDate: "Jan, 2021",
          //   endDate: "Jan, 2021",
          //   aboutCertificate: "<p>Completed a professional UX/UI Design program, gaining expertise in user-centered design principles and best practices in web and mobile design.</p>"
          // }
        ]
      }
    ],
  },
];
