import React, { useContext, useEffect, useRef, useState } from "react";
import "./QRPopup.css";
import GlobalContext from "../context/GlobalContext";
import Toggle from "./Toggle";
export default function QRPopup({ cancel }) {
    const { currentTemplateData, setCurrentTemplateData, liveTemplateURL } = useContext(GlobalContext);
    const qrPopRef = useRef();
    const [copied, setCopied] = useState(false);
    const { qrPosition, isQRCode } = currentTemplateData;

    const toggle = currentTemplateData.isQRCode;
    const handleToggleChange = () => {
        setCurrentTemplateData({ ...currentTemplateData, isQRCode: !isQRCode });
        setCurrentTemplateData({ ...currentTemplateData, isQRCode: !isQRCode });
    };
    const CopyURL = () => {
        navigator.clipboard.writeText(liveTemplateURL)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Hide message after 2 seconds
            })
            .catch(err => {
                console.error("Failed to copy text: ", err);
            });
    };
    const ClosePopup = (e) => {
        if (qrPopRef.current.contains(e.target)) {
            return;
        }
        cancel();
    };

    const ChangePositionType = (dirY, dirX) => {
        setCurrentTemplateData({ ...currentTemplateData, qrPosition: { ...qrPosition, vertical: dirY, horizontal: dirX } });
    }

    const ChangeRange = (e) => {
        const { name, value } = e.target;
        setCurrentTemplateData({ ...currentTemplateData, qrPosition: { ...qrPosition, [name]: parseInt(value) } });
    }

    const IncreasePos = (pos, value) => {
        if (value >= 400) return;
        setCurrentTemplateData({ ...currentTemplateData, qrPosition: { ...qrPosition, [pos]: value + 1 } });
    }

    const DecreasePos = (pos, value) => {
        if (value <= 0) return;
        setCurrentTemplateData({ ...currentTemplateData, qrPosition: { ...qrPosition, [pos]: value - 1 } });
    }

    useEffect(() => {
        document.addEventListener("click", ClosePopup, true);
        return () => {
            document.removeEventListener("click", ClosePopup, true);
        };
    }, []);
    return (
        <div className="common-pop-box qr-code-popup" ref={qrPopRef}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={"none"} className="cancel-btn" onClick={cancel}>
                <path d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <h2>QR Code</h2>
            <h4>Add a QR code to your resume for easy access anywhere</h4>
            <Toggle toggle={toggle} handleToggleChange={handleToggleChange} />
            <h4>Live URL</h4>
            <div className="copy-button" style={isQRCode ? { marginBottom: "20px" } : {}}>
                <a href={liveTemplateURL} target='_blank' rel="noopener noreferrer">{liveTemplateURL}</a>
                <i onClick={CopyURL} title='Copy URL' style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={18} height={18} color={"#5A4ABD"} fill={"none"}>
                        <path d="M9 15C9 12.1716 9 10.7574 9.87868 9.87868C10.7574 9 12.1716 9 15 9L16 9C18.8284 9 20.2426 9 21.1213 9.87868C22 10.7574 22 12.1716 22 15V16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15C12.1716 22 10.7574 22 9.87868 21.1213C9 20.2426 9 18.8284 9 16L9 15Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16.9999 9C16.9975 6.04291 16.9528 4.51121 16.092 3.46243C15.9258 3.25989 15.7401 3.07418 15.5376 2.90796C14.4312 2 12.7875 2 9.5 2C6.21252 2 4.56878 2 3.46243 2.90796C3.25989 3.07417 3.07418 3.25989 2.90796 3.46243C2 4.56878 2 6.21252 2 9.5C2 12.7875 2 14.4312 2.90796 15.5376C3.07417 15.7401 3.25989 15.9258 3.46243 16.092C4.51121 16.9528 6.04291 16.9975 9 16.9999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </i>
                {copied && <div className="copied-message">Copied !</div>}
            </div>

            {
                isQRCode &&
                <>
                    <h4>Position</h4>
                    <div className="qr-position">
                        <div className="cv-qr-poss">
                            <div className="qr-pos qr-top-left" style={qrPosition.vertical === "top" && qrPosition.horizontal === "left" ? { borderColor: "#5a4abd" } : {}} onClick={() => ChangePositionType("top", "left")}></div>
                            <div className="qr-pos qr-top-right" style={qrPosition.vertical === "top" && qrPosition.horizontal === "right" ? { borderColor: "#5a4abd" } : {}} onClick={() => ChangePositionType("top", "right")}></div>
                            <div className="qr-pos qr-bottom-left" style={qrPosition.vertical === "bottom" && qrPosition.horizontal === "left" ? { borderColor: "#5a4abd" } : {}} onClick={() => ChangePositionType("bottom", "left")}></div>
                            <div className="qr-pos qr-bottom-right" style={qrPosition.vertical === "bottom" && qrPosition.horizontal === "right" ? { borderColor: "#5a4abd" } : {}} onClick={() => ChangePositionType("bottom", "right")}></div>
                        </div>
                        <div className="controls">
                            <p>Horizontal</p>
                            <div className="range">
                                <i className="fa-solid fa-minus" style={qrPosition.X <= 0 ? { cursor: "not-allowed" } : {}} onClick={() => DecreasePos("X", qrPosition.X)} />
                                <input type="range" name="X" value={qrPosition.X} onChange={ChangeRange} min={"0"} max={"400"} />
                                <i className="fa-solid fa-plus" style={qrPosition.X >= 400 ? { cursor: "not-allowed" } : {}} onClick={() => IncreasePos("X", qrPosition.X)} />
                            </div>
                            <p style={{ textAlign: "center", marginTop: "5px" }}>{qrPosition.X} px</p>
                            <p>Vertical</p>
                            <div className="range">
                                <i className="fa-solid fa-minus" style={qrPosition.Y <= 0 ? { cursor: "not-allowed" } : {}} onClick={() => DecreasePos("Y", qrPosition.Y)} />
                                <input type="range" name="Y" value={qrPosition.Y} onChange={ChangeRange} min={"0"} max={"400"} />
                                <i className="fa-solid fa-plus" style={qrPosition.Y >= 400 ? { cursor: "not-allowed" } : {}} onClick={() => IncreasePos("Y", qrPosition.Y)} />
                            </div>
                            <p style={{ textAlign: "center", marginTop: "5px" }}>{qrPosition.Y} px</p>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}