import React, { useContext, useEffect, useRef, useState } from "react";
import "./SuggestionPopup.css";
import GlobalContext from "../context/GlobalContext";

export default function SuggestionPopup({ PopupId, PopSet, cancel, listArray }) {

    const [noResults, setNoResults] = useState(false);
    const { ChangeListValue } = useContext(GlobalContext)
    const popupSuggestRef = useRef();

    const [filteredSuggestName, setFilteredSuggestName] = useState(listArray);

    //DEBOUNCING SEARCHING
    useEffect(() => {
        const handler = setTimeout(() => {
            if (PopSet) {
                const matches = listArray.filter(element =>
                    element.listName.toLowerCase().includes(PopSet.toLowerCase())
                );

                setFilteredSuggestName(matches);
                setNoResults(matches.length === 0);
            } else {
                setFilteredSuggestName(listArray);
                setNoResults(false);
            }
        }, 0); // 2 seconds debounce

        return () => {
            clearTimeout(handler); // Cleanup on unmount or interest change
        };
    }, [PopSet]);

    // CLOSE POPUP BY USEREF
    const ClosePopup = (e) => {
        if (popupSuggestRef.current.contains(e.target)) {
            return;
        }
        cancel();
    };


    useEffect(() => {
        document.addEventListener("click", ClosePopup, true);
        return () => {
            document.removeEventListener("click", ClosePopup, true);
        };
    }, []);

    return (
        <div className="suggest-popup common-pop-box" ref={popupSuggestRef}>
            {noResults ? (
                <h2>No results found</h2>
            ) : (
                filteredSuggestName.map(({ id, listName }) => (
                    <li onClick={
                        () => {
                            ChangeListValue({ target: { name: "interest", value: listName } }, 7, PopupId);
                            ChangeListValue({ target: { name: "skillName", value: listName } }, 3, PopupId);
                            cancel();
                        }
                    } className="each-form" key={id}>{listName}</li>
                ))
            )}
        </div>
    )
}