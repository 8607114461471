import React, { useContext } from "react";
import "./BeigeTemplate.css";
import { QRCodeCanvas } from "qrcode.react";
import GlobalContext from "../../context/GlobalContext";

const BeigeTemplate = ({ resumeData }) => {
  const { AllSections, isQRCode ,qrPosition} = resumeData;
  const { vertical, horizontal, X, Y } = qrPosition;
  const { liveTemplateURL } = useContext(GlobalContext);

  const themeStyle = { backgroundColor: resumeData.themeColor }

  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    // color: resumeData.headingTextColor,
  }
  const { backgroundPattern } = resumeData.resumeStyle;

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    // color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`,
    lineHeight: `${resumeData.bodyTextSize + 8}px`

  }
  const boldParaStyleCss = {
    ...paraStyleCSS, fontSize: `${resumeData.bodyTextSize + 1}px`, fontWeight: '600',textTransform:'uppercase'

  }

  const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

  return (
    <div className="Beige-resume-template" style={{ backgroundImage: `url("/background-pattern/${backgroundPattern}.png")` }}>
    {
                isQRCode && <div className="resume-qr-code" style={{ [vertical]: `${Y}px`, [horizontal]: `${X}px`}}>
                    <QRCodeCanvas value={liveTemplateURL} size={"50"} />
                </div>
            }
      <div className="Beige-a-Template">
        <div className="Beige-section Beige-name">
          <div className="Beige-name-data personal-edit">
            <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
            <h4 style={{ ...headingStyleCSS}}>{resumeData.userJobRole}</h4>
          </div>
        </div>
        {/* <img className="design2" src={design2} alt="" style={{width:"50px" ,height:"50px"}} /> */}


         {/* <!--CONTACT SECTION--> */}
         <div className="Beige-section Beige-contact-me">
  {resumeData.AllSections[1].isSection && (
    <div className="Beige-contact-sec forHeading-beige contact-edit">
      <h2 style={combineHeadingStyle}>{resumeData.AllSections[1].sectionName}</h2>
      <div className="Beige-contact-list">
        {resumeData.AllSections[1].list.map((element) => {
          const { listId, iconName, contactName, additionalLink } = element;

          const handleItemClick = (e) => {
            if (!additionalLink) {
              e.preventDefault(); 
            }
          };
          return (
            <div
              key={listId}
              className="Beige-contact"
              style={{ cursor: 'pointer' }}
              onClick={handleItemClick}
            >
              {additionalLink ? (
                <a
                  href={additionalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'flex', alignItems: 'center', color: 'inherit', fontSize:`${resumeData.bodyTextSize}px`, fontFamily: 'body-font',width:'100%'}}
                >
                  <i className={iconName} style={{fontSize:`${resumeData.bodyTextSize}px`}}></i>
                  <p style={{fontSize:`${resumeData.bodyTextSize}px` ,...paraStyleCSS}}>{contactName}</p>
                </a>
              ) : (
                <>
                  <i className={iconName} style={{fontSize:`${resumeData.bodyTextSize}px`}}></i>
                  <p style={{ fontFamily: 'body-font', ...paraStyleCSS }}>{contactName}</p>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  )}
</div>
</div>  


      {/* <!--ABOUT SECTION--> */}
      {
        resumeData.AllSections[0].isSection &&
        <div className="Beige-about-blank-div">
          <div className="Beige-section Beige-about-me" style={themeStyle}>
            <div className=" summary-edit" style={{ paddingLeft: '1rem',minWidth:'77%' }}>
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[0].sectionName}</h2>
              <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />
            </div>
            <div
              className="Beige-profile-image"
              style={{
                backgroundImage: `url(${resumeData.profileImage})`,
              }}
            ></div>
          </div>

        </div>
      }



      <div className="Beige-b-Template">
        <div className="Beige-lower-left">
          {/* <!--JOB SECTION--> */}
          {
            resumeData.AllSections[4].isSection &&
            <div className="Beige-section-job job-exp-edit">
              <div className="Beinge-sec-job-exp">
                <div className="Beige-sec-job-heading forHeading-beige">
                  <h2 style={{...combineHeadingStyle,marginTop:'0px'}}>{resumeData.AllSections[4].sectionName}</h2>
                </div>
                <div className="Beige-sec-job-detail">
                  {resumeData.AllSections[4].list.map((element, index) => {
                    const { companyName, jobRole, startDate, endDate, aboutJob } =
                      element;
                    return (
                      <div className="Beige-common-sections" key={index}>
                        <div className="Beige-edu-grp">
                          <p  className="Bedg-a"style={boldParaStyleCss}>{companyName} </p>
                          <p className="Bedg-b" style={boldParaStyleCss}>{startDate} <span style={{ display: 'block' }}>- {endDate}</span></p>
                        </div>
                        <p style={boldParaStyleCss}>{jobRole}</p>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          }
          {/* <!--PROJECT SECTION--> */}

          {
            resumeData.AllSections[2].isSection &&
            <div className="Beige-section Beige-project forHeading-beige project-edit" >
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[2].sectionName}</h2>
              <div className="Beige-projects ">
                {resumeData.AllSections[2].list.map((element, index) => {
                  const { projectName, startDate, endDate, aboutProject } =
                    element;
                  return (
                    <div className="Beige-common-sections" key={index}>
                      <div className="Beige-edu-grp">
                        <p className="Bedg-a" style={boldParaStyleCss}>{projectName} </p>
                        <p className="Bedg-b" style={boldParaStyleCss}>{startDate} <span style={{ display: 'block' }}>- {endDate}</span></p>
                      </div>
                      <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
                    </div>
                  );
                })}
              </div>
            </div>}
          {AllSections[7].isSection && <
            div className='Beige-hobbies-section forHeading-beige interest-edit'>
            <h2 style={combineHeadingStyle}>{AllSections[7].sectionName}</h2>
            <ul className="Beige-common-list-styling">
              {AllSections[7].list.map(hobbies => {
                const { listId, interest } = hobbies;
                return (
                  <li style={paraStyleCSS} key={listId}>
                    {interest}
                  </li>
                )
              })}
            </ul>
          </div>}

        </div>
        {/* <!--EDUCATION SECTION--> */}
        <div className="new-section-edu">
          <div className="Beige-section-education">
            {
              resumeData.AllSections[5].isSection &&
              <div className="Beige-section-edu education-edit">
                <div className="Beige-section-edu-heading forHeading-beige">
                  <h2 style={{...combineHeadingStyle,marginTop:'0px'}}>{resumeData.AllSections[5].sectionName}</h2>
                </div>
                <div className="Beige-section-edu-grp">
                  {resumeData.AllSections[5].list.map(
                    (element, index) => {
                      const { collegeName, course, startDate, endDate, aboutEducation } = element;
                      return (
                        <div className="Beige-common-sections" key={index}>
                          <div className="Beige-edu-grp">
                            <p className="Bedg-a" style={boldParaStyleCss}>{collegeName} </p>
                            <p className="Bedg-b" style={boldParaStyleCss}>{startDate} <span style={{ display: 'block' }}>- {endDate}</span></p>
                          </div>
                          <p style={boldParaStyleCss} className="beige-course-name">{course}</p>
                          <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} className="beige-about-edu" />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            }

            {AllSections[8].isSection &&
              <div className='Beige-section-certificate certification-edit'>
                <div className="Beige-section-certificate-heading forHeading-beige">
                  <h2 style={combineHeadingStyle}>{AllSections[8].sectionName}</h2>
                </div>
                <div className="Beige_section-certificate-grp">
                  {AllSections[8].list.map(Project => {
                    const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
                    return (
                      <div className="Beige-common-sections" key={listId}>
                        <div className="Beige-edu-grp">
                          <p className="Bedg-a" style={boldParaStyleCss}>{instituteName} </p>
                          <p className="Bedg-b"style={boldParaStyleCss}>{startDate} <span style={{ display: 'block' }}>- {endDate}</span></p>
                        </div>
                        <p style={boldParaStyleCss} className="beige-course-name">{certificateName}</p>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} className="beige-about-edu" />
                      </div>
                    )
                  })}
                </div>
              </div>}


            {/* <!--SKILL SECTION--> */}

            {
              resumeData.AllSections[3].isSection &&
              <div className="Beige-skill-sec forHeading-beige skill-edit">
                <h2 style={combineHeadingStyle}>{resumeData.AllSections[3].sectionName}</h2>
                <ul className="Beige-common-list-styling">
                  {resumeData.AllSections[3].list.map((element) => {
                    return (
                      <li style={paraStyleCSS}>
                        {element.skillName}
                      </li>
                    )
                  })}
                </ul>
              </div>
            }

            {AllSections[6].isSection && 
            <div className='Beige-language-sec forHeading-beige language-edit'>
              <h2 style={combineHeadingStyle}>{AllSections[6].sectionName}</h2>
              <ul className="Beige-common-list-styling">
                {AllSections[6].list.map(languages => {
                  const { listId, languageName, languageProfiency } = languages;
                  return (
                    <li style={paraStyleCSS} key={listId}>
                      {languageName} - {languageProfiency}
                    </li>
                  )
                })}
              </ul>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};


export default BeigeTemplate; 