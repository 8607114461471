import React, { useContext } from 'react'
import './SalesAchieverResume.css';
import { QRCodeCanvas } from 'qrcode.react';
import GlobalContext from '../../context/GlobalContext';

export const SalesAchieverResume = ({ resumeData }) => {

    const { AllSections, isQRCode, qrPosition } = resumeData;
    const { vertical, horizontal, X, Y } = qrPosition;
    const { liveTemplateURL } = useContext(GlobalContext);
    const themStyle = { backgroundColor: resumeData.themeColor }
    const { backgroundPattern } = resumeData.resumeStyle;

    const headingStyleCSS = {
        fontFamily: resumeData.headingTextFont,
        color: resumeData.headingTextColor
    }

    const paraStyleCSS = {
        fontFamily: resumeData.bodyTextFont,
        color: resumeData.bodyTextColor,
        fontSize: `${resumeData.bodyTextSize}px`,
        lineHeight: `${resumeData.bodyTextSize + 8}px`
    }

    const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

    return (
        <div className="SalesAchieverRT" style={{ backgroundImage: `url("/background-pattern/${backgroundPattern}")` }}>

            {
                isQRCode && <div className="resume-qr-code" style={{ [vertical]: `${Y}px`, [horizontal]: `${X}px`, zIndex: 10 }}>
                    <QRCodeCanvas value={liveTemplateURL} size={"50"} />
                </div>
            }

            <div className="parent-sec">
                <div className="left-sec" style={themStyle}>
                    <div className='for-img' style={{ backgroundImage: `url(${resumeData.profileImage})` }}></div>

                    {/* CONTACT */}
                    {
                        resumeData.AllSections[1].isSection &&
                        <div className="contact custom-heading-space contact-edit">
                            <h2 style={combineHeadingStyle} >{resumeData.AllSections[1].sectionName}</h2>
                            {
                                AllSections[1].list.map((element) => {
                                    const { listId, iconName, contactName, additionalLink } = element;

                                    const handleItemClick = (e) => {
                                        if (!additionalLink) {
                                            e.preventDefault();
                                        }
                                    };

                                    return (
                                        <div className="contact-list" key={listId}>
                                            {additionalLink ? (
                                                <a
                                                    href={additionalLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                                >
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <i style={{ color: resumeData.bodyTextColor, fontSize: resumeData.bodyTextSize +2}} className={iconName} />
                                                        <p style={paraStyleCSS}>{contactName}</p>
                                                    </li>
                                                </a>
                                            ) : (
                                                <li
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={handleItemClick}
                                                >
                                                    <i style={{ color: resumeData.bodyTextColor, fontSize: resumeData.bodyTextSize +2}} className={iconName} />
                                                    <p style={paraStyleCSS}>{contactName}</p>
                                                </li>
                                            )}
                                        </div>
                                    );
                                })
                            }


                        </div>}

                    {/* SKILLS */}
                    {
                        resumeData.AllSections[3].isSection &&
                        <div className="sales-common-list-styling skill-edit">
                            <h2 style={combineHeadingStyle} >{resumeData.AllSections[3].sectionName}</h2>
                            <ul>
                                {resumeData.AllSections[3].list.map((element, listId) => (
                                    <li style={paraStyleCSS} key={listId}>
                                        {element.skillName}
                                    </li>
                                ))}
                            </ul>
                        </div>}

                    {/* EDUCATION */}
                    {
                        resumeData.AllSections[5].isSection &&
                        <div className="education custom-heading-space education-edit">
                            <h2 style={combineHeadingStyle}>{resumeData.AllSections[5].sectionName}</h2>
                            {resumeData.AllSections[5].list.map((element, index) => {
                                const { listId, collegeName, course, startDate, endDate, aboutEducation } = element;
                                return (
                                    <div className='custom-common-details' key={listId}>
                                        <p style={paraStyleCSS}><b>{course}</b></p>
                                        <p style={paraStyleCSS}><b>{collegeName} | {startDate} - {endDate}</b></p>
                                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} />
                                    </div>
                                )
                            })}
                        </div>}
                    {AllSections[6].isSection && <div className='sales-common-list-styling language-edit'>
                        <h2 style={combineHeadingStyle}>{AllSections[6].sectionName}</h2>
                        <ul>
                            {AllSections[6].list.map(languages => {
                                const { listId, languageName, languageProfiency } = languages;
                                return (
                                    <li style={paraStyleCSS} key={listId}>
                                        {languageName} {languageProfiency !== "None" && ` - ${languageProfiency}`}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>}

                </div>

                <div className="right-sec">
                    <div className="profile-details personal-edit">
                        <h1 style={headingStyleCSS} >{resumeData.userName}</h1>
                        <h4 style={headingStyleCSS}>{resumeData.userJobRole}</h4>
                    </div>
                    <div className="gradient-section" style={{ background: `linear-gradient(130deg, rgba(255,255,255,1) 30%, ${resumeData.themeColor} 30%)` }}>
                    </div>
                    <div className='for-spacing'>

                        {/* SUMMARY */}
                        {
                            resumeData.AllSections[0].isSection &&
                            <div className="summary custom-heading-space summary-edit">
                                <h2 style={combineHeadingStyle} >{resumeData.AllSections[0].sectionName}</h2>
                                <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />
                            </div>}

                        {/* JOB EXP */}
                        {
                            resumeData.AllSections[4].isSection &&
                            <div className="experience custom-heading-space job-exp-edit">
                                <h2 style={combineHeadingStyle} >{resumeData.AllSections[4].sectionName}</h2>
                                {resumeData.AllSections[4].list.map((element, index) => {
                                    const { listId, companyName, jobRole, startDate, endDate, aboutJob } = element;
                                    return (
                                        <div className='custom-common-details' key={listId}>
                                            <p style={paraStyleCSS}><b>{jobRole}</b></p>
                                            <p style={paraStyleCSS}><b>{companyName} | {startDate} - {endDate}</b></p>
                                            <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
                                        </div>
                                    );
                                })}
                            </div>}

                        {/* PROJECTS */}
                        {

                            resumeData.AllSections[2].isSection &&
                            <div className="projects custom-heading-space project-edit">
                                <h2 style={combineHeadingStyle} >{resumeData.AllSections[2].sectionName}</h2>
                                {resumeData.AllSections[2].list.map((element, index) => {
                                    const { listId, projectName, startDate, endDate, aboutProject } = element;
                                    return (
                                        <div className='custom-common-details' key={listId}>
                                            <p style={paraStyleCSS}><b>{projectName}  |  {startDate} - {endDate}</b></p>
                                            <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
                                        </div>
                                    )
                                })}
                            </div>}

                        {AllSections[8].isSection && <div className='certification custom-heading-space certification-edit'>
                            <h2 style={combineHeadingStyle}>{AllSections[8].sectionName}</h2>
                            {AllSections[8].list.map(Project => {
                                const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
                                return (
                                    <div className='custom-common-details' key={listId}>
                                        <p style={paraStyleCSS}><b>{instituteName}</b></p>
                                        <p style={paraStyleCSS}><b>{certificateName} | {startDate} - {endDate}</b></p>
                                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} />
                                    </div>
                                )
                            })}
                        </div>}
                        {AllSections[7].isSection &&
                            <div className='sales-common-list-styling interest-edit'>
                                <h2 style={combineHeadingStyle}>{AllSections[7].sectionName}</h2>
                                <ul>
                                    {AllSections[7].list.map(hobbies => {
                                        const { listId, interest } = hobbies;
                                        return (
                                            <li style={paraStyleCSS} key={listId}>
                                                {interest}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
