import React, { useContext } from 'react';
import './Border_Chain_Resume.css';
import { QRCodeCanvas } from 'qrcode.react';
import GlobalContext from '../../context/GlobalContext';

export default function Border_Chain_Resume({ resumeData }) {

    const { AllSections, isQRCode, qrPosition } = resumeData;
    const { vertical, horizontal, X, Y } = qrPosition;

    const { liveTemplateURL } = useContext(GlobalContext);
    const themeColor = { borderColor: resumeData.themeColor }
    const { backgroundPattern } = resumeData.resumeStyle;

    const headingStyleCSS = {
        fontFamily: resumeData.headingTextFont,
        color: resumeData.headingTextColor,
    }

    const paraStyleCSS = {
        fontFamily: resumeData.bodyTextFont,
        color: resumeData.bodyTextColor,
        fontSize: `${resumeData.bodyTextSize}px`,
        lineHeight: `${resumeData.bodyTextSize + 8}px`
    }

    const boldParaStyleCss = {
        ...paraStyleCSS, fontSize: `${resumeData.bodyTextSize + 2}px`
    }

    const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

    return (
        <div className="Border-Style-Resume-Template" style={{ ...themeColor, backgroundImage: `url("/background-pattern/${backgroundPattern}")` }}>
            {
                isQRCode ? <div className="resume-qr-code" style={{ [vertical]: `${Y}px`, [horizontal]: `${X}px` }}>
                    <QRCodeCanvas value={liveTemplateURL} size={"50"} />
                </div> : <></>
            }
            <div className="Header">
                <div className="P-details personal-edit" style={themeColor}>
                    <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
                    <h4 style={headingStyleCSS}>{resumeData.userJobRole}</h4>
                </div>
                <div
                    className="Profile_Image"
                    style={{
                        backgroundImage: `url(${resumeData.profileImage})`,
                        ...themeColor
                    }}
                ></div>
            </div>
            <div className="Container">
                <div className="Left-Column">

                    {/* CONTACT */}
                    {
                        resumeData.AllSections[1].isSection &&
                        <div className="Contact-info contact-edit" style={themeColor}>
                            <h2 style={combineHeadingStyle}>{AllSections[1].sectionName}</h2>
                            <ul>
                                {AllSections[1].list.map(element => {
                                    const { listId, iconName, contactName, additionalLink } = element;
                                    const handleItemClick = (e) => {
                                        if (!additionalLink) {
                                            e.preventDefault();
                                        }
                                    };
                                    return (
                                        <li
                                            key={listId}
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleItemClick}
                                        >
                                            {additionalLink ? (
                                                <a
                                                    href={additionalLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ display: 'flex' }}
                                                >
                                                    <i style={{ color: resumeData.themeColor, font: resumeData.bodyTextSize }} className={iconName} />
                                                    <p style={{ ...paraStyleCSS, marginLeft: '10px' }}>{contactName}</p>
                                                </a>
                                            ) : (
                                                <>
                                                    <i style={{ color: resumeData.themeColor, font: resumeData.bodyTextSize }} className={iconName} />
                                                    <p style={paraStyleCSS}>{contactName}</p>
                                                </>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>}

                    {/* SKILLS */}

                    {
                        resumeData.AllSections[3].isSection &&
                        <div className="common-list-styling skill-edit" style={themeColor}>
                            <h2 style={combineHeadingStyle}>{AllSections[3].sectionName}</h2>
                            <ul style={themeColor}>
                                {AllSections[3].list.map(element => {
                                    const { listId, skillName } = element
                                    return (
                                        <li style={paraStyleCSS} key={listId}>
                                            <span style={{ backgroundColor: resumeData.themeColor }}></span>
                                            {skillName}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    }


                    {/* PROJECTS */}
                    {
                        resumeData.AllSections[2].isSection &&
                        <div className="common-section project-edit" style={themeColor}>
                            <h2 style={combineHeadingStyle}>{AllSections[2].sectionName}</h2>
                            {AllSections[2].list.map(element => {
                                const { listId, projectName, startDate, endDate, aboutProject } = element
                                return (
                                    <div key={listId}
                                        className="common-details">
                                        <p><b style={boldParaStyleCss}>{projectName}</b></p>
                                        <p style={paraStyleCSS}><i>{startDate} - {endDate}</i></p>
                                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
                                    </div>
                                )
                            })}
                        </div>}

                    {AllSections[7].isSection && <div className='common-list-styling interest-edit' style={themeColor}>
                        <h2 style={combineHeadingStyle}>{AllSections[7].sectionName}</h2>
                        <ul style={themeColor}>
                            {AllSections[7].list.map(hobbies => {
                                const { listId, interest } = hobbies;
                                return (
                                    <li style={paraStyleCSS} key={listId}>
                                        <span style={{ backgroundColor: resumeData.themeColor }}></span>
                                        {interest}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>}

                    {AllSections[6].isSection && <div className='common-list-styling language-edit' style={themeColor}>
                        <h2 style={combineHeadingStyle}>{AllSections[6].sectionName}</h2>
                        <ul style={themeColor}>
                            {AllSections[6].list.map(languages => {
                                const { listId, languageName, languageProfiency } = languages;
                                return (
                                    <li style={paraStyleCSS} key={listId}>
                                        <span style={{ backgroundColor: resumeData.themeColor }}></span>
                                        <p style={paraStyleCSS}>{languageName} {languageProfiency !== "None" && ` - ${languageProfiency}`}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>}
                </div>

                <div className="Right-Column" style={themeColor}>
                    {/* SUMMARY */}
                    {
                        resumeData.AllSections[0].isSection &&
                        <div key={AllSections[0].listId} className="Summary-info summary-edit" style={themeColor}>
                            <h2 style={combineHeadingStyle}>{AllSections[0].sectionName}</h2>
                            <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />
                        </div>}

                    {/* EDUCATION */}

                    {
                        resumeData.AllSections[5].isSection &&
                        <div className="common-section education-edit" style={themeColor}>
                            <h2 style={combineHeadingStyle}>{AllSections[5].sectionName}</h2>
                            {AllSections[5].list.map(element => {
                                const { listId, collegeName, course, startDate, endDate, aboutEducation } = element
                                return (
                                    <div key={listId}
                                        className="common-details">
                                        <p><b style={boldParaStyleCss}>{course}</b></p>
                                        <p><b style={boldParaStyleCss}>{collegeName}</b></p>
                                        <p style={paraStyleCSS}><i>{startDate} - {endDate}</i></p>
                                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} />
                                    </div>
                                )
                            })}
                        </div>}

                    {/* EXPERIENCE */}
                    {
                        resumeData.AllSections[4].isSection &&
                        <div className="common-section job-exp-edit" style={themeColor}>
                            <h2 style={combineHeadingStyle}>{AllSections[4].sectionName}</h2>
                            {AllSections[4].list.map(element => {
                                const { listId, companyName, jobRole, startDate, endDate, aboutJob } = element
                                return (
                                    <div key={listId}
                                        className="common-details">
                                        <p><b style={boldParaStyleCss}>{companyName}</b></p>
                                        <p><b style={boldParaStyleCss}>{jobRole}</b></p>
                                        <p style={paraStyleCSS}><i>{startDate} - {endDate}</i></p>
                                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
                                    </div>
                                )
                            })}
                        </div>}

                    {AllSections[8].isSection && <div className='certification-edit common-section' style={themeColor}>
                        <h2 style={combineHeadingStyle}>{AllSections[8].sectionName}</h2>
                        {AllSections[8].list.map(Project => {
                            const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
                            return (
                                <div key={listId}
                                    className="common-details">
                                    <p><b style={boldParaStyleCss}>{instituteName}</b></p>
                                    <p><b style={boldParaStyleCss}>{certificateName}</b></p>
                                    <p style={paraStyleCSS}><i>{startDate} - {endDate}</i></p>
                                    <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} />
                                </div>
                            )
                        })}
                    </div>}

                </div>
            </div>
        </div>

    )
}
