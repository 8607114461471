import React, { useRef, useState, useEffect, useContext } from "react";
import "./BlogpostPopup.css";
import TextEditorAd from "./TextEditorAd";
import GlobalContext from "../context/GlobalContext";

export default function BlogpostPopup({ cancel }) {
  const { backServer, addBlogPost } = useContext(GlobalContext);
  const [showScheduleInput, setShowScheduleInput] = useState(false);
  const [scheduledDate, setScheduledDate] = useState({ date: "", time: "" });
  const [visibility, setVisibility] = useState("");
  const [isEditingSchedule, setIsEditingSchedule] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [image, setImage] = useState("/images/Blog-Banner-Placeholder.png");
  const [editorContent, setEditorContent] = useState("");
  const [title, setTitle] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [author, setAuthor] = useState("");
  const [slug, setSlug] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(false);

  // Handle radio button change for visibility
  const handleRadioChange = (e) => {
    const selectedVisibility = e.target.id;
    setVisibility(selectedVisibility);

    if (selectedVisibility === "scheduled") {
      setShowScheduleInput(true);
    } else {
      setShowScheduleInput(false);
      setScheduledDate({ date: "", time: "" });
      setIsScheduled(false);
    }
    if (selectedVisibility !== "scheduled" && isScheduled) {
      setIsScheduled(false);
    }
  };

  // Handle saving the schedule
  const handleSaveSchedule = () => {
    if (scheduledDate.date && scheduledDate.time) {
      setIsScheduled(true);
      setShowScheduleInput(false);
      setIsEditingSchedule(false);
    }
  };

  // Handle rescheduling
  const handleReschedule = () => {
    setIsEditingSchedule(true);
    setShowScheduleInput(true);
    setIsScheduled(false);
  };

  // Handle image upload
  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setImage(URL.createObjectURL(file));
  //   }
  // };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        // Update state with base64 image data
        setImage(base64String);
      };
      // Read the selected file as data URL (base64)
      reader.readAsDataURL(file);
    }
  };

  // Handle image removal
  const handleRemoveImage = () => {
    setImage("/images/Blog-Banner-Placeholder.png");
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const createdTimeBlog = new Date();
    const day = String(createdTimeBlog.getDate()).padStart(2, "0");
    const month = String(createdTimeBlog.getMonth() + 1).padStart(2, "0");
    const year = createdTimeBlog.getFullYear();
    const hours = String(createdTimeBlog.getHours()).padStart(2, "0");
    const minutes = String(createdTimeBlog.getMinutes()).padStart(2, "0");

    const formattedDate = `${day}/${month}/${year} & Time: ${hours}:${minutes}`;
    const formattedSlug = slug.toLowerCase().replace(/\s+/g, "-");

    if (!title || !excerpt || !author || !slug || !editorContent || !visibility) {
      alert("Please fill in all the required fields before saving.");
      return;
    }
    const data = {
      visibility,
      scheduledDate,
      editorContent,
      image,
      title,
      excerpt,
      author,
      slug: formattedSlug,
      createdAt: formattedDate
    };

    localStorage.setItem("blogpostData", JSON.stringify(data));
    setIsDataChanged(false);
    try {
      const responseCGBE = await fetch(`${backServer}/cg/add-post`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = await responseCGBE.json();
      if (responseData.success == false) {
        alert("This slug already exists. Please choose another one.");
        return;
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = () => {
    if (isDataChanged) {
      const confirmClose = window.confirm(
        "You have unsaved changes. Do you want to save the blog post before closing?"
      );
      if (confirmClose) {
        handleSave();
      } else {
        cancel();
        setIsDataChanged(false);
      }
    } else {
      cancel();
    }
  };
  const handleResetSchedule = () => {
    setScheduledDate({ date: "", time: "" });
    setIsScheduled(false);
  };

  return (
    <>
      <div className="blog-post-popup-overlay">
        <div className="blog-post-popup-container">
          <h2>New Post</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            className="cancel-btn"
            onClick={handleCancel}
          >
            <path
              d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6"
              stroke="#252046"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <form onSubmit={handleSave}>
            <div className="bp-text">
              <h3>Featured Image</h3>
              <div className="blog-image-grp">
                <div
                  className="blog-image-preview"
                  style={{
                    backgroundImage: `url(${image})`,
                    cursor: "pointer",
                    border: "1px solid #e5e5e5",
                  }}
                  onClick={() =>
                    document.getElementById("blog-upload-img").click()
                  }
                >
                  {" "}
                </div>
                <input
                  id="blog-upload-img"
                  className="blog-upload-img"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                <label htmlFor="blog-upload-img">Upload Image</label>
                {/* Conditionally render the Remove button only if the image is not the default one */}
                {image !== "/images/Blog-Banner-Placeholder.png" && (
                  <button
                    type="button"
                    className="remove-btn"
                    onClick={handleRemoveImage}
                  >
                    Remove Image
                  </button>
                )}
              </div>
            </div>

            <div className="bp-text">
              <h3>Title</h3>
              <input
                type="text"
                placeholder="About your latest product."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="bp-text">
              <h3>Excerpt</h3>
              <input
                type="text"
                placeholder="Add a summary of the post to appear on your home page or blog."
                value={excerpt}
                onChange={(e) => setExcerpt(e.target.value)}
              />
            </div>
            <div className="bp-text">
              <h3>Author</h3>
              <input
                type="text"
                placeholder="Author Name"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
            </div>
            <div className="bp-text">
              <h3>Slug</h3>
              <input
                type="text"
                placeholder="Slug URL"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
              />
            </div>
            <div className="cg-create-bp">
              <h3>Content</h3>
              <TextEditorAd value={editorContent} onChange={setEditorContent} />
            </div>

            <div className="bp-text" id="inp-addSpacing">
              <h3>Visibility</h3>
              <div className="bp-radio-input-grp">
                <div className="radio-inp-sep">
                  <input
                    type="radio"
                    id="visible"
                    name="visibility"
                    onChange={handleRadioChange}
                    checked={visibility === "visible"}
                  />
                  <label htmlFor="visible">Visible</label>
                </div>
                <div className="radio-inp-sep">
                  <input
                    type="radio"
                    id="hidden"
                    name="visibility"
                    onChange={handleRadioChange}
                    checked={visibility === "hidden"}
                  />
                  <label htmlFor="hidden">Hidden</label>
                </div>
                <div className="radio-inp-sep">
                  <input
                    type="radio"
                    id="draft"
                    name="visibility"
                    onChange={handleRadioChange}
                    checked={visibility === "draft"}
                  />
                  <label htmlFor="draft">Draft</label>
                </div>
                <div className="radio-inp-sep">
                  <input
                    type="radio"
                    id="scheduled"
                    name="visibility"
                    onChange={handleRadioChange}
                    checked={visibility === "scheduled"}
                  />
                  <label htmlFor="scheduled">Schedule</label>
                </div>
              </div>
            </div>

            {showScheduleInput && !isScheduled && (
              <div id="schedule-input" className="schedule-grp">
                <div className="sch-grp-a">
                  <label htmlFor="schedule-date">Select Date:</label>
                  <input
                    type="date"
                    id="schedule-date"
                    onChange={(e) =>
                      setScheduledDate({
                        ...scheduledDate,
                        date: e.target.value,
                      })
                    }
                    value={scheduledDate.date || ""}
                  />
                </div>
                <div className="sch-grp-b">
                  <label htmlFor="schedule-time">Select Time:</label>
                  <input
                    type="time"
                    id="schedule-time"
                    onChange={(e) =>
                      setScheduledDate({
                        ...scheduledDate,
                        time: e.target.value,
                      })
                    }
                    value={scheduledDate.time || ""}
                  />
                </div>

                {scheduledDate.date && scheduledDate.time && (
                  <>
                    <button type="button" onClick={handleSaveSchedule}>
                      Save Schedule
                    </button>
                    <button type="button" onClick={handleResetSchedule}>
                      Reset
                    </button>
                  </>
                )}
              </div>
            )}

            {isScheduled && !isEditingSchedule && (
              <div className="scheduled-label">
                <p>
                  Post Scheduled for {scheduledDate.date} at{" "}
                  {scheduledDate.time}
                </p>
                <button type="button" onClick={handleReschedule}>
                  Reschedule
                </button>
              </div>
            )}
            <div className="save-blog-btn-container">
              <button type="submit">Save Blog</button>
              {/* <button  id='btn-publish' type="button" onClick={handlePublish}>
               Publish */}
              {/* </button> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
