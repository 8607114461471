import React, { useContext, useState } from "react";
import "./AdminLogin.css";
import GlobalContext from "../context/GlobalContext";

export default function AdminLogin() {

    const [eyeBtn, setEyeBtn] = useState({ passT: "password", iClass: "fa-solid fa-eye-slash" });
    const [formErr, setFormErr] = useState({ isErr: false, errMsg: "" });
    const [credentials, setCredentials] = useState({ username: "", password: "" });
    const { backServer } = useContext(GlobalContext);

    const showPass = () => {
        const { passT } = eyeBtn;
        if (passT === "password")
            setEyeBtn({ passT: "text", iClass: "fa-solid fa-eye" });
        else setEyeBtn({ passT: "password", iClass: "fa-solid fa-eye-slash" });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${backServer}/cg/admin`, {
                method: "POST",
                body: JSON.stringify(credentials),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json();
            if (!data.success) {
                setFormErr({ isErr: true, errMsg: "Invalid username or password." })
                return;
            }
            localStorage.setItem('auth-admin', JSON.stringify(data.auth_admin));
            window.location.reload();
        } catch (err) {
            setFormErr({ isErr: true, errMsg: "Server error." })
        }
    }

    return (
        <div className="cg-admin-login-body">
            <img src="./branding/Career Gennie Favicon.svg" alt="Career Gennie Logo"  />
            <form className="cg-admin-login-box" onSubmit={handleSubmit}>
                <label htmlFor="admin-username">Username</label>
                <input type="text" id="admin-username" name="username" value={credentials.username} onChange={handleChange} placeholder="- Your username" required />
                <label htmlFor="admin-password">Password</label>
                <div className="wrap-eye">
                    <input type={eyeBtn.passT} id="admin-password" name="password" value={credentials.password} onChange={handleChange} placeholder="- Your password" required />
                    <i onClick={showPass} className={eyeBtn.iClass}></i>
                </div>
                {formErr.isErr && <p className="err-msg">
                    <i className="fa-solid fa-circle-exclamation"></i>
                    {formErr.errMsg}
                </p>}
                <button type="submit">Sign In</button>
            </form>
        </div>
    )
}

