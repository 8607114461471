import React, { useState } from 'react'
import { TemplateData } from "../templates/TemplateData";
import './DesignationBasedResumeGuidance.css';
import { Tilt } from 'react-tilt';

export const DesignationBasedResumeGuidance = ({ editTemplate, setIsPrevTemplate, HideOverflowOnPop }) => {
    const [subSelectedTem, setSubSelectedTem] = useState("web-developer");

    //TILT CONTROLS
    const defaultOptions = {
        reverse: true,  // reverse the tilt direction
        max: 10,     // max tilt rotation (degrees)
        perspective: 1000,   // Transform perspective, the lower the more extreme the tilt gets.
        scale: 1.02,    // 2 = 200%, 1.5 = 150%, etc..
        speed: 1000,   // Speed of the enter/exit transition
        transition: true,   // Set a transition on enter/exit.
        axis: null,   // What axis should be disabled. Can be X or Y.
        reset: true,    // If the tilt effect has to be reset on exit.
        easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    }

    const professions = [
        { value: "web-developer", label: "Web Developer", icon: "fa-solid fa-code" },
        { value: "graphic-designer", label: "Graphic Designer", icon: "fa-solid fa-pen" },
        { value: "seo-executive", label: "SEO Executive", icon: "fa-solid fa-user-gear" },
        { value: "data-analyst", label: "Data Analyst", icon: "fa-solid fa-database" },
        { value: "accontants", label: "Accountants", icon: "fa-solid fa-chart-simple" },
        { value: "bde", label: "BDE", icon: "fa-solid fa-buildings" },
        { value: "digital-marketing", label: "Digital Marketing", icon: "fa-solid fa-paper-plane" },
        { value: "ui-ux-designer", label: "UI/UX Designer", icon: "fa-solid fa-object-ungroup" },
        { value: "content-writer", label: "Content Writer", icon: "fa-solid fa-file" }
    ];

    const handleDropdownChange = (event) => {
        const { value } = event.target;
        setSubSelectedTem(value);
    }
    return (

        <div className="home-resume-picker" style={{ backgroundImage: "url('./images/background-svg-shape.svg')" }}>
            <div className="hsp-row1">
                <h2 className='career-gennie-web-home-h2'>
                    The Right Resume Builder for Your <span>Job and Experience</span>
                </h2>
                <div className="dropdown-side">
                    {/* <img src="" alt="" draggable="false" /> */}
                    <div className="select-wrapper">
                        <select
                            value={subSelectedTem}
                            onChange={handleDropdownChange}
                            className="profession-dropdown"
                        >
                            {professions.map((profession) => (
                                <option key={profession.value} value={profession.value}>
                                    {profession.label}
                                </option>
                            ))}
                        </select>
                    </div>

                </div>


            </div>
            <div className="rp-container">
                <div className="list-side">
                    {
                        professions.map(element => {
                            const { value, label, icon } = element;
                            return (
                                <div
                                    key={value}
                                    className={subSelectedTem === value ? "designation-active-tab designation-list" : "designation-list"}
                                    onClick={() => {
                                        setSubSelectedTem(value);
                                    }}
                                >
                                    <i className={icon}></i> <h3>{label}</h3>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="view-side">
                    {TemplateData.map((element, index) =>
                        (element.subCategory === subSelectedTem) && (
                            <div className="vs" key={index}>
                                {console.log(element.subCategory, subSelectedTem)}
                                <div className='vs-div' style={{ border: '30px solid #f0f2fd', borderRadius: '20px', backgroundColor: '#f0f2fd' }}>
                                    <Tilt options={defaultOptions}>
                                        <div className='view-side-dimenssions' onClick={() => { setIsPrevTemplate([<element.element resumeData={element} />, element]); HideOverflowOnPop(true) }}>
                                            <img className="des-temp" src={element.templateImage} alt={element.templateName} />
                                        </div>
                                    </Tilt>
                                </div>
                                <button className="template-btn" onClick={() => editTemplate(element)}>Use This Template</button>
                            </div>
                        )
                    )}
                </div>

            </div>
        </div>
    )
} 