import React, { useContext } from 'react';
import './Blue_and_White_Clean_and_Professional_Resume.css';
import { QRCodeCanvas } from 'qrcode.react';
import GlobalContext from '../../context/GlobalContext';

export default function Blue_and_White_Clean_and_Professional_Resume({ resumeData }) {

    const { AllSections, isQRCode, qrPosition } = resumeData;
    const { vertical, horizontal, X, Y } = qrPosition;

    const { liveTemplateURL } = useContext(GlobalContext);

    const headingStyleCSS = {
        fontFamily: resumeData.headingTextFont,
        color: resumeData.headingTextColor,
    }
    const { backgroundPattern } = resumeData.resumeStyle;

    const paraStyleCSS = {
        fontFamily: resumeData.bodyTextFont,
        color: resumeData.bodyTextColor,
        fontSize: `${resumeData.bodyTextSize}px`,
        lineHeight: `${resumeData.bodyTextSize + 8}px`
    }

    const boldParaStyleCss = {
        ...paraStyleCSS, fontSize: `${resumeData.bodyTextSize + 2}px`
    }

    const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

    return (
        <div className="blue-and-white-clean-resume" style={{ backgroundImage: `url("/background-pattern/${backgroundPattern}")` }}>

            {/* HEADER SECTION */}

            <div className="header">
                {
                    isQRCode ? <div className="resume-qr-code" style={{ [vertical]: `${Y}px`, [horizontal]: `${X}px` }}>
                        <QRCodeCanvas value={liveTemplateURL} size={"50"} />
                    </div> : <></>
                }

                <div
                    className="img-module"
                    style={{
                        backgroundImage: `url(${resumeData.profileImage})`,
                    }}
                ></div>
                <div className="personal-details-module ">
                    <div className='personal-edit'>
                        <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
                        <h4 style={headingStyleCSS}>{resumeData.userJobRole}</h4>
                    </div>

                    {/* CONTACT SECTION */}

                    {
                        resumeData.AllSections[1].isSection &&
                        <ul className="icon-contact contact-edit">
                            {AllSections[1].list.map(element => {
                                const { listId, iconName, contactName, additionalLink } = element;
                                const handleItemClick = (e) => {
                                    if (!additionalLink) {
                                        e.preventDefault();
                                    }
                                };
                                return (
                                    <li
                                        key={listId}
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleItemClick}
                                    >
                                        {additionalLink ? (
                                            <a
                                                href={additionalLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ display: 'flex', alignItems: 'center' }}

                                            >
                                                <i style={{ color: resumeData.bodyTextColor }} className={iconName} />
                                                <p style={{ ...paraStyleCSS, paddingLeft: '10px' }}>{contactName}</p>
                                            </a>
                                        ) : (
                                            <>
                                                <i style={{ color: resumeData.bodyTextColor }} className={iconName} />
                                                <p style={paraStyleCSS}>{contactName}</p>
                                            </>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>}
                </div>
            </div>

            {/* SUMMARY SECTION */}

            {
                resumeData.AllSections[0].isSection &&
                <div className='summary-edit'>
                    <h2 style={combineHeadingStyle}>{AllSections[0].sectionName}</h2>
                    <p className="summary-para" style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />
                </div>
            }
            {/* PROJECT SECTION */}

            {
                resumeData.AllSections[2].isSection &&
                <>
                    <div className='project-edit'>
                        <h2 style={combineHeadingStyle}>{AllSections[2].sectionName}</h2>
                        {AllSections[2].list.map(element => {
                            const { listId, projectName, startDate, endDate, aboutProject } = element;
                            return (
                                <div className="project-sec common-sec" key={listId}>
                                    <div className="name-date">
                                        <p><b style={boldParaStyleCss}>{projectName}</b></p>
                                        <p style={paraStyleCSS}>{startDate} / {endDate}</p>
                                    </div>
                                    <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
                                </div>
                            )
                        })}
                    </div>
                </>
            }



            {/* JOB EXPERIENCE SECTION */}

            {resumeData.AllSections[4].isSection &&
                <div className='job-exp-edit'>
                    <h2 style={combineHeadingStyle}>{AllSections[4].sectionName}</h2>
                    {AllSections[4].list.map(element => {
                        const { listId, companyName, jobRole, startDate, endDate, aboutJob } = element;
                        return (
                            <div className="job-sec common-sec" key={listId}>
                                <div className="name-date">
                                    <p><b style={boldParaStyleCss}>{companyName}</b></p>
                                    <p style={paraStyleCSS}>{startDate} / {endDate}</p>
                                </div>
                                <p><b style={boldParaStyleCss}>{jobRole}</b></p>
                                <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
                            </div>
                        )
                    })}
                </div>}


            {/* SKILLS SECTION */}
            {resumeData.AllSections[3].isSection &&
                <div className='common-list-styling skill-edit'>
                    <h2 style={combineHeadingStyle}>{AllSections[3].sectionName}</h2>
                    <ul>
                        {AllSections[3].list.map(element => {
                            const { listId, skillName } = element;
                            return (
                                <li style={paraStyleCSS} key={listId}>
                                    {skillName}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            }



            {/* EDUCATION SECTION */}

            {
                resumeData.AllSections[5].isSection &&
                <div className='education education-edit'>
                    <h2 style={combineHeadingStyle}>{AllSections[5].sectionName}</h2>
                    {AllSections[5].list.map(element => {
                        const { listId, collegeName, course, startDate, endDate, aboutEducation } = element;
                        return (
                            <div className="education-sec common-sec" key={listId}>
                                <div className="name-date">
                                    <p><b style={boldParaStyleCss}>{collegeName}</b></p>
                                    <p style={paraStyleCSS}>{startDate} / {endDate}</p>
                                </div>
                                <p><b style={boldParaStyleCss}>{course}</b></p>
                                <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} />
                            </div>
                        )
                    })}
                </div>}


            {AllSections[7].isSection && <div className='common-list-styling interest-edit'>
                <h2 style={combineHeadingStyle}>{AllSections[7].sectionName}</h2>
                <ul>
                    {AllSections[7].list.map(hobbies => {
                        const { listId, interest } = hobbies;
                        return (
                            <li style={paraStyleCSS} key={listId}>
                                {interest}
                            </li>
                        )
                    })}
                </ul>
            </div>}
            {AllSections[8].isSection && <div className='certification-edit common-section'>
                <h2 style={combineHeadingStyle}>{AllSections[8].sectionName}</h2>
                {AllSections[8].list.map(Project => {
                    const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
                    return (
                        <div className="certification-sec common-sec" key={listId}>
                            <div className="name-date">
                                <p><b style={boldParaStyleCss}>{instituteName}</b></p>
                                <p style={paraStyleCSS}>{startDate} / {endDate}</p>
                            </div>
                            <p><b style={boldParaStyleCss}>{certificateName}</b></p>
                            <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} />
                        </div>
                    )
                })}
            </div>}

            {AllSections[6].isSection && <div className='common-list-styling language-edit'>
                <h2 style={combineHeadingStyle}>{AllSections[6].sectionName}</h2>
                <ul>
                    {AllSections[6].list.map(languages => {
                        const { listId, languageName, languageProfiency } = languages;
                        return (
                            <li style={paraStyleCSS} key={listId}>
                                <p style={paraStyleCSS}>{languageName} {languageProfiency !== "None" && ` - ${languageProfiency}`}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>}
        </div>
    )
}