import React, { useRef, useState, useEffect, useContext } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import "./AtsScorePopup.css";
import GlobalContext from "../context/GlobalContext";
import AtsAnalyzer from './AtsAnalzer';
import { AtsSkeletonLoader } from "./AtsSkeletonLoader";

export const AtsScorePopup = ({ cancel }) => {
  const textPopup = useRef();
  const [result, setResult] = useState("");
  const [improvementSuggestions, setImprovementSuggestions] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { atsScoreGemini } = useContext(GlobalContext);
  const { currentTemplateData, setCurrentTemplateData } = useContext(GlobalContext);
  const { resumeATS, initialResumeData, initialSuggestion } = currentTemplateData;

  // Effect to update the initialResumeData when atsScoreGemini changes
  useEffect(() => {
    if (atsScoreGemini[1] !== initialResumeData) {
      // Only update context if the data has changed
      setCurrentTemplateData(prevState => ({
        ...prevState,
        initialResumeData: atsScoreGemini[1],  // Update resume data if changed
        initialSuggestion: improvementSuggestions // Update suggestions if available
      }));
    }
  }, [atsScoreGemini, initialResumeData, improvementSuggestions, setCurrentTemplateData]);

  // Main Effect to fetch ATS Score and improvement suggestions
  useEffect(() => {
    const fetchAtsScore = async () => {
      // If resume data hasn't changed, use existing data
      if (atsScoreGemini[1] === initialResumeData) {
        setResult(resumeATS);
        setImprovementSuggestions(initialSuggestion); // Use saved suggestions
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const genAI = new GoogleGenerativeAI("AIzaSyDeKkrIQ_uSGDdYj9l3_4MykdI3XdrTxtk");
        const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

        // ATS Score prompt
        const additionalText = 'Use this HTML and text content to analyze the keywords, and score the resume out of 100. Just tell the resume score, nothing else.';
        const combinedPrompt = `First compare the resume data if it is same then don't show any suggestion of resume score show the previous one, if not then proceed with the one HTML Content: ${atsScoreGemini[0]}\nText Content: ${atsScoreGemini[1]}\n${additionalText}`;

        const response = await model.generateContent(combinedPrompt);

        if (response && response.response && response.response.text) {
          const newResult = response.response.text;
          setResult(newResult);

          // Update currentTemplateData with the new ATS result
          setCurrentTemplateData(prev => ({
            ...prev,
            resumeATS: newResult
          }));
        } else {
          throw new Error("Invalid response structure");
        }

        // Improvement suggestions prompt
        // const improvementPrompt = `Analyze the following resume data ${atsScoreGemini[1]} and provide specific suggestions to enhance its ATS compatibility and score, organized by sections. For each suggestion, use an <h3> tag for the heading (only the heading text, no special characters like asterisks or hashtags) and a <p> tag for the text. Ensure the language is simple, concise, and to the point. The output should be in HTML format, with headings and paragraphs only. Do not include any extra symbols or formatting. Instead of using the candidate's name, speak directly to the user with 'you' or 'your' to make the advice more personal and engaging, but the heading remains the same. Do not provide any suggestions related to choosing a resume template or overall suggestions and something like that.`;
        const improvementPrompt = `Analyze the following resume data ${atsScoreGemini[1]} and provide specific suggestions to enhance its ATS compatibility and score, organized by sections. For each suggestion, use an <h3> tag for the heading (only the heading text, no special characters like asterisks or hashtags) and a <p> tag for the text. Ensure the language is simple, concise, and to the point. The output should only include content wrapped in <h3> and <p> tags. Do not include any extra symbols, formatting, or content outside these tags. Instead of using the candidate's name, speak directly to the user with 'you' or 'your' to make the advice more personal and engaging, but the heading remains the same. Do not provide any suggestions related to choosing a resume template or overall suggestions like that.`;

        const improvementResponse = await model.generateContent(improvementPrompt);

        if (improvementResponse && improvementResponse.response && improvementResponse.response.text) {
          setImprovementSuggestions(improvementResponse.response.text);

          // Update currentTemplateData with the new suggestions
          setCurrentTemplateData(prev => ({
            ...prev,
            initialSuggestion: improvementResponse.response.text
          }));
        } else {
          throw new Error("Invalid response structure for improvements");
        }
      } catch (error) {
        console.error("Error generating content:", error);
        setError("An error occurred while generating the score and suggestions.");
      } finally {
        setLoading(false);
      }
    };

    fetchAtsScore();
  }, [atsScoreGemini, initialResumeData, resumeATS, initialSuggestion, setCurrentTemplateData]);

  // Handle click outside to close the popup
  const handleClickOutside = (event) => {
    if (textPopup.current && textPopup.current.contains(event.target)) return;
    cancel();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={`ats-score-popup common-pop-box ${loading ? "loading" : ""}`} ref={textPopup}>
      {loading ? (
        <AtsSkeletonLoader />
      ) : error ? (
        <div className="error-message">
          <i className="fas fa-exclamation-circle iserror" />
          <p>{error}</p>
        </div>
      ) : (
        <>
          <h2>Your ATS Score</h2>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="cancel-btn" onClick={cancel}>
            <path d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <AtsAnalyzer value={result} />
          {improvementSuggestions && (
            <div className="improvement-suggestions" dangerouslySetInnerHTML={{ __html: improvementSuggestions }} />
          )}
        </>
      )}
    </div>
  );
};
