import React, { useState, useContext } from 'react';
import GlobalContext from '../context/GlobalContext'; // assuming you have a global context to manage form data
import './Language.css';
import { useNavigate } from 'react-router-dom';
import SaveAlert from '../alerts/SaveAlert';
import ResumePreviewPopup from '../popup/ResumePreviewPopup';
import { TemplateData } from '../templates/TemplateData';

export default function Language({ isLast }) {
    const { currentTemplateData, ChangeListValue, DeleteItem, AddItem, ChangeSectionValue, authtoken, setIsSaveData, backServer } = useContext(GlobalContext);
    const { sectionName, list } = currentTemplateData.AllSections[6];
    const [isPreview, setIsPreview] = useState(false);
    const navigate = useNavigate();
    const proficiencyLevels = ["None", "Beginner", "Intermediate", "Expert"];

    const objectData = {
        listId: new Date().getTime().toString(),
        language: "",
        languageProfiency: "Beginner" // default proficiency
    };

    const handleLanguageChange = (e, listId) => {
        const { name, value } = e.target;
        ChangeListValue({ target: { name, value } }, 6, listId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!authtoken) {
                localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
                isLast && navigate(isLast);
                //RESUME PREVIEW
                !isLast && setIsPreview(
                    <ResumePreviewPopup
                        element={TemplateData.map((element) => {
                            if (element.id === currentTemplateData.id)
                                return <element.element resumeData={currentTemplateData} />;
                        })}
                        cancel={() => setIsPreview(false)}
                    />
                )
                return;
            }

            await fetch(`${backServer}/dashboard/update-templates`, {
                method: "POST",
                body: JSON.stringify(currentTemplateData),
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': authtoken
                }
            })
            setIsSaveData(<SaveAlert status={"show"} alertMsg={"All changes saved"} />);
            setTimeout(() => {
                setIsSaveData(<SaveAlert status={"hide"} alertMsg={"All changes saved"} />);
            }, 800);
            //ALSO SAVE IN LOCAL STORAGE
            localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
            isLast && navigate(isLast);
            //RESUME PREVIEW
            !isLast && setIsPreview(
                <ResumePreviewPopup
                    element={TemplateData.map((element) => {
                        if (element.id === currentTemplateData.id)
                            return <element.element resumeData={currentTemplateData} />;
                    })}
                    cancel={() => setIsPreview(false)}
                />
            )
        } catch (error) {
            console.log("Server error.", error);
        }
    };

    return (
        <>
            {isPreview}
            <div className="language-form">
                <h2 className="cg-form-h2">Languages</h2>
                <form onSubmit={handleSubmit}>
                    <div className="cg-wrap-inputs">
                        <input
                            type="text"
                            className="cg-form-input"
                            value={sectionName}
                            name='sectionName'
                            onChange={(e) => ChangeSectionValue(e, 6)}
                            placeholder='Section name'
                        />
                        {list.map((element) => {
                            const { languageName, languageProfiency, listId } = element;

                            return (
                                <div key={listId} className="language-list">
                                    <input
                                        type="text"
                                        name='languageName'
                                        value={languageName}
                                        onChange={(e) => handleLanguageChange(e, listId)}
                                        placeholder='Add language'
                                    />
                                    <div className='flex-row'>
                                        <div className='wrapper-select'>
                                            <select id='list-item-language'
                                                name='languageProfiency'
                                                value={languageProfiency}
                                                onChange={(e) => handleLanguageChange(e, listId)}
                                            >
                                                {proficiencyLevels.map((level, index) => (
                                                    <option style={{ backgroundColor: '#fff' }} key={index} value={level}>
                                                        {level}
                                                    </option>

                                                ))}
                                            </select>
                                        </div>
                                        <i className='fa-solid fa-trash' onClick={() => DeleteItem(6, listId)}></i>
                                    </div>

                                </div>
                            );
                        })}
                    </div>
                    <a className="cg-web-form-fill-btn add-section" onClick={() => AddItem(6, objectData)}><i className='fa-regular fa-plus'></i>Add Section</a>
                    <button className="cg-web-form-fill-btn" type='submit'>
                        Save and {isLast ? "Next" : "Preview"}
                        <i className="fa-regular fa-arrow-right" />
                    </button>
                </form>
            </div>
        </>
    );
}
