import React, { useContext, useState } from "react";
import "../Resume-process-css/job-exp.css";
import GlobalContext from "../context/GlobalContext";
import { useNavigate } from 'react-router-dom';
import { TemplateData } from "../templates/TemplateData";
import { AiTextPopup } from "../popup/AiTextPopup";
import ProjectJobEduTextEditor from "../popup/ProjectJobEduTextEditor";
import MonthYearPicker from "../popup/MonthYearPicker";
import ResumePreviewPopup from "../popup/ResumePreviewPopup";

export default function JobExp({ isLast }) {

  const [aiTextSuggestion, setAiTextSuggestion] = useState(false);
  const [isMonthYearPopup, setIsMonthYearPopup] = useState(false);
  const navigate = useNavigate();

  const {
    currentTemplateData,
    DeleteItem,
    AddItem,
    ChangeListValue,
    ChangeSectionValue,
    authtoken,
    backServer,
  } = useContext(GlobalContext);

  const index = 4; // Ensure this index corresponds to the job experience section
  const { sectionName, list } = currentTemplateData.AllSections[index];
  const [isPreview, setIsPreview] = useState(false);

  const objectData = {
    listId: new Date().getTime().toString(),
    companyName: "Company Name",
    jobRole: "Your designation",
    startDate: "YY",
    endDate: "YY",
    aboutJob: "Description",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!authtoken) {
        localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
        navigate('/edit-resume/certificate');
        return;
      }

      await fetch(`${backServer}/dashboard/update-templates`, {
        method: "POST",
        body: JSON.stringify(currentTemplateData),
        headers: {
          "Content-Type": "application/json",
          "auth-token": authtoken,
        },
      });
      // Save in local storage
      localStorage.setItem("currentTemplate", JSON.stringify(currentTemplateData));
      isLast && navigate(isLast);
      //RESUME PREVIEW
      !isLast && setIsPreview(
        <ResumePreviewPopup
          element={TemplateData.map((element) => {
            if (element.id === currentTemplateData.id)
              return <element.element resumeData={currentTemplateData} />;
          })}
          cancel={() => setIsPreview(false)}
        />
      )
    } catch (error) {
      console.log("Server error.", error);
    }
  };

  const handleTextChange = (newText, listId) => {
    ChangeListValue({ target: { name: 'aboutJob', value: newText } }, index, listId);
    setAiTextSuggestion(false);
  };

  return (
    <>
      <div className="job-exp">
        <h2 className="cg-form-h2">Job Experience</h2>
        <form onSubmit={handleSubmit}>
          <div className="cg-wrap-inputs">
            <input
              type="text"
              className="cg-form-input"
              name="sectionName"
              value={sectionName}
              placeholder="Section Name"
              onChange={(e) => ChangeSectionValue(e, index)}
              required
            />
            {list.map((element) => {
              const {
                listId,
                companyName,
                jobRole,
                startDate,
                endDate,
                aboutJob,
              } = element;

            return (
              <div className="job-exp-content" key={listId}>
                    <input
                      className="cg-form-input"
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => ChangeListValue(e, index, listId)}
                      placeholder="Company Name"
                      required
                    />
                    <input
                      className="cg-form-input"
                      type="text"
                      name="jobRole"
                      value={jobRole}
                      onChange={(e) => ChangeListValue(e, index, listId)}
                      placeholder="Job Role"
                      required
                    />
                <div className="job-exp-section-pic-date">
                  <div className="job-exp-datepic-monthdate">
                    <div className="month-year-pickers">
                      <div className="start-end-date" onClick={() => setIsMonthYearPopup({ listId, isStartDate: true })}>
                        <p>{startDate}</p>
                        <i class="fa-regular fa-calendar-range"></i>
                      </div>
                      {isMonthYearPopup.listId === listId && isMonthYearPopup.isStartDate ? <MonthYearPicker cancel={() => setIsMonthYearPopup(false)} startingDate={true} listId={listId} sectionIndex={index} name={"startDate"} /> : <></>}
                    </div>
                    <div className="month-year-pickers">
                      <div className="start-end-date" onClick={() => setIsMonthYearPopup({ listId, isStartDate: false })}>
                        <p>{endDate ? <>{endDate}</> : <>Choose date</>}</p>
                        <i class="fa-regular fa-calendar-range"></i>
                      </div>
                      {isMonthYearPopup.listId === listId && !isMonthYearPopup.isStartDate ? <MonthYearPicker cancel={() => setIsMonthYearPopup(false)} startingDate={false} listId={listId} sectionIndex={index} name={"endDate"} /> : <></>}
                    </div>
                  </div>
                </div>
                <div className="cg-text-editor">
                  <ProjectJobEduTextEditor
                    name="aboutjob"
                    value={aboutJob}
                    onChange={(newText) => handleTextChange(newText, listId)}
                    required
                    placeholder='Description'
                  />
                  <div className="cg-ai-text-suggestion" title='Use AI to enhance your content.' onClick={() => setAiTextSuggestion(listId)}>
                      <i className="fa-light fa-sparkles"></i>
                      <p>Generate text</p>
                    </div>
                  {aiTextSuggestion === listId && (
                    <AiTextPopup
                      prompt={aboutJob}
                      cancel={() => setAiTextSuggestion(false)}
                      onTextChange={(newText) => handleTextChange(newText, listId)}
                    />
                  )}
                </div>

                  <div className="job-exp-del-add-button">
                    <hr />
                    <i
                      className="fa-solid fa-trash"
                      onClick={() => DeleteItem(index, listId)}
                    ></i>
                  </div>
                </div>
              );
            })}
          </div>
          <a className="cg-web-form-fill-btn add-section" onClick={() => AddItem(index, objectData)}>
            <i className="fa-regular fa-plus" /> Add Section
          </a>
          <button className="cg-web-form-fill-btn" type="submit">
            Save and {isLast ? "Next" : "Preview"}
            <i className="fa-regular fa-arrow-right" />
          </button>
        </form>
      </div>
      {isPreview}
    </>
  );
}
