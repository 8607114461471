import React, { useContext, useState, useEffect, useRef } from "react";
import GlobalContext from "../context/GlobalContext";
import IconPopup from "../popup/IconPopup";
import { useNavigate } from "react-router-dom";
import "../Resume-process-css/Contact.css";
import SaveAlert from "../alerts/SaveAlert";
import ResumePreviewPopup from "../popup/ResumePreviewPopup";
import { TemplateData } from "../templates/TemplateData";

export default function ContactInfo({ isLast }) {
  const {
    currentTemplateData,
    DeleteItem,
    AddItem,
    ChangeListValue,
    ChangeSectionValue,
    authtoken,
    setIsSaveData,
    backServer,
  } = useContext(GlobalContext);

  const [activeIcon, setActiveIcon] = useState("");
  const [visibleLinkInput, setVisibleLinkInput] = useState(null);
  const [linkValue, setLinkValue] = useState("");
  const navigate = useNavigate();
  const refPopupBox = useRef(null);
  const [isPreview, setIsPreview] = useState(false);

  const closePopup = (e) => {
    if (refPopupBox.current && !refPopupBox.current.contains(e.target)) {
      setVisibleLinkInput(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closePopup, true);
    return () => {
      document.removeEventListener("click", closePopup, true);
    };
  }, []);

  const objectData = {
    listId: Date.now().toString(),
    iconName: "fa-solid fa-plus",
    contactName: "",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!authtoken) {
      localStorage.setItem(
        "currentTemplate",
        JSON.stringify(currentTemplateData)
      );
      isLast && navigate(isLast);
      //RESUME PREVIEW
      !isLast && setIsPreview(
        <ResumePreviewPopup
          element={TemplateData.map((element) => {
            if (element.id === currentTemplateData.id)
              return <element.element resumeData={currentTemplateData} />;
          })}
          cancel={() => setIsPreview(false)}
        />
      )
      return;
    }

    try {
      const response = await fetch(`${backServer}/dashboard/update-templates`, {
        method: "POST",
        body: JSON.stringify(currentTemplateData),
        headers: {
          "Content-Type": "application/json",
          "auth-token": authtoken,
        },
      });

      if (!response.ok) throw new Error("Failed to save changes");

      setIsSaveData(
        <SaveAlert status={"show"} alertMsg={"All changes saved"} />
      );
      setTimeout(
        () =>
          setIsSaveData(
            <SaveAlert status={"hide"} alertMsg={"All changes saved"} />
          ),
        800
      );
      localStorage.setItem(
        "currentTemplate",
        JSON.stringify(currentTemplateData)
      );
      isLast && navigate(isLast);
      //RESUME PREVIEW
      !isLast && setIsPreview(
        <ResumePreviewPopup
          element={TemplateData.map((element) => {
            if (element.id === currentTemplateData.id)
              return <element.element resumeData={currentTemplateData} />;
          })}
          cancel={() => setIsPreview(false)}
        />
      )
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const toggleLinkInput = (listId) => {
    setVisibleLinkInput(visibleLinkInput === listId ? null : listId);
  };

  const saveLink = (element) => {
    ChangeListValue({ target: { value: linkValue } }, 1, element.listId);
    setLinkValue("");
    setVisibleLinkInput(null);
  };

  return (
    <>
      {isPreview}
      <div className="edit-contact-form">
        <h2 className="cg-form-h2">Contact Info</h2>
        <form onSubmit={handleSubmit}>
          <div className="cg-wrap-inputs">
            {currentTemplateData.AllSections[1].sectionVisible && (
              <input
                type="text"
                className="cg-form-input"
                name="sectionName"
                value={currentTemplateData.AllSections[1].sectionName}
                onChange={(e) => ChangeSectionValue(e, 1)}
                placeholder="Section name"
              />
            )}
            {currentTemplateData.AllSections[1].list.map((element) => (
              <div key={element.listId} className="social-media">
                <i
                  id="brandIcon"
                  className={element.iconName}
                  onClick={() => setActiveIcon(element.listId)}
                ></i>
                <input
                  type="text"
                  name="contactName"
                  value={element.contactName}
                  onChange={(e) => ChangeListValue(e, 1, element.listId)}
                  placeholder="your@contact"
                />

                <i
                  id="brandIcon-link"
                  className="fa-solid fa-link"
                  onClick={() => toggleLinkInput(element.listId)}
                ></i>

                <i
                  id="brandIcon"
                  className="fa-solid fa-trash"
                  onClick={() => DeleteItem(1, element.listId)}
                ></i>

                {visibleLinkInput === element.listId && (
                  <div className="additional-link-sec" ref={refPopupBox}>
                    <p>Enter Link:</p>
                    <input
                      type="text"
                      value={element.additionalLink}
                      onChange={(e) => ChangeListValue(e, 1, element.listId)}
                      name="additionalLink"
                      placeholder="https://careergennie.com/"
                    />
                    <button type="button" onClick={() => saveLink(element)}>
                      Save
                    </button>
                  </div>
                )}

                {activeIcon === element.listId && (
                  <IconPopup
                    cutPopup={() => setActiveIcon("")}
                    iconId={element.listId}
                    iconClass={element.iconName}
                  />
                )}
              </div>
            ))}
          </div>
          <a className="cg-web-form-fill-btn add-section" onClick={() => AddItem(1, objectData)}>
            <i className="fa-regular fa-plus" /> Add Section
          </a>
          <button className="cg-web-form-fill-btn" type="submit">
            Save and {isLast ? "Next" : "Preview"}
            <i className="fa-regular fa-arrow-right" />
          </button>
        </form>
      </div>
    </>
  );
}
