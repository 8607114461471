import React, { useContext, useEffect, useState } from "react";
import "./UploadMedia.css";
import GlobalContext from "../context/GlobalContext";
import MediaEdit from "./MediaEdit";

const UploadMedia = ({ cancel }) => {

  const { backServer } = useContext(GlobalContext);
  const [allMedia, setAllMedia] = useState();
  const [selectMedia, setSelectMedia] = useState();
  const [openEditmediaPopup, setOpenMediaeditPopup] = useState(false);
  const [mediaToEdit, setMediaToEdit] = useState({});
  const [isChange, setIsChange] = useState(false);


  const handleSaveMedia = (updatedMedia) => {
    setAllMedia((prevMedia) =>
      prevMedia.map((media) =>
        media._id === updatedMedia._id ? updatedMedia : media
      )
    );
  };

  const uploadFile = async (e) => {
    try {
      const formData = new FormData();
      formData.append("url", e.target.files[0]);
      const response = await fetch(`${backServer}/cg/upload`, {
        method: "POST",
        body: formData
      });
      const backData = await response.json();
      setAllMedia([...allMedia, backData.medias])
      setSelectMedia(backData.medias.url)
    } catch (err) {
      console.log("Image upload error!", err);
    }
  }

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await fetch(`${backServer}/cg/all-media`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }
        });
        const media = await response.json();
        setAllMedia(media.medias);
      } catch (err) {
        console.log("All media error!", err);
      }
    }

    fetchMedia();
  }, [isChange])

  return (
    <div className="cg-upload-media-popup">
      <div className="popup-container">
        <div className="popup-header">
          <h2>Media</h2>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="cancel-btn" onClick={cancel}>
            <path d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        </div>

        <div className="search-media-box">
          <i className="fa-solid fa-search" />
          <input
            type="text"
            placeholder="Search media..."
          />
        </div>

        <div className="upload-box">
          <p className="upload-text">Drop files to upload or</p>
          <label className="select-files-button" htmlFor="fileUpload">
            Upload Image
          </label>
          <input
            type="file"
            id="fileUpload"
            multiple
            accept="image/*"
            onChange={uploadFile}
          />
          <p className="file-size-note">MAXIMUM UPLOAD FILE SIZE: 2GB</p>
        </div>

        <div className="uploaded-images">
          {
            allMedia ?
              [...allMedia].reverse().map((element, index) => {
                const { title, url, alt, description, type } = element;
                return (
                  <div className={`media ${url === selectMedia && "active"}`} onClick={() => setSelectMedia(url)} key={url}>
                    <div className="image-preview" style={{ backgroundImage: `url(${backServer}/uploads/${url})` }} key={index}>
                      <i onClick={() => {
                        setMediaToEdit({ title, url, alt, description });
                        setOpenMediaeditPopup(true);
                      }} className="edit-icon fa-regular fa-pen-to-square"></i>
                      {openEditmediaPopup && <MediaEdit cancel={() => setOpenMediaeditPopup(false)} media={mediaToEdit} change={() => setIsChange(!isChange)}
                      />}
                    </div>
                    <p>{title.length > 10 ? title.slice(0, 10) + '...' : title}</p>
                    <p style={{ textTransform: 'uppercase', color: "#999" }}>{type}</p>
                  </div>
                );
              })
              : <h4>No media found!</h4>
          }
        </div>
        <div className="cg-media-can-dne-btn">
          <button className="can" onClick={() => setSelectMedia(null)}>Cancel</button>
          <button className="sav" style={selectMedia ? {} : { backgroundColor: "#f0f2fd", color: "#8e8feb", cursor: "not-allowed" }}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default UploadMedia;
