import React, { useContext } from 'react';
import './Three_Side_Border_Resume.css';
import { QRCodeCanvas } from 'qrcode.react';
import GlobalContext from '../../context/GlobalContext';

export default function Three_Side_Border_Resume({ resumeData }) {

    const hexToRgba = (hex, opacity) => {
        hex = hex.replace(/^#/, '');
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    const { AllSections, isQRCode, qrPosition } = resumeData;
    const { vertical, horizontal, X, Y } = qrPosition;

    const { liveTemplateURL } = useContext(GlobalContext);
    const { themeColor } = resumeData;
    const downColor = hexToRgba(themeColor, 0.4);
    const { backgroundPattern } = resumeData.resumeStyle;

    const headingStyleCSS = {
        fontFamily: resumeData.headingTextFont,
        color: resumeData.headingTextColor,
    }

    const paraStyleCSS = {
        fontFamily: resumeData.bodyTextFont,
        color: resumeData.bodyTextColor,
        fontSize: `${resumeData.bodyTextSize}px`,
        lineHeight: `${resumeData.bodyTextSize + 8}px`
    }

    const boldParaStyleCss = {
        ...paraStyleCSS, fontSize: `${resumeData.bodyTextSize + 2}px`
    }

    const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

    return (
        <div className="Three_Side_Border_Resume" style={{ backgroundImage: `url("/background-pattern/${backgroundPattern}")` }}>

            {
                isQRCode ? <div className="resume-qr-code" style={{ [vertical]: `${Y}px`, [horizontal]: `${X}px` }}>
                    <QRCodeCanvas value={liveTemplateURL} size={"50"} />
                </div> : <></>
            }
            <div className="Left_Column" style={{ borderColor: themeColor }}>
                <div
                    className="Profile_Image"
                    style={{
                        backgroundImage: `url(${resumeData.profileImage})`,
                        outlineColor: themeColor
                    }}
                ></div>

                {/* CONTACT */}
                {
                    resumeData.AllSections[1].isSection &&
                    <div className="Contact_info contact-edit" style={{ borderColor: themeColor }}>
                        <h2 style={combineHeadingStyle}>{AllSections[1].sectionName}</h2>
                        <ul style={{ borderColor: themeColor }}>
                            {AllSections[1].list.map(element => {
                                const { listId, iconName, contactName, additionalLink } = element;
                                const handleItemClick = (e) => {
                                    if (!additionalLink) {
                                        e.preventDefault();
                                    }
                                };
                                return (
                                    <li
                                        key={listId}
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleItemClick}
                                    >
                                        {additionalLink ? (
                                            <a
                                                href={additionalLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ display: 'flex',  }}
                                            >
                                                <i style={{ backgroundColor: themeColor, color: resumeData.bodyTextColor }} className={iconName} />
                                                <p style={paraStyleCSS}>{contactName}</p>
                                            </a>
                                        ) : (
                                            <>
                                                <i style={{ backgroundColor: themeColor, color: resumeData.bodyTextColor }} className={iconName} />
                                                <p style={paraStyleCSS}>{contactName}</p>
                                            </>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>}

                {/* SKILLS */}
                {
                    resumeData.AllSections[3].isSection &&
                    <div className="Common-list-styling skill-edit" style={{ borderColor: themeColor }}>
                        <h2 style={combineHeadingStyle}>{AllSections[3].sectionName}</h2>
                        <ul style={{ borderColor: themeColor }}>
                            {AllSections[3].list.map(skill => {
                                const { listId, skillName } = skill;
                                return (
                                    <li style={paraStyleCSS} key={listId}>
                                        <span className="dot" style={{ backgroundColor: resumeData.themeColor }}></span>
                                        <p style={paraStyleCSS}>{skillName}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>}

                {/* PROJECT */}
                {
                    resumeData.AllSections[2].isSection &&
                    <div className="Project_Information project-edit" style={{ borderColor: themeColor }}>
                        <h2 style={combineHeadingStyle}>{AllSections[2].sectionName}</h2>
                        {AllSections[2].list.map(Project => {
                            const { listId, projectName, startDate, endDate, aboutProject } = Project;
                            return (
                                <div className="Project_Type" key={listId} style={{ borderColor: themeColor }}>
                                    <span style={{ backgroundColor: themeColor }} className="styling-dot" />
                                    <p><b style={boldParaStyleCss}>{projectName}</b></p>
                                    <p style={paraStyleCSS}><i>{startDate} - {endDate}</i></p>
                                    <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
                                </div>
                            )
                        })}
                    </div>}

                {AllSections[7].isSection &&
                    <div className='Common-list-styling interest-edit' style={{ borderColor: themeColor }}>
                        <h2 style={combineHeadingStyle}>{AllSections[7].sectionName}</h2>
                        <ul style={{ borderColor: themeColor }}>
                            {AllSections[7].list.map(hobbies => {
                                const { listId, interest } = hobbies;
                                return (
                                    <li style={paraStyleCSS} key={listId}>
                                        <span className="dot" style={{ backgroundColor: resumeData.themeColor }}></span>
                                        <p style={paraStyleCSS}> {interest}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>}
            </div>
            <div className="Right_Column ">
                <div className="Personal_Information personal-edit personal-edit" style={{ backgroundColor: downColor }}>
                    <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
                    <h4 style={headingStyleCSS}>{resumeData.userJobRole}</h4>
                </div>

                {/* SUMMARY */}
                {
                    resumeData.AllSections[0].isSection &&
                    <div className="Summary_Information summary-edit" style={{ borderColor: themeColor }}>
                        <h2 style={combineHeadingStyle}>{AllSections[0].sectionName}</h2>
                        <p className='para-theme' style={{ ...paraStyleCSS, borderColor: themeColor }} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />
                    </div>}


                {/* EDUCATION */}
                {
                    resumeData.AllSections[5].isSection &&
                    <div className="Common-Section education-edit" style={{ borderColor: themeColor }}>
                        <h2 style={combineHeadingStyle}>{AllSections[5].sectionName}</h2>
                        {AllSections[5].list.map(Education => {
                            const { listId, collegeName, course, startDate, endDate, aboutEducation } = Education;
                            return (
                                <div className="Common_Details" key={listId} style={{ borderColor: themeColor }}>
                                    <span style={{ backgroundColor: themeColor }} className="styling-dot" />
                                    <p><b style={boldParaStyleCss}>{course}</b></p>
                                    <div className="Common_level">
                                        <p className='overflow' style={paraStyleCSS}>{collegeName}</p>
                                        <p style={paraStyleCSS}><i>{startDate} - {endDate}</i></p>
                                    </div>
                                    <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} />
                                </div>
                            )
                        })}
                    </div>}

                {/* JOB EXP */}
                {
                    resumeData.AllSections[4].isSection &&
                    <div className="Common-Section job-exp-edit" style={{ borderColor: themeColor }}>
                        <h2 style={combineHeadingStyle}>{AllSections[4].sectionName}</h2>
                        {AllSections[4].list.map(Experience => {
                            const { listId, companyName, jobRole, startDate, endDate, aboutJob } = Experience;
                            return (
                                <div className="Common_Details" key={listId} style={{ borderColor: themeColor }}>
                                    <span className="styling-dot" style={{ backgroundColor: themeColor }}></span>
                                    <p><b style={boldParaStyleCss}>{companyName}</b></p>
                                    <div className="Common_level">
                                        <p className='overflow' style={paraStyleCSS}>{jobRole}</p>
                                        <p style={paraStyleCSS}><i>{startDate} - {endDate}</i></p>
                                    </div>
                                    <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
                                </div>
                            )
                        })}
                    </div>}
                {AllSections[8].isSection &&
                    <div className='certification-edit Common-Section' style={{ borderColor: themeColor }}>
                        <h2 style={combineHeadingStyle}>{AllSections[8].sectionName}</h2>
                        {AllSections[8].list.map(Project => {
                            const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
                            return (
                                <div className="Common_Details" key={listId} style={{ borderColor: themeColor }}>
                                    <span className="styling-dot" style={{ backgroundColor: themeColor }}></span>
                                    <p><b style={boldParaStyleCss}>{instituteName}</b></p>
                                    <div className="Common_level">
                                        <p className='overflow' style={paraStyleCSS}>{certificateName}</p>
                                        <p style={paraStyleCSS}><i>{startDate} - {endDate}</i></p>
                                    </div>
                                    <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} />
                                </div>
                            )
                        })}
                    </div>}

                {AllSections[6].isSection &&
                    <div className='Common-list-styling language-edit' style={{ borderColor: themeColor }}>
                        <h2 style={combineHeadingStyle}>{AllSections[6].sectionName}</h2>
                        <ul style={{ borderColor: themeColor }}>
                            {AllSections[6].list.map(languages => {
                                const { listId, languageName, languageProfiency } = languages;
                                return (
                                    <li style={paraStyleCSS} key={listId}>
                                        <span className="dot" style={{ backgroundColor: resumeData.themeColor }}></span>
                                        <p> {languageName} {languageProfiency !== "None" && ` - ${languageProfiency}`} </p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>}

            </div>
            <div className="Border_Gradient" style={{ background: `linear-gradient(90deg, ${themeColor} 68%, rgba(255, 255, 255, 1) 68%)` }} />
            <div className="Border_Gradient_R" style={{ backgroundColor: themeColor }}></div>
        </div>

    )
}
