import { useState } from 'react'
import React from 'react';
import './PostMedia.css';
import UploadMedia from '../cg-admin-popup/UploadMedia'
// import ShowMediaImage from './ShowMediaImage';

export default function PostMedia() {
  const [openUploadmediaPopup, setOpenUploadmediaPopup] = useState(false);
  return (
    <>
      <div className="upload-media-image">
        <h2>Media</h2>
        <button onClick={() => setOpenUploadmediaPopup(true)}> Upload Media</button>
        {openUploadmediaPopup && <UploadMedia cancel={() => setOpenUploadmediaPopup(false)} />}

      </div>
      {/* <ShowMediaImage /> */}
    </>
  )
}
