import React, { useContext } from 'react'
import './NuevaTemplate.css';
import { QRCodeCanvas } from 'qrcode.react';
import GlobalContext from '../../context/GlobalContext';

export const NuevaTemplate = ({ resumeData }) => {

  const { AllSections, isQRCode, qrPosition } = resumeData;
  const { vertical, horizontal, X, Y } = qrPosition;

  const { liveTemplateURL } = useContext(GlobalContext);
  const { themeColor } = resumeData;
  const { backgroundPattern } = resumeData.resumeStyle;

  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    color: resumeData.headingTextColor,
  }

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`,
    lineHeight: `${resumeData.bodyTextSize + 8}px`
  }

  const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px`, borderColor: themeColor }

  return (
    <div className="NuevaTemplate" style={{ backgroundImage: `url("/background-pattern/${backgroundPattern}")` }}>
      {
        isQRCode ? <div className="resume-qr-code" style={{ [vertical]: `${Y}px`, [horizontal]: `${X}px` }}>
          <QRCodeCanvas value={liveTemplateURL} size={"50"} />
        </div> : <></>
      }
      <div className='fisrrt-hr'>
        < hr style={{ backgroundColor: themeColor }} />
        {/* first section */}
        <div className="intro">
          <h1 className="personal-edit">{resumeData.userName} | {resumeData.userJobRole}</h1>

          {/* CONTACT */}
          {
            AllSections[1].isSection &&
            <div className='details contact-edit'>
              <ul>
                {AllSections[1].list.map(element => {
                  const { listId, iconName, contactName, additionalLink } = element;
                  const handleItemClick = (e) => {
                    if (!additionalLink) {
                      e.preventDefault();
                    }
                  };
                  return (
                    <li
                      key={listId}
                      style={{ cursor: 'pointer' }}
                      onClick={handleItemClick}
                    >
                      {additionalLink ? (
                        <a
                          href={additionalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <p style={paraStyleCSS}>| {contactName} |</p>
                        </a>
                      ) : (
                        <>
                          <div style={{ cursor: 'pointer' }}>
                            <p style={paraStyleCSS}>| {contactName} |</p>
                          </div>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>}
        </div>
      </div>
      {/* first section */}

      {/* Second Section summary */}
      {
        AllSections[0].isSection &&
        <div className="summary-sec summary-edit">
          <div className="headingbox">
            <hr style={{ backgroundColor: themeColor }} />
            <div className="centered" style={{ backgroundColor: themeColor }}>
              <h2 style={combineHeadingStyle}>{AllSections[0].sectionName}</h2>
            </div>
          </div>
          <div style={paraStyleCSS} className='para' dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />

          {/* Second Section summary */}

        </div>}

      {/* Skill section */}
      {
        AllSections[3].isSection && <div className="summary-sec skills skill-edit">
          <div className="headingbox">
            <hr style={{ backgroundColor: themeColor }} />
            <div className="centered" style={{ backgroundColor: themeColor }}>
              <h2 style={combineHeadingStyle}>{AllSections[3].sectionName}</h2>
            </div>
          </div>
          <div className='common-list-styling'>
            <ul>
              {AllSections[3].list.map((element, index) => (
                <div className='before-icon' key={index}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color={themeColor} fill={themeColor} >
                    <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="2.5" />
                    <path d="M8 12.5L10.5 15L16 9" stroke="#fff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg> <li style={paraStyleCSS} key={index}>{element.skillName}</li>
                </div>
              ))}
            </ul>
          </div>
        </div>}

      {/* Exp section */}
      {AllSections[4].isSection && <div className='job-exp-edit'>
        <div className="summary-sec">
          <div className="headingbox">
            <hr style={{ backgroundColor: themeColor }} />
            <div className="centered" style={{ backgroundColor: themeColor }}>
              <h2 style={combineHeadingStyle}>{AllSections[4].sectionName}</h2>
            </div>
          </div>
        </div>
        <div className='data-secction'>
          {AllSections[4].list.map((element, index) => {
            const { companyName, jobRole, startDate, endDate, aboutJob } = element;
            return (
              <div className='common-details' key={index}>
                <p style={paraStyleCSS}><b>{companyName}</b></p>
                <p style={paraStyleCSS}><b>{jobRole} | {startDate} - {endDate}</b></p>
                <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
              </div>
            )
          })}
        </div>
      </div>}
      {/* Proj section */}
      {AllSections[2].isSection && <div className='project-edit'>
        <div className="summary-sec">
          <div className="headingbox">
            <hr style={{ backgroundColor: themeColor }} />
            <div className="centered " style={{ backgroundColor: themeColor }}>
              <h2 style={combineHeadingStyle}>{AllSections[2].sectionName}</h2>
            </div>
          </div>
        </div>
        <div className='data-secction'>
          {AllSections[2].list.map((element, index) => {
            const { projectName, startDate, endDate, aboutProject } = element;
            return (
              <div className='common-details' key={index}>
                <p style={paraStyleCSS}><b>{projectName} | {startDate} - {endDate}</b></p>
                <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
              </div>
            )
          })}
        </div>
      </div>}

      {AllSections[8].isSection && <div className='certification-edit common-section'>
        <div className="summary-sec">
          <div className="headingbox">
            <hr style={{ backgroundColor: themeColor }} />
            <div className="centered" style={{ backgroundColor: themeColor }}>
              <h2 style={combineHeadingStyle}>{AllSections[8].sectionName}</h2>
            </div>
          </div>
        </div>
        <div className='data-secction'>
          {AllSections[8].list.map(Project => {
            const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
            return (
              <div className='common-details' key={listId}>
                <p style={paraStyleCSS}><b>{instituteName}</b></p>
                <p style={paraStyleCSS}><b>{certificateName} | {startDate} - {endDate}</b></p>
                <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} />
              </div>
            )
          })}
        </div>
      </div>}

      {/* Education */}
      {AllSections[5].isSection && <div className='education-edit'>
        <div className="summary-sec">
          <div className="headingbox">
            <hr style={{ backgroundColor: themeColor }} />
            <div className="centered" style={{ backgroundColor: themeColor }}>
              <h2 style={combineHeadingStyle}>{AllSections[5].sectionName}</h2>
            </div>
          </div>
        </div>
        <div className='data-secction'>
          {AllSections[5].list.map((element, index) => {
            const { collegeName, course, startDate, endDate, aboutEducation, } = element;
            return (
              <div className='common-details' key={index}>
                <p style={paraStyleCSS}><b>{collegeName}</b></p>
                <p style={paraStyleCSS}><b>{course} | {startDate} - {endDate}</b></p>
                <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} />
              </div>
            )
          })}
        </div>
      </div>}

      {AllSections[7].isSection && <div className='summary-sec hobbies interest-edit'>
        <div className="headingbox">
          <hr style={{ backgroundColor: themeColor }} />
          <div className="centered" style={{ backgroundColor: themeColor }}>
            <h2 style={combineHeadingStyle}>{AllSections[7].sectionName}</h2>
          </div>
        </div>
        <div className='common-list-styling'>
          <ul>
            {AllSections[7].list.map(hobbies => {
              const { listId, interest } = hobbies;
              return (
                <div className='before-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color={themeColor} fill={themeColor} >
                    <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="2.5" />
                    <path d="M8 12.5L10.5 15L16 9" stroke="#fff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <li style={paraStyleCSS} key={listId}>
                    {interest}
                  </li>
                </div>
              )
            })}
          </ul>
        </div>
      </div>}


      {AllSections[6].isSection && <div className='summary-sec languages language-edit'>
        <div className="headingbox" >
          <hr style={{ backgroundColor: themeColor }} />
          <div className="centered" style={{ backgroundColor: themeColor }}>
            <h2 style={combineHeadingStyle}>{AllSections[6].sectionName}</h2>
          </div>
        </div>
        <div className='common-list-styling'>
          <ul>
            {AllSections[6].list.map(languages => {
              const { listId, languageName, languageProfiency } = languages;
              return (
                <div className='before-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color={themeColor} fill={themeColor}>
                    <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="2.5" />
                    <path d="M8 12.5L10.5 15L16 9" stroke="#fff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <li style={paraStyleCSS} key={listId}>
                    <p> {languageName} {languageProfiency !== "None" && ` - ${languageProfiency}`} </p>

                  </li>
                </div>
              )
            })}
          </ul>
        </div>
      </div>}

      <div className='fisrrt-hr'>
        < hr style={{ backgroundColor: themeColor }} />
      </div>
    </div>
  )
}
