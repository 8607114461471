import React, { useState } from 'react'
import "./PostPage.css"
import BlogpostPopup from '../cg-admin-popup/BlogpostPopup'
import ShowBlogData from "./ShowBlogData"
export default function PostPage() {

  const [openBlogpostPopup, setOpenBlogpostPopup] = useState(false);

  return (
    <div>
    <div className="cg-post-page">
      <h3>Blog Posts</h3>
      <button onClick={() => setOpenBlogpostPopup(true)}>Create New Post</button>
      {openBlogpostPopup && <BlogpostPopup cancel={() => setOpenBlogpostPopup(false)} />}
    </div>
    <ShowBlogData/>
    </div>
  );
}