import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import GlobalContext from '../context/GlobalContext';
import Error from '../pages/Error'; 
import './BlogPostPage.css';
import BlogPageVertical from '../pages/BlogPageVertical';

const BlogPostPage = () => {
  const { blogPath } = useParams();  
  const { blogPosts } = useContext(GlobalContext);  
  const post = blogPosts.find(post => post.slug === blogPath && post.visibility.status === 'visible');
  if (!post) {
    return <Error />;
  }
  const { title, author, createdAt, excerpt, featuredImage ,description} = post;

  return (
    <div className="bp-container">
    <div className="blog-post-page">
      <header className="blog-post-header">
        <h1>{title}</h1>
        <div className="post-image-container">
        <img
          src={featuredImage || "/images/Blog-Banner-Placeholder.png"}
          alt={title}
          className="post-image"
          onError={(e) => e.target.src = "/images/Blog-Banner-Placeholder.png"}
        />
      </div>
      </header>

      <section className="post-content">
        <div className="post-content-right">
        <div className="full-content" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className="post-content-left">
          <span className='details'>
        <p className='da'>Published by {author}</p><p className='db'>Date: {createdAt}</p>
        </span>
        <BlogPageVertical/>
        </div>
      </section>
      
    </div>
    </div>
  );
};

export default BlogPostPage;
