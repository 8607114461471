import React, { useRef, useState, useEffect } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import "./AiTextPopup.css";
import { SkeletonLoader } from "./SkeletonLoader";

export const AiTextPopup = ({ prompt, cancel, onTextChange }) => {
  const textPopup = useRef();
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const onClickHandler = async () => {
      setLoading(true);
      try {
        const genAI = new GoogleGenerativeAI("AIzaSyDeKkrIQ_uSGDdYj9l3_4MykdI3XdrTxtk");
        const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

        const additionalText = `
          Please analyze and enhance the provided resume text while maintaining its original structure. 
          Focus on improving clarity, coherence, and ATS (Applicant Tracking System) optimization by 
          correcting grammatical errors and integrating strong, relevant keywords. 
          Generate 3 different samples, each section must contain between 40 and 60 words. 
          After the closing HTML tag (</p>), ensure there is only the text "END" without any extra punctuation or a period at the end of each section. 
          Do not mention word count, sample numbers, or use any special characters or symbols. 
          The answer must be in HTML format without additional punctuation or quotes.Analyse it again and remove the text html from the starting .
        `;

        const combinedPrompt = `${prompt}\nAdditional Context: ${additionalText}`;
        const result = await model.generateContent(combinedPrompt);
        let cleanedResult = result.response.text();

        cleanedResult = cleanedResult
          .replace(/<\/p>END\s*/g, '</p>END')  // Remove extra spaces after </p>END
          .replace(/`/g, '')  // Remove backticks
          .replace(/^.*?(?=<p>)/, '')  // Remove any unwanted text before the first <p> tag
          .trim();  // Trim leading/trailing whitespace

        setResult(cleanedResult);
      } catch (error) {
        console.error("Error generating content:", error);
      } finally {
        setLoading(false);
      }
    };

    if (prompt) {
      onClickHandler();
    }
  }, [prompt]);

  const formatResultAsList = (text) => {
    const items = text.split("END").filter((item) => item.trim() !== "");
    return (
      <div className="result-list">
        {items.map((item, index) => {
          const cleanItem = item.trim();
          if (cleanItem) { // Only render <p> tags with data
            return (
              <p
                key={index}
                className="ai-gemini-each-list"
                onClick={() => handleItemClick(cleanItem)}
                dangerouslySetInnerHTML={{ __html: cleanItem }}
              />
            );
          }
          return null; 
        })}
      </div>
    );
  };

  const handleItemClick = (item) => {
    if (onTextChange) {
      onTextChange(item);
    }
  };

  const handleClickOutside = (event) => {
    if (textPopup.current.contains(event.target)) return;
    cancel();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div
        className={`ai-generated-text-popup ${
          prompt === "" || prompt.split(" ").length < 10
            ? "invalid-prompt"
            : "valid-prompt"
        }`}
        ref={textPopup}
      >
        {loading ? (
          <SkeletonLoader />
        ) : (
          <>
            {prompt === "" || prompt.split(" ").length < 10
              ? "Enter valid data"
              : formatResultAsList(result)}
          </>
        )}
      </div>
    </>
  );
};
