import React, { useContext } from 'react'
import './GrayThemetemplate.css';
import { QRCodeCanvas } from 'qrcode.react';
import GlobalContext from '../../context/GlobalContext';


export const GrayThemeTemplate = ({ resumeData }) => {
  const { AllSections, isQRCode, themeColor, qrPosition } = resumeData;
  const { vertical, horizontal, X, Y } = qrPosition;
  const { liveTemplateURL } = useContext(GlobalContext);
  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    color: resumeData.headingTextColor,
  }

  const { backgroundPattern } = resumeData.resumeStyle;

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`,
    lineHeight: `${resumeData.bodyTextSize + 8}px`
  }

  const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

  return (

    <div className="GTT-resume-template" style={{ backgroundImage: `url("/background-pattern/${backgroundPattern}")` }}>
      {
        isQRCode ? <div className="resume-qr-code" style={{ [vertical]: `${Y}px`, [horizontal]: `${X}px` }}>
          <QRCodeCanvas value={liveTemplateURL} size={"50"} />
        </div> : <></>
      }

      {/*Name SO-1 */}
      <div className="GTT-template  name-sec-so-1 personal-edit">
        <img style={{ borderColor: themeColor, width: '120px', height: '120px' }} src={resumeData.profileImage} alt="" />
        <div className='name-sec-so-1-details' style={{ borderColor: themeColor }}>
          <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
          <h4 style={headingStyleCSS}>{resumeData.userJobRole}</h4>
        </div>
      </div>

      {/*Contact SO-2 */}
      {
        resumeData.AllSections[1].isSection &&
        <div className='contact-sec-so2 contact-edit' style={{ background: themeColor }}>
          <ul>
            {AllSections[1].list.map(element => {
              const { listId, iconName, contactName, additionalLink } = element;
              const handleItemClick = (e) => {
                if (!additionalLink) {
                  e.preventDefault();
                }
              };
              return (
                <li
                  key={listId}
                  style={{ cursor: 'pointer' }}
                  onClick={handleItemClick}
                >
                  {additionalLink ? (
                    <a
                      href={additionalLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '8px 0px 8px' }}
                    >
                      <i style={{
                        fontSize: resumeData.bodyTextSize,
                        color: '#fff'
                      }} className={iconName} />
                      <p style={{ ...paraStyleCSS, color: '#fff' }}>{contactName}</p>
                    </a>
                  ) : (
                    <>
                      <div style={{ ...paraStyleCSS, cursor: 'pointer' }} className="account-so-2-details" >
                        <i className={iconName}></i>
                        <p  >{contactName}</p>
                      </div>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </div>}

      {/* Profile SO-3 */}
      {
        resumeData.AllSections[0].isSection &&
        <div className="gtt-for-heading profile-so-3 summary-edit">
          <div className='gtt-for-heading profile-so-3-a'>
            <h2 style={{ ...combineHeadingStyle }}>{resumeData.AllSections[0].sectionName} </h2>
          </div>
          <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: AllSections[0].summary }} />
        </div>}


      {/* GROUP SO-4 */}
      <div className="grp-so-4" style={{ borderColor: themeColor }}>
        <div className="so-4-left" style={{ borderColor: themeColor }}>

          {/* experience | Job */}
          {
            resumeData.AllSections[4].isSection &&
            <div className=" gtt-for-heading gtt-job-section job-exp-edit">

              <h2 style={combineHeadingStyle}>{resumeData.AllSections[4].sectionName}</h2>
              <div className='gtt-job-secction-details'>
                {/* <hr className='gtt-forhr' /> */}
                {resumeData.AllSections[4].list.map((element, index) => {
                  const { listId, companyName, jobRole, startDate, endDate, aboutJob } =
                    element;
                  return (
                    <div className='common-section' key={listId}>
                      <p style={{ ...paraStyleCSS, fontWeight: 700 }}>{companyName} | {startDate} - {endDate} </p>
                      <p style={{ ...paraStyleCSS, fontWeight: 700 }} className='gtt-sub-heading'>{jobRole}</p>
                      <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutJob }} />
                    </div>
                  );
                })}
              </div>
            </div>}

          {/* projects */}
          {
            resumeData.AllSections[2].isSection &&
            <div className="gtt-for-heading gtt-projects project-edit">
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[2].sectionName}</h2>
              <div className="gtt-projeccts-details">
                {resumeData.AllSections[2].list.map((element) => {
                  const { listId, projectName, startDate, endDate, aboutProject } =
                    element;
                  return (
                    <div className='common-section' key={listId}>
                      <p style={{ ...paraStyleCSS, fontWeight: 700 }}>{projectName} |  {startDate} - {endDate}</p>
                      <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutProject }} />
                    </div>
                  );
                })}
              </div>
            </div>}
          {AllSections[8].isSection &&
            <div className='gtt-for-heading gtt-certificate certification-edit'>
              <h2 style={combineHeadingStyle}>{AllSections[8].sectionName}</h2>
              <div className='gtt-certificate-details'>
                {AllSections[8].list.map(Project => {
                  const { listId, instituteName, startDate, endDate, certificateName, aboutCertificate } = Project;
                  return (
                    <div className='common-section' key={listId}>
                      <p style={{ ...paraStyleCSS, fontWeight: 700 }}>{instituteName} |  {startDate} - {endDate}</p>
                      <p style={{ ...paraStyleCSS, fontWeight: 700 }} className='gtt-sub-heading'>{certificateName}</p>
                      <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutCertificate }} />
                    </div>
                  )
                })}
              </div>
            </div>}
          {AllSections[6].isSection &&
            <div className='gtt-for-heading gtt-languages language-edit'>
              <h2 style={combineHeadingStyle}>{AllSections[6].sectionName}</h2>
              <ul className='common-list-style'>
                {AllSections[6].list.map(languages => {
                  const { listId, languageName, languageProfiency } = languages;
                  return (
                    <li style={paraStyleCSS} key={listId}>
                      <p style={paraStyleCSS}>{languageName} {languageProfiency !== "None" && ` - ${languageProfiency}`}</p>
                    </li>
                  )
                })}
              </ul>
            </div>}
        </div>


        <div className="so-4-right">
          {/* Education */}
          {
            resumeData.AllSections[5].isSection &&
            <div className='gtt-for-heading gtt-education education-edit'>
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[5].sectionName} </h2>
              <div className='gtt-education-details'>
                {resumeData.AllSections[5].list.map(
                  (element) => {
                    const { listId, collegeName, course, startDate, endDate, aboutEducation, } = element;
                    return (
                      <div className='common-section' key={listId}>
                        <p style={{ ...paraStyleCSS, fontWeight: 700 }}>{collegeName} |  {startDate} - {endDate}</p>
                        <p style={{ ...paraStyleCSS, fontWeight: 700 }} className='gtt-sub-heading'>{course}</p>
                        <p style={paraStyleCSS} dangerouslySetInnerHTML={{ __html: aboutEducation }} />
                      </div>
                    );
                  }
                )}
              </div>
            </div>}


          {/* Skills */}
          {
            resumeData.AllSections[3].isSection &&
            <div className="gtt-for-heading gtt-skils skill-edit">
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[3].sectionName}</h2>
              <ul className='common-list-style'>
                {resumeData.AllSections[3].list.map((element) => {
                  return (
                    <li style={paraStyleCSS} key={element.listId}>
                      {element.skillName}
                    </li>
                  )
                })}
              </ul>
            </div>}
          {AllSections[7].isSection &&
            <div className='gtt-for-heading gtt-hobbies interest-edit'>
              <h2 style={combineHeadingStyle}>{AllSections[7].sectionName}</h2>
              <ul className='common-list-style'>
                {AllSections[7].list.map(hobbies => {
                  const { listId, interest } = hobbies;
                  return (
                    <li style={paraStyleCSS} key={listId}>
                      {interest}
                    </li>
                  )
                })}
              </ul>
            </div>}
        </div>
      </div>
    </div>
  );
}

