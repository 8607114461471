
import React from 'react';
import {Link } from 'react-router-dom'; 
import './AdminDashboardLeftbar.css';

export default function AdminDashboardLeftbar({ currPage }) {
 
  return (
    <div className="cg-admin-leftbar">
      <ul>
        <Link
        to="/cg-admin"
          className={`cg-leftbar-li ${currPage === '/cg-admin' ? 'active' : ''}`}
        >
          <i className="fa-regular fa-objects-column"></i>
          Dashboard
        </Link>
        <Link
        to="/cg-admin/post"
          className={`cg-leftbar-li ${currPage === '/cg-admin/post' ? 'active' : ''}`}
       
        >
          <i className="fa-regular fa-plus"></i>
          Post
        </Link>
        <Link
        to="/cg-admin/media"
          className={`cg-leftbar-li ${currPage === '/cg-admin/media' ? 'active' : ''}`} 
        >
          <i className="fa-light fa-photo-film"></i>
          Media
        </Link>
        <Link
        to="/cg-admin/change-admin-password"
          className={`cg-leftbar-li ${currPage === '/cg-admin/change-admin-password' ? 'active' : ''}`}

        >
          <i className="fa-regular fa-lock-open"></i>
          Change Password
        </Link>
      </ul>
    </div>
  );
}
